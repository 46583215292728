/*
 * @Author: one-dragon
 * @Date: 2018-11-14 11:00:02
 * @Last Modified by: one-dragon
 * @Last Modified time: 2019-02-21 21:09:36
 * description: 封装公共方法
 */

import axios from 'axios';
import { Loading, MessageBox } from 'element-ui';
// import { TrackUtils } from '~/assets/js/event_track';
import md5 from 'blueimp-md5';
import CryptoJS from 'crypto-js';
// Vue.use(CryptoJS);
// ajax请求封装
// axios.defaults.baseURL = process.env.HOST ? 'http://' + process.env.HOST + ':' + process.env.PORT : '';
axios.defaults.headers.common['token'] = localStorage.getItem('token') || ''; // 设置请求头token
axios.defaults.headers.common['userId'] = localStorage.getItem('userId') || '';
axios.defaults.headers.common['Authorization'] = localStorage.getItem('authorization');
let loadingInstance = null;
const defaultParams = { isLoading: true, isSuccessPrompt: false, isFailPrompt: true };
class $v {
    // { isLoading: true, isSuccessPrompt: false, isFailPrompt: true }
    static get(url, json, success, error, dataParams) {
        // 定义dataParams参数内容
        dataParams = Object.assign({}, defaultParams, dataParams);
        // 判断传参
        let config = {};
        if (typeof json == 'object') {
            if (json.params) {
                config = json;
            } else {
                config.params = json;
            }
            config.params = $v.commonParam({
                method: 'get',
                data: config.params
            });
        }
        if (typeof (json) == 'function') {
            // error = error || success;
            if(typeof (error) == 'object') {
                dataParams = Object.assign({}, dataParams, error);
            }
            error = success || error;
            success = json;
            config.params = $v.commonParam({
                method: 'get',
                data: config.params
            });
        }
        if(typeof (error) == 'object') {
            dataParams = Object.assign({}, dataParams, error);
            error = false;
        }
        // 判断是否加载loading
        if(dataParams.isLoading) {
            loadingInstance = Loading.service({
                fullscreen: true,
                text: typeof (dataParams.isLoading) == 'string' ? dataParams.isLoading : ''
            });
        }
        // 发送请求
        return axios.get(url, config).then((d) => {
            let data = d.data;
            // 调用处理结果函数
            $v.resultHandle(data, success, error, dataParams, url);
        }).catch((catchError) => {
            $v.errorHandle(error, dataParams, url, catchError);
        })
    }
    static delete(url, json, success, error, dataParams) {
        // 定义dataParams参数内容
        dataParams = Object.assign({}, defaultParams, dataParams);
        // 判断传参
        let config = {};
        if (typeof json == 'object') {
            if (json.params) {
                config = json;
            } else {
                config.params = json;
            }
            config.params = $v.commonParam({
                method: 'get',
                data: config.params
            });
        }
        if (typeof (json) == 'function') {
            // error = error || success;
            if(typeof (error) == 'object') {
                dataParams = Object.assign({}, dataParams, error);
            }
            error = success || error;
            success = json;
            config.params = $v.commonParam({
                method: 'get',
                data: config.params
            });
        }
        if(typeof (error) == 'object') {
            dataParams = Object.assign({}, dataParams, error);
            error = false;
        }
        // 判断是否加载loading
        if(dataParams.isLoading) {
            loadingInstance = Loading.service({
                fullscreen: true,
                text: typeof (dataParams.isLoading) == 'string' ? dataParams.isLoading : ''
            });
        }
        // 发送请求
        return axios.delete(url, config).then((d) => {
            let data = d.data;
            // 调用处理结果函数
            $v.resultHandle(data, success, error, dataParams, url);
        }).catch((catchError) => {
            $v.errorHandle(error, dataParams, url, catchError);
        })
    }
    static post(url, json, success, error, dataParams) {
        // 定义dataParams参数内容
        dataParams = Object.assign({}, defaultParams, dataParams);
        // 判断传参
        let data = {};
        let config = {};
        if (typeof (json) == 'object') {
            if (json.data) {
                data = json.data;
                delete json.data;
                config = json;
            } else {
                data = json;
            }
        }
        if (typeof (json) == 'function') {
            // error = error || success;
            if(typeof (error) == 'object') {
                dataParams = Object.assign({}, dataParams, error);
            }
            error = success || error;
            success = json;
        }
        if(typeof (error) == 'object') {
            dataParams = Object.assign({}, dataParams, error);
            error = false;
        }
        // 判断是否加载loading
        if(dataParams.isLoading) {
            loadingInstance = Loading.service({
                fullscreen: true,
                text: typeof (dataParams.isLoading) == 'string' ? dataParams.isLoading : ''
            });
        }
        // 发送请求
        return axios.post($v.commonParam({
            method: 'post',
            url: url
        }), $v.commonParam({
            method: 'post',
            data: data
        }), config).then((d) => {
            // console.log(d);
            let data = d.data;
            // 调用处理结果函数
            $v.resultHandle(data, success, error, dataParams, url)
        }).catch((catchError) => {
            $v.errorHandle(error, dataParams, url, catchError);
        })
    }
    static put(url, json, success, error, dataParams) {
        // 定义dataParams参数内容
        dataParams = Object.assign({}, defaultParams, dataParams);
        // 判断传参
        let data = {};
        let config = {};
        if (typeof (json) == 'object') {
            if (json.data) {
                data = json.data;
                delete json.data;
                config = json;
            } else {
                data = json;
            }
        }
        if (typeof (json) == 'function') {
            // error = error || success;
            if(typeof (error) == 'object') {
                dataParams = Object.assign({}, dataParams, error);
            }
            error = success || error;
            success = json;
        }
        if(typeof (error) == 'object') {
            dataParams = Object.assign({}, dataParams, error);
            error = false;
        }
        // 判断是否加载loading
        if(dataParams.isLoading) {
            loadingInstance = Loading.service({
                fullscreen: true,
                text: typeof (dataParams.isLoading) == 'string' ? dataParams.isLoading : ''
            });
        }
        // 发送请求
        return axios.put($v.commonParam({
            method: 'post',
            url: url
        }), $v.commonParam({
            method: 'post',
            data: data
        }), config).then((d) => {
            let data = d.data;
            // 调用处理结果函数
            $v.resultHandle(data, success, error, dataParams, url)
        }).catch((catchError) => {
            $v.errorHandle(error, dataParams, url, catchError);
        })
    }
    // 设置出入公共参数
    static commonParam({ method, data, url }) {
        if (data) {
            let obj = data;
            if (method == 'get') {}
            /*
            if(method == 'get') {
                // get请求时，入参属性值为数组时转成字符传入，入参属性名为pageInfo时，转成点值传入
                for(let i in obj) {
                    if(obj[i].constructor == Array) {
                        obj[i] = String(obj[i]);
                    }
                }
                if(obj.pageInfo) {
                    for(let i in obj.pageInfo) {
                        obj[`pageInfo.${i}`] = obj.pageInfo[i];
                    }
                    delete obj.pageInfo;
                }
            }
            */
            // 生成MD5
            const key = md5(JSON.stringify(obj));
            obj.key = key;
            return obj;
        }
        if (url) {
            return url;
        }
    }
    // 处理ajax请求成功结果
    static resultHandle(data, success, error, dataParams, url) {
        try {
            loadingInstance.close();
            // IE或者Edge下清除loading
            if (CurrBrowser.getpc().broswer == 'IE' || CurrBrowser.getpc().broswer == 'Edge') {
                setTimeout(() => {
                    let elm = document.querySelector('.el-loading-mask.is-fullscreen');
                    if (elm) {
                        elm.parentNode.removeChild(elm);
                    }
                }, 2000)
            }
        } catch (e) {
            console.log('error:' + e);
            console.log(url);
        }

        // 返回成功
        success ? success(data) : '';

        // 默认成功基本---提示框数据
        // let promptData = {
        //     title: '提示',
        //     message: '请求成功',
        //     moreMessage: '',
        //     type: 'success'
        // }
        // // 处理提示框提示
        // if (dataParams.isSuccessPrompt) {
        //     return PromptBox.common(Object.assign({}, promptData, dataParams.isSuccessPrompt));
        // }
    }
    // 处理ajax请求错误结果
    static errorHandle(error, dataParams, url, catchError) {
        // console.log(catchError.response.data);
        // console.log(catchError.response.status);
        // console.log(catchError.response.headers);
        // console.log(catchError.message);
        // console.log(catchError.response);
        try {
            loadingInstance.close();
            // IE或者Edge下清除loading
            if (CurrBrowser.getpc().broswer == 'IE' || CurrBrowser.getpc().broswer == 'Edge') {
                setTimeout(() => {
                    let elm = document.querySelector('.el-loading-mask.is-fullscreen');
                    if (elm) {
                        elm.parentNode.removeChild(elm);
                    }
                }, 2000)
            }
        } catch (e) {
            console.log('error: ' + e)
        }
        // 系统级别---错误提示数据
        let promptData = {
            title: '提示',
            message: '系统错误',
            moreMessage: `url: ${url} </br> message: ${catchError.message}`,
            res: catchError,
            type: 'error'
        }
        // 页面级别---代码错误提示
        if (catchError.message && catchError.response == undefined) {
            // 控制台输出具体错误内容
            console.error(catchError);
            promptData.message = '页面错误';
            return PromptBox.common(Object.assign({}, promptData));
        }
        // 接口级别---返回400错误提示
        if (catchError.response.status == 400 && typeof (catchError.response.data) == 'object') {
            let data = catchError.response.data;
            promptData.message = data.reason;
            promptData.moreMessage = `url: ${url} </br> code: ${data.code} </br> message: ${data.message}`;
        }
        // 处理提示框提示
        if (typeof (error) == 'function') {
            error(catchError, () => {
                if(dataParams.isFailPrompt) {
                    return PromptBox.common(Object.assign({}, promptData, dataParams.isFailPrompt));
                }
            })
        }
        if(!error) {
            if(dataParams.isFailPrompt) {
                return PromptBox.common(Object.assign({}, promptData, dataParams.isFailPrompt));
            }
        }else {
            console.log('error:' + catchError.message);
        }
    }
}


class PromptBox {
    static common({ title, message, moreMessage, type }) {
        // <button class="el-button el-button--primary el-button--small">更多 <i class="el-icon-caret-bottom"></i></button>
        return MessageBox({
            customClass: 'common_prompt_box_style',
            dangerouslyUseHTMLString: true,
            modal: false,
            // type,
            title: title,
            message: `
                <style>
                    .el-message-box__wrapper[aria-label="${title}"]{
                        background-color: rgba(0,0,0,.5);
                        z-index: 9999 !important;
                    }
                </style>
                <div class="msg_box">
                    <div class="el-message-box__status el-icon-${type}"></div>
                    <div class="msg_content">${message}</div>
                    <i class="switch_btn el-icon-arrow-down" style="${moreMessage ? '' : 'display: none;'}" onclick="
                        if(this.className.indexOf('el-icon-arrow-down') >= 0) {
                            this.className = 'switch_btn el-icon-arrow-up';
                            this.parentNode.nextElementSibling.style.height = 'auto';
                        }else {
                            this.className = 'switch_btn el-icon-arrow-down';
                            this.parentNode.nextElementSibling.style.height = 0;
                        }
                    "></i>
                </div>
                <div class="more_msg_box collapse-transition">${moreMessage}</div>
            `,
            showCancelButton: false,
            showConfirmButton: false
        }).catch(() => {})
    }
}

// 图片转为base64
class imgSrcTo {
    // img为传入img标签dom对象，getData为回调函数返回base64串
    static base64(img, cb) {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        if(typeof cb == 'function') cb(canvas.toDataURL());
    }
}

// Base64转图片
class Base64ToImg {
    static base64ToBlob(dataurl, filename) {
        // 默认转png类型
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
}

// 检测当前浏览器端
class CurrBrowser {
    static get() {
        var browser = {
            versions: (function () {
                var u = navigator.userAgent;
                // var app = navigator.appVersion;
                return { // 移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, // IE内核
                    presto: u.indexOf('Presto') > -1, // opera内核
                    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // 火狐内核
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
                    iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
                    iPad: u.indexOf('iPad') > -1, // 是否iPad
                    webApp: u.indexOf('Safari') == -1 // 是否web应该程序，没有头部与底部
                };
            })(),
            language: (navigator.browserLanguage || navigator.language).toLowerCase()
        }
        if (browser.versions.mobile) { // 判断是否是移动设备打开。browser代码在下面
            // return 'mobile';
            var ua = navigator.userAgent.toLowerCase(); // 获取判断用的对象
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                // 在微信中打开
                return 'WX';
            }
            if (ua.match(/WeiBo/i) == 'weibo') {
                // 在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == 'qq') {
                // 在QQ空间打开
            }
            if (browser.versions.ios) {
                // 是否在IOS浏览器打开
                return 'WAP';
            }
            if (browser.versions.android) {
                // 是否在安卓浏览器打开
                return 'WAP';
            }
            return 'APP';
        } else {
            // 否则就是PC浏览器打开
            return 'PC';
        }
    }
    static getpc() {
        var sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/edge\/([\d.]+)/)) ? sys.edge = s[1] :
            (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? sys.ie = s[1] :
                (s = ua.match(/msie ([\d.]+)/)) ? sys.ie = s[1] :
                    (s = ua.match(/firefox\/([\d.]+)/)) ? sys.firefox = s[1] :
                        (s = ua.match(/chrome\/([\d.]+)/)) ? sys.chrome = s[1] :
                            (s = ua.match(/opera.([\d.]+)/)) ? sys.opera = s[1] :
                                (s = ua.match(/version\/([\d.]+).*safari/)) ? sys.safari = s[1] : 0;
        if (sys.edge) {
            return {
                broswer: 'Edge',
                version: sys.edge
            }
        };
        if (sys.ie) {
            return {
                broswer: 'IE',
                version: sys.ie
            }
        };
        if (sys.firefox) {
            return {
                broswer: 'Firefox',
                version: sys.firefox
            }
        };
        if (sys.chrome) {
            return {
                broswer: 'Chrome',
                version: sys.chrome
            }
        };
        if (sys.opera) {
            return {
                broswer: 'Opera',
                version: sys.opera
            }
        };
        if (sys.safari) {
            return {
                broswer: 'Safari',
                version: sys.safari
            }
        };
        return {
            broswer: '',
            version: '0'
        };
    }
}


// 根据年份、月份判断当前月的天数
let CountDay = (month, year) => {
    var dayNum = 0;
    switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
        dayNum = 31;
        break;
    case 4:
    case 6:
    case 9:
    case 11:
        dayNum = 30;
        break;
    case 2:
        if (/^\d+$/.test(year / 100)) {
            if (/^\d+$/.test(year / 400)) {
                dayNum = 29;
            } else {
                dayNum = 28;
            }
        } else {
            if (/^\d+$/.test(year / 4)) {
                dayNum = 29;
            } else {
                dayNum = 28;
            }
        }
        break;
    }
    return dayNum;
}


// 根据树结构查找对应数据
class TreeData {
    /*
     * data: 遍历的数据
     * labelVal: 匹配内容
     * label: 匹配内容的key字段
     * children: 子集的key字段
     * treeStr: 字段匹配内容的连接的字符串，不传则使用label为匹配
     * callback: 匹配成功后的回调函数
     * currTree: 匹配内容的连接的字符串
     * totalArr: 总数据
     * */
    static get({
        data,
        labelVal,
        label,
        children,
        treeStr,
        callback
    }, currTree = '', totalArr = []) {
        if (!data) {
            return;
        }
        if (totalArr.length == 0) {
            totalArr = data;
        }
        data.map((item) => {
            if (item[label] == labelVal) {
                // 当前最顶层父级所有数据
                let currTotalObj = {};
                // 当前最顶层父级匹配的内容
                let tree = '';
                if (currTree == '') {
                    tree = String(item[treeStr || label]);
                } else {
                    tree = currTree;
                }
                totalArr.map((item2) => {
                    if (item2[treeStr || label] == tree.split('$&&$')[0]) {
                        currTotalObj = item2;
                    }
                })
                // 成功后返回数据: 当前对应的对象、当前同级的所有数据（数组返回）、当前最顶层父级所有数据、匹配内容的连接的字符串
                callback ? callback(item, data, currTotalObj, currTree + item[treeStr || label]) : '';
            } else {
                let trees = currTree + item[treeStr || label] + '$&&$';
                TreeData.get({
                    data: item[children],
                    labelVal,
                    label,
                    children,
                    treeStr,
                    callback
                }, trees, totalArr);
            }
        })
    }
}


// 获取url中的参数
class GetUrlPara {
    static get(name) {
        // 构造一个含有目标参数的正则表达式对象
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        // 匹配目标参数
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        // 返回参数值
        return null;
    }
}


// 日期转换
class DateFormat {
    // 获取标准时间格式: Fri Jan 18 2019 17:38:16 GMT+0800 (中国标准时间)
    static getStandardTime(val) {
        let date;
        if (typeof val == 'string') {
            val = val.replace(/-/g, '/');
            date = new Date(val);
        }
        if (typeof val == 'number') {
            date = new Date(val);
        }
        if (typeof val == 'object') {
            date = val;
        }
        return date;
    }
    // 获取日期格式: yyyy-mm-dd
    static get(val) {
        val = DateFormat.getStandardTime(val);
        let y = val.getFullYear();
        let m = ('0' + (val.getMonth() + 1)).substr(-2);
        let d = ('0' + val.getDate()).substr(-2);
        return `${y}-${m}-${d}`;
    }
    // 获取日期时间格式: yyyy-mm-dd hh:mm:ss
    static getFull(val) {
        val = DateFormat.getStandardTime(val);
        let date = DateFormat.get(val);
        // 获取小时数(0-23)
        let hou = ('0' + val.getHours()).substr(-2);
        // 获取分钟数(0-59)
        let min = ('0' + val.getMinutes()).substr(-2);
        // 获取秒数(0-59)
        let sec = ('0' + val.getSeconds()).substr(-2);
        return `${date} ${hou}:${min}:${sec}`
    }
    // 获取指定月份的日期时间格式: yyyy-mm-dd hh:mm:ss; 区别是不会按照日份加减到指定月份，而是直接计算加减月份，日份保持不变
    // 例如：getFixedMonth('2019-01-31', 1) -> 2019-02-28 00:00:00
    static getFixedMonth(date, num) {
        date = DateFormat.getStandardTime(date);
        let { i: integer, r: remainder } = Num.getIR(num, 12);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        let hou = ('0' + date.getHours()).substr(-2);
        let min = ('0' + date.getMinutes()).substr(-2);
        let sec = ('0' + date.getSeconds()).substr(-2);

        y = y + integer;
        m = (m + remainder) + (m + remainder > 12 ? -12 : m + remainder <= 0 ? 12 : 0);
        let currCountDay = CountDay(m, y);
        d = d > currCountDay ? currCountDay : d;
        return `${y}-${('0' + m).substr(-2)}-${('0' + d).substr(-2)} ${hou}:${min}:${sec}`;
    }
    // 获取指定月份的日期时间格式: yyyy-mm-dd hh:mm:ss
    // 例如：getSpecifiedMonth('2019-01-31', 1) -> 2019-03-03 00:00:00
    static getSpecifiedMonth(date, num) {
        date = DateFormat.getStandardTime(date);
        date.setMonth(date.getMonth() + num);
        return DateFormat.getFull(date);
    }
    // 获取指定日份的日期时间格式: yyyy-mm-dd hh:mm:ss
    static getSpecifiedDay(date, num) {
        date = DateFormat.getStandardTime(date);
        date.setDate(date.getDate() + num);
        return DateFormat.getFull(date);
    }
    // 获取指定秒数的日期时间格式: yyyy-mm-dd hh:mm:ss
    static getSpecifiedSeconds(date, num) {
        date = DateFormat.getStandardTime(date);
        date.setSeconds(date.getSeconds() + num);
        return DateFormat.getFull(date);
    }
}

class GetNowFormatDate{
    static getNowDate(){
        var date = new Date();
        var seperator1 = '-';
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = '0' + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }
}

// sessionStorage操作
class Session {
    static set(name, val) {
        sessionStorage.setItem(name, val);
    }
    static get(name) {
        return sessionStorage.getItem(name);
    }
    static remove(name) {
        sessionStorage.removeItem(name);
    }
    static clear() {
        sessionStorage.clear();
    }
}

// localStorage操作
class Local {
    static set(name, val) {
        localStorage.setItem(name, val);
    }
    static get(name) {
        return localStorage.getItem(name);
    }
    static remove(name) {
        localStorage.removeItem(name);
    }
    static clear() {
        localStorage.clear();
    }
}


// 右侧内容区滚动条回滚到顶部
class ScrollbarTo {
    static top(num) {
        let bar = ScrollbarTo.getScrollBar();
        bar.scrollTop = num || 0;
    }
    static getScrollBar() {
        return window.document.querySelector('.content_r .default_layout_scrollbar > .el-scrollbar__wrap');
    }
}


// 滚动条滑动缓冲动画效果
// 必传参数：elem(滚动目标元素), direction('top'/'left'), num(滚动距离)
// 选填参数：speed(滚动速度), cb(滚动完成回调函数)
let scrollAnimate = function (elem, direction, num, speed, cb) {
    if (!elem || !direction || !String(num)) { return false; }
    // 首字母大写
    direction = direction.toLowerCase().replace(/\b[a-z]/g, word => word.toUpperCase());
    // 判断选填入参
    if (typeof speed == 'function') {
        cb = speed;
        speed = null;
    }
    if (window.scrollAnimateTimer != undefined) {
        clearInterval(window.scrollAnimateTimer);
    }
    window.scrollAnimateTimer = undefined;
    let speeds = speed || 4;
    let isAdd = true; // 默认向右、向下滑动
    // 向左、向上滑动
    if (num < elem[`scroll${direction}`]) {
        isAdd = false;
    }
    window.scrollAnimateTimer = setInterval(() => {
        // 通过元素的当前srcoll值，获取下次滚动距离，产生缓冲动画
        let speed = Math.ceil((!isAdd ? Math.abs(num - elem[`scroll${direction}`]) : (num - elem[`scroll${direction}`])) / speeds);
        // 获取元素下次滚动的scroll值
        let count = elem[`scroll${direction}`] + (isAdd ? speed : -speed);
        // 判断下次滚动的scroll值是否超过目标值(num值)
        if ((isAdd && count >= num) || (!isAdd && count <= num)) {
            elem[`scroll${direction}`] = num;
            clearInterval(window.scrollAnimateTimer);
            cb ? cb() : '';
            return;
        }
        elem[`scroll${direction}`] += (isAdd ? speed : -speed);
        if (elem[`scroll${direction}`] == num) {
            clearInterval(window.scrollAnimateTimer);
            cb ? cb() : '';
        }
    }, 30)
}

// 操作数值的一些公共方法
class Num {
    // 取整和取余
    static getIR(dividend, divisor) {
        // dividend: 被除数； divisor: 除数
        let i = parseInt(dividend / divisor);
        let r = dividend % divisor;
        // integer remainder
        return { i, r }
    }
}

// 操作对象的一些公共方法
class Obj {
    // 设置对象属性
    // 把对象 { a: 2 } 设置成 { a: { aa: 1 } } => Obj.set(obj, 'a.aa', 1)
    static set(obj, sKey, val) {
        if (!sKey || obj.toString() != '[object Object]') return;
        let tempObj = obj;
        let keyArr = sKey.split('.');
        let len = keyArr.length - 1;
        for (let i = 0; i <= len; ++i) {
            let key = keyArr[i];
            if (i == len) {
                tempObj[key] = val;
            } else {
                if (key in tempObj) {
                    tempObj = tempObj[key] = tempObj[key].toString() == '[object Object]' ? tempObj[key] : {};
                } else {
                    tempObj = tempObj[key] = {};
                }
            }
        }
    }
    // 通过 对象obj 和 字符串'a.b.c'， 判断是否能获取最终结果, 其中is为是否跟踪到最后一个
    // { a: { aa: 1 } } => isGet(obj, 'a.aa') => { o: obj, k: 'aa', v: 1, is: true }
    // { a: { aa: 1 } } => isGet(obj, 'a.aa.aaa') => { o: obj, k: 'aa', v: 1, is: false }
    static isGet(obj, sKey) {
        if (!sKey || obj.toString() != '[object Object]') {
            return { o: obj, k: null, v: null, is: false }
        }
        let keyArr = sKey.split('.');
        let tempObj = obj;
        let is = true;
        let i = 0;
        for (let len = keyArr.length; i < len; ++i) {
            let key = keyArr[i];
            if (tempObj.toString() == '[object Object]' && key in tempObj) {
                tempObj = tempObj[key];
            } else {
                is = false;
                break;
            }
        }
        return {
            // i: i - 1,
            // t: tempObj,
            // v: tempObj ? tempObj.toString() == '[object Object]' ? tempObj[keyArr[i - 1]] : tempObj : null,
            o: obj,
            k: keyArr[i - 1],
            v: tempObj,
            is
        }
    }
}

// AES加密解密
class AES{
    static encrypt(data){
        // 加密
        var key = CryptoJS.enc.Utf8.parse('k1wNY5ZyPOnWYcWn');
        var iv = CryptoJS.enc.Utf8.parse('wM#*2@4S4bG05o6l');
        var encrypted = CryptoJS.AES.encrypt(data, key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString(); // 返回的是base64格式的密文
    }
    static decrypt(data){ 
        // 解密
        var key = CryptoJS.enc.Utf8.parse('k1wNY5ZyPOnWYcWn');
        var iv = CryptoJS.enc.Utf8.parse('wM#*2@4S4bG05o6l');
        var decrypted = CryptoJS.AES.decrypt(data, key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return decrypted.toString(CryptoJS.enc.Utf8);     
    }
}

// 操作数组的一些公共方法
class Arr {

}

// 校验输入框输入金额 只能数字或小数 只能有一个小数点并且第一位不能为小数点
class NumPrice{
    static NumberCheck(num) {
        let numInput;
        let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
        // var reg = /^\d+(\.\d+)?/;
        if(!reg.test(num)){
            numInput = '0.00';
        }else{
            numInput = num;
        }
        return numInput;
    }
}

// 计算利润
class ComputeProfit{
    static getFit(salePrice1, fxPrice1, fxPriceEnd1, minTax1, maxTax1, minFrl1, maxFrl1,
        stockPrice1, customPrice1, isShip1, isTax1, rateTax1, otherPrice1, declarePrice1) {
        let salePrice = salePrice1 * 1; // 供货价
        let fxPrice = fxPrice1 * 1; // 最小零售价
        let fxPriceEnd = fxPriceEnd1 * 1; // 最大零售价
        let minTax = minTax1 * 1; // 最小税费
        let maxTax = maxTax1 * 1; // 最大税费
        let minFrl = minFrl1 * 1; // 最小运费
        let maxFrl = maxFrl1 * 1; // 最大运费
        let stockPrice = stockPrice1 * 1; // 库内操作费
        let customPrice = customPrice1 * 1; // 清关费
        let declarePrice = declarePrice1 * 1; // 订单申报费
        let otherPrice = otherPrice1 * 1; // 其它费用
        let isShip = isShip1; // 包邮 true false
        let isTax = isTax1; // 包税 true false
        let rateTax = rateTax1 * 1; // 税率
        let minPro = 0; // 最小利润
        let maxPro = 0; // 最大利润
        if(isShip == false && isTax == false){
            // 不包邮不包税
            minPro = ((salePrice - stockPrice - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2); // 最小利润
            maxPro = ((salePrice - stockPrice - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2); // 最大利润
        }else if(isShip == true && isTax == false){
            // 包邮不包税
            minPro = ((salePrice - stockPrice - maxFrl - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2); // 最小利润
            maxPro = ((salePrice - stockPrice - minFrl - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2); // 最大利润
        }else if(isShip == false && isTax == true){
            // 不包邮包税
            minPro = ((salePrice - stockPrice - maxTax - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2);
            maxPro = ((salePrice - stockPrice - minTax - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2);
        }else if(isShip == true && isTax == true){
            // 包邮包税
            minPro = ((salePrice - stockPrice - maxFrl - maxTax - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2);
            maxPro = ((salePrice - stockPrice - minFrl - minTax - customPrice - declarePrice - otherPrice) + 0.004).toFixed(2);
        }
        return [minPro, maxPro]
    }
    // 计算运营商海关申报价和利润
    static getFxFit(supplyPrice1, salePrice1, minFrl1, maxFrl1, isShip1, isTax1, isShipType1, isTaxType1, rateTax1) {
        let supplyPrice = supplyPrice1 * 1; // 供货价
        let salePrice = salePrice1 * 1; // 销售价
        let minFrl = minFrl1 * 1; // 最小运费
        let maxFrl = maxFrl1 * 1; // 最大运费
        let isShip = isShip1; // 运营商包邮
        let isTax = isTax1; // 运营商包税
        let isShipType = isShipType1; // 包邮 
        let isTaxType = isTaxType1; // 供货商包税
        let rateTax = rateTax1 * 1; // 供货商税率
        let minTax = 0; // 最小税费
        let maxTax = 0; // 最大税费
        let minPro = 0; // 最小利润
        let maxPro = 0; // 最大利润
        // 供货商和运营商不包邮不包税
        if(isShip == false && isTax == false && isShipType == false && isTaxType == false){
            minTax = 0;
            maxTax = 0;
            minPro = (salePrice - supplyPrice + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice + 0.004).toFixed(2);
        }else if(isShip == true && isTax == false && isShipType == false && isTaxType == false){
            // 运营商包邮不包税，供货商不包邮不包税
            minTax = 0;
            maxTax = 0;
            minPro = (salePrice - supplyPrice - minFrl + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice - maxFrl + 0.004).toFixed(2);
        }else if(isShip == false && isTax == true && isShipType == false && isTaxType == false){
            // 运营商不包邮包税，供货商不包邮不包税
            minTax = ((salePrice + minFrl) * rateTax + 0.004).toFixed(2);
            maxTax = ((salePrice + maxFrl) * rateTax + 0.004).toFixed(2);
            minPro = (salePrice - supplyPrice - minTax + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice - maxTax + 0.004).toFixed(2);
        }else if(isShip == true && isTax == true && isShipType == false && isTaxType == false){
            // 运营商包邮包税，供货商不包邮不包税
            minTax = ((salePrice + minFrl) * rateTax + 0.004).toFixed(2);
            maxTax = ((salePrice + maxFrl) * rateTax + 0.004).toFixed(2);
            minPro = (salePrice - supplyPrice - minTax - minFrl + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice - maxTax - maxFrl + 0.004).toFixed(2);
        }else if(isShip == false && isTaxType == true && isTax == false){
            // 运营商不包邮不包税且供货商不包邮包税
            minTax = 0;
            maxTax = 0;
            minPro = (salePrice - supplyPrice + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice + 0.004).toFixed(2);
        }else if(isShip == true && isTaxType == true && isTax == false){
            // 运营商包邮不包税且供货商不包邮包税
            minTax = 0;
            maxTax = 0;
            minPro = (salePrice - supplyPrice - minFrl + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice - maxFrl + 0.004).toFixed(2);
        }else if(isShipType == true && isTaxType == false && isTax == false && isShip == false){
            // 供货商包邮不包税且运营商不包邮不包税
            minTax = 0;
            maxTax = 0;
            minPro = (salePrice - supplyPrice + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice + 0.004).toFixed(2);
        }else if(isShipType == true && isTaxType == false && isTax == true && isShip == false){
            // 供货商包邮不包税且运营商不包邮包税
            minTax = ((salePrice + minFrl) * rateTax + 0.004).toFixed(2);
            maxTax = ((salePrice + maxFrl) * rateTax + 0.004).toFixed(2);
            minPro = (salePrice - supplyPrice - minTax + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice - maxTax + 0.004).toFixed(2);
        }else if(isShipType == true && isTaxType == true && isShip == false && isTax == false){
            // 供货商包邮包税,运营商不包邮不包税
            minTax = 0;
            maxTax = 0;
            minPro = (salePrice - supplyPrice + 0.004).toFixed(2);
            maxPro = (salePrice - supplyPrice + 0.004).toFixed(2);
        }
        return [minTax, maxTax, minPro, maxPro] // 税费 利润
    }
}

export {
    $v,
    PromptBox,
    CurrBrowser,
    CountDay,
    TreeData,
    GetUrlPara,
    GetNowFormatDate,
    DateFormat,
    Session,
    Local,
    imgSrcTo,
    Base64ToImg,
    ScrollbarTo,
    scrollAnimate,
    ComputeProfit,
    NumPrice,
    Num,
    Obj,
    Arr,
    AES
}
