import Vue from 'vue';
import { $v, PromptBox } from '~/assets/js/public';
import axios from 'axios';
import fetch from '~/assets/config/fetch';
import { apiUrl1, apiUrl2, apiUrl3 } from '~/assets/config/env';
// apiUrl1:宋泽;apiUrl2:王强强;apiUrl3:杨明凯
/**
*添加清关公司
*/
export const addCustomsCompany = (debug, customsCompanyCode, customsCompanyName, freightPrefix, state) => fetch('/Customs/addCustomsCompany', { debug, customsCompanyCode, customsCompanyName, freightPrefix, state }, 'GET', 'fetch', 2);

/**
*添加清关公司
*/
export const editCustomsCompany = (debug, customsCompanyId, customsCompanyCode, customsCompanyName, freightPrefix, state) => fetch('/Customs/editCustomsCompany', { debug, customsCompanyId, customsCompanyCode, customsCompanyName, freightPrefix, state }, 'GET', 'fetch', 2);

// 递归查询税率
export const taxCategoryData = (taxCategoryPid) => fetch(`/tax/taxCategory`, { taxCategoryPid }, 'GET', 'fetch', 2);

/*
*清关公司列表
*/
export const getCustomsCompanyList = (debug, page, pagesize, showAll, customsCompanyCode, customsCompanyName, state) => fetch('/Customs/getCustomsCompanyList', { debug, page, pagesize, showAll, customsCompanyCode, customsCompanyName, state }, 'GET', 'fetch', 2);

/*
*国家列表
*/
export const getCustomsCountryList = (debug, showAll, state, type) => fetch('/Customs/getCustomsCountryList', { debug, showAll, state, type }, 'GET', 'fetch', 2);

/**
*添加仓库
*/
export const addWarehousea = (debug, warehouseName, countryCode, warehouseType, addresserName, addresserPhone, addresserAddr, addresserZip, addresserCity, addresserCounty, addresserProvince, addresserMail, addresserCompanyName, state, addresserProvinceId) => fetch('/Warehouse/addWarehousea',
	{ debug, warehouseName, countryCode, warehouseType, addresserName, addresserPhone, addresserAddr, addresserZip, addresserCity, addresserCounty, addresserProvince, addresserMail, addresserCompanyName, state, addresserProvinceId }, 'GET', 'fetch', 2);

/**
*仓库详情
*/
export const getWarehouseInfo = (debug, warehouseId) => fetch('/Warehouse/getWarehouseInfo', { debug, warehouseId }, 'POST', 'fetch', 2);

/**
*仓库修改
*/
export const editWarehousea = (debug, warehouseId, warehouseName, countryCode, warehouseType, addresserName, addresserPhone, addresserAddr, addresserZip, addresserCity, addresserCounty, addresserProvince, addresserMail, addresserCompanyName, state, addresserProvinceId) => fetch('/Warehouse/editWarehousea', { debug, warehouseId, warehouseName, countryCode, warehouseType, addresserName, addresserPhone, addresserAddr, addresserZip, addresserCity, addresserCounty, addresserProvince, addresserMail, addresserCompanyName, state, addresserProvinceId }, 'POST', 'fetch', 2);

/*
*仓库列表
*/
export const getWarehouseList = (debug, page, pagesize, showAll, warehouseCode, warehouseName, state) => fetch('/Warehouse/getWarehouseList', { debug, page, pagesize, showAll, warehouseCode, warehouseName, state }, 'GET', 'fetch', 2);

/*
* 根据仓库查询口岸
*/
export const getIeportByWarehouse = (params) => fetch('/ieport/info', params, 'GET', 'fetch', 9);

/*
*省市区列表
*/
export const getProvincesList = (debug, level) => fetch('/Provinces/getProvincesList', { debug, level }, 'GET', 'fetch', 2);

/*
*添加国内物流
*/
export const addInLogistics = (debug, logisticsName, logisticsCode, logisticsCompanyId, logisticsCompanyKey, logisticsCompanyName, logisticsType, logisticsPriceType, state, logisticsSender, logisticsAddressee) => fetch('/Logistics/addLogistics', { debug, logisticsName, logisticsCode, logisticsCompanyId, logisticsCompanyKey, logisticsCompanyName, logisticsType, logisticsPriceType, state, logisticsSender, logisticsAddressee }, 'GET', 'fetch', 2);

/*
*添加国外物流
*/
export const addOutLogistics = (debug, logisticsAddressee) => fetch('/Logistics/addLogistics', { debug, logisticsAddressee }, 'GET', 'fetch', 2);

/*
*物流列表
*/
export const logisticsList = (debug, page, pagesize, showAll, logisticsName, state) => fetch('/Logistics/getLogisticsList', { debug, page, pagesize, showAll, logisticsName, state }, 'GET', 'fetch', 2);

/*
*物流详情
*/
export const getLogisticsInfo = (debug, logisticsId) => fetch('/Logistics/getLogisticsInfo', { debug, logisticsId }, 'GET', 'fetch', 2);

/*
*线路审核
*/
export const setVerifyLine = (debug, lineId, lineVerifyUserId, lineVerifyUserName, lineVerifyOverruleReason, state) => fetch('/Line/setVerifyLine', { debug, lineId, lineVerifyUserId, lineVerifyUserName, lineVerifyOverruleReason, state }, 'POST', 'fetch', 2);

/*
*海关列表
*/
export const getCustomsIeport = (debug, page, pagesize, showAll, state) => fetch('/Customs/getCustomsIeport', { debug, page, pagesize, showAll, state }, 'GET', 'fetch', 2);

/*
*备案主体列表
*/
export const getCustomsFilingCompany = (debug, page, pagesize, showAll, state) => fetch('/Customs/getCustomsFilingCompany', { debug, page, pagesize, showAll, state }, 'GET', 'fetch', 2);

/**
*添加线路
*/
export const addLine = (debug, supplierId, name, lineType, customsId, iePortId, customPrice, packPrice, declarePrice, otherPrice, logisticsId, warehouseId, excessPieceNum, excessPiecePrice, excessSkuNum, excessSkuPrice, isInsurance, minInsurancePrice) => fetch('/Line/addLine',
	{ debug, supplierId, name, lineType, customsId, iePortId, customPrice, packPrice, declarePrice, otherPrice, logisticsId, warehouseId, excessPieceNum, excessPiecePrice, excessSkuNum, excessSkuPrice, isInsurance, minInsurancePrice }, 'GET', 'fetch', 2);

/*
*线路列表
*/
export const getLogisticsList = (debug, page, pagesize, showAll, supplierId, state, iePortId) => fetch('/Line/getLogisticsList', { debug, page, pagesize, showAll, supplierId, state, iePortId }, 'GET', 'fetch', 2);

/*
*线路详情
*/
export const getLineInfo = (debug, lineId) => fetch('/Line/getLineInfo', { debug, lineId }, 'GET', 'fetch', 2);

/*
*添加线路物流
*/
export const addLineLogistics = (debug, lineId, logisticsId, logistics, excessPieceNum, excessPiecePrice, excessSkuNum, excessSkuPrice, packPrice, customPrice, declarePrice, otherPrice, isInsurance, minInsurancePrice) => fetch('/Line/addLineLogistics', { debug, lineId, logisticsId, logistics, excessPieceNum, excessPiecePrice, excessSkuNum, excessSkuPrice, packPrice, customPrice, declarePrice, otherPrice, isInsurance, minInsurancePrice }, 'POST', 'fetch', 2);

/*
*获取线路物流信息
*/
export const getLineLogisticsInfo = (debug, lineId) => fetch('/Line/getLineLogisticsInfo', { debug, lineId }, 'GET', 'fetch', 2);

/*
*获取供货商线路信息
*/
export const adminMerchants = (pageNum, pageSize, adminMerchantCompanyName) => fetch('/adminMerchants/lines/count', { pageNum, pageSize, adminMerchantCompanyName }, 'GET', 'fetch', 0);

/*
*获取商品分类
*/
export const recursiveList = () => fetch('/goodsCategory/recursive', {}, 'GET', 'fetch', 0);
/*
*获取商品规格列表
*/
export const goodsSpecification = () => fetch('/goodsSpecification', {}, 'GET', 'fetch', 0);

/*
*计量单位列表
*/
export const getCustomsUnits = (debug, page, pagesize, showAll, state) => fetch('/Customs/getCustomsUnits', { debug, page, pagesize, showAll, state }, 'GET', 'fetch', 2);

/*
*商品库列表
*/
export const globalGoods = (pageNum, pageSize, adminMerchantId, globalGoodsSearch) => fetch('/globalGoods', { pageNum, pageSize, adminMerchantId, globalGoodsSearch }, 'GET', 'fetch', 0);

/*
*商品库详情
*/
export const globalGoodsInfo = (globalGoodsId) => fetch('/globalGoods/' + globalGoodsId, {}, 'GET', 'fetch', 0);

/*
*删除商品库
*/
export const delGlobalGood = (globalGoodsId) => fetch('/globalGoods/' + globalGoodsId, {}, 'DELETE', 'fetch', 0);

/*
*备案列表 SPU
*/
export const globalGoodsFilings = (pageNum, pageSize, adminMerchantId, filingStatus, filingStatusOffline, customsIeportCode, globalGoodsSearch, beginDate, endDate, globalGoodsFilingOnline) => fetch('/globalGoodsFilings', { pageNum, pageSize, adminMerchantId, filingStatus, filingStatusOffline, customsIeportCode, globalGoodsSearch, beginDate, endDate, globalGoodsFilingOnline }, 'GET', 'fetch', 0);

/*
*备案列表 SKU
*/
export const goodsSkuFilings = (pageNum, pageSize, adminMerchantId, filingStatus, offlineFilingStatus, customsIeportCode, globalGoodsSearch, beginDate, endDate, isSoldOut) => fetch('/globalGoodsFilings/skus', { pageNum, pageSize, adminMerchantId, filingStatus, offlineFilingStatus, customsIeportCode, globalGoodsSearch, beginDate, endDate, isSoldOut }, 'GET', 'fetch', 0);

/*
*备案列表详情
*/
export const globalGoodsFilingInfo = (globalGoodsFilingId) => fetch('/globalGoodsFilings/' + globalGoodsFilingId, {}, 'GET', 'fetch', 0);

/*
*商品发布列表
*/
export const globalGoodsFilingList = (pageNum, pageSize, adminMerchantId, isSoldOut, globalGoodsSearch, customsIeportCode, isOnlyShowWarningStock) => fetch('/globalGoodsFilings/skus/online', { pageNum, pageSize, adminMerchantId, isSoldOut, globalGoodsSearch, customsIeportCode, isOnlyShowWarningStock }, 'GET', 'fetch', 0);

/*
*商品发布列表修改库存
*/
export const globalGoodsModStock = (globalGoodsFilingSkuId, globalGoodsFilingSkuStock) => fetch('/globalGoodsFilings/sku/stock', { globalGoodsFilingSkuId, globalGoodsFilingSkuStock }, 'PUT', 'fetch', 0);

/*
*根据线路获取运费，清关费，库存操作费，订单申报费等
*/
export const getLinePriceSection = (debug, lineId, weight) => fetch('/Line/getLinePriceSection', { debug, lineId, weight }, 'GET', 'fetch', 2);

/*
*全部运营商列表
*/
export const agentList = (pageNum, pageSize, adminMerchantSearch) => fetch('/adminMerchants/agent', { pageNum, pageSize, adminMerchantSearch }, 'GET', 'fetch', 0);

/*
*全部供货商列表
*/
export const supplierList = (pageNum, pageSize, adminMerchantCountry, adminMerchantSearch) => fetch('/adminMerchants/supplier', { pageNum, pageSize, adminMerchantCountry, adminMerchantSearch }, 'GET', 'fetch', 0);

/*
*运营商详情
*/
export const agentInfo = (adminMerchantId) => fetch('/adminMerchants/agent/' + adminMerchantId, {}, 'GET', 'fetch', 0);

/*
*发起合同签署
*/
export const contractReq = (adminContractId, adminContractRequestDate, adminMerchantAgentId, adminMerchantRequestId, adminMerchantSupplierId) => fetch('/adminContractSigns/request', { adminContractId, adminContractRequestDate, adminMerchantAgentId, adminMerchantRequestId, adminMerchantSupplierId }, 'POST', 'fetch', 0);

/*
*合同列表
*/
export const contractList = (pageNum, pageSize, contractSignStatus, adminMerchantSupplierId, adminMerchantAgentId) => fetch('/adminContractSigns', { pageNum, pageSize, contractSignStatus, adminMerchantSupplierId, adminMerchantAgentId }, 'GET', 'fetch', 0);

/*
*回执合同签署
*/
export const agree = (adminContractSignId, adminMerchantAgentId, adminMerchantSupplierId, requestAdminMerchant) => fetch('/adminContractSigns/agree', { adminContractSignId, adminMerchantAgentId, adminMerchantSupplierId, requestAdminMerchant }, 'POST', 'fetch', 0);

/*
*拒绝合同签署
*/
export const refuse = (adminContractSignId, adminMerchantAgentId, adminMerchantSupplierId) => fetch('/adminContractSigns/refuse', { adminContractSignId, adminMerchantAgentId, adminMerchantSupplierId }, 'POST', 'fetch', 0);

/*
*供货商查看已合作代理商
*/
export const cooperateAgents = (pageNum, pageSize, adminMerchantSupplierId, adminMerchantAgentSearch, adminMerchantAgentShopChannelType, adminContractSignStatus) => fetch('/adminContractSigns/cooperateAgents', { pageNum, pageSize, adminMerchantSupplierId, adminMerchantAgentSearch, adminMerchantAgentShopChannelType, adminContractSignStatus }, 'GET', 'fetch', 0);

/*
*代理商查看已合作供货商
*/
export const cooperateSuppliers = (pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierSearch, adminMerchantSupplierCountry, adminContractSignStatus) => fetch('/adminContractSigns/cooperateSuppliers', { pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierSearch, adminMerchantSupplierCountry, adminContractSignStatus }, 'GET', 'fetch', 0);

/*
*供货商查看代理商Sk列表
*/
export const agentSkuList = (pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, goodsSku, isDistribution) => fetch('/agentGoods/skus', { pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, goodsSku, isDistribution }, 'GET', 'fetch', 0);

/*
*运营商商品列表
*/
export const agentGoods = (pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, agentGoodsSearch, agentGoodsOnline, isOnlyShowWarningStock) => fetch('/agentGoods', { pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, agentGoodsSearch, agentGoodsOnline, isOnlyShowWarningStock }, 'GET', 'fetch', 0);

/*
*运营商商品列表
*/
export const agentGoodsQueryAgentGoods = (pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, agentGoodsSearch, agentGoodsOnline, isOnlyShowWarningStock, agentGoodsDistribution) => fetch('/agentGoods/queryAgentGoods', { pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, agentGoodsSearch, agentGoodsOnline, isOnlyShowWarningStock, agentGoodsDistribution }, 'POST', 'fetch', 0);

/*
*运营商商品详情
*/
export const agentGoodInfo = (agentGoodsId) => fetch('/agentGoods/' + agentGoodsId, {}, 'GET', 'fetch', 0);

/*
*查询是否分销代理商品详情
*/
export const agentGoodInfoDistribution = (agentGoodsId, agentGoodsDistribution) => fetch('/agentGoods/detailIsDistribution', { agentGoodsId, agentGoodsDistribution }, 'POST', 'fetch', 0);

/*
*运营商自动上架商品开关
*/
export const agentSwitch = (adminMerchantAgentShopId) => fetch('/adminMerchantAgentShop/autoOnline/switch/' + adminMerchantAgentShopId, {}, 'POST', 'fetch', 0);

/*
*代理商品上下架
*/
export const onlineSwitch = (goodsSku) => fetch('/agentGoodsSku/online/switch', { goodsSku }, 'POST', 'fetch', 0);

/*
*代理商品上下架
*/
export const onlineSwitchNew = (params) => fetch('/agentGoodsSku/online/switch', params, 'POST', 'fetch', 0);


/*
*代理店铺设置预警库存
*/
export const warningStock = (adminMerchantAgentShopId, agentShopWarningStock) => fetch('/adminMerchantAgentShop/warningStock', { adminMerchantAgentShopId, agentShopWarningStock }, 'POST', 'fetch', 0);

/*
*供货商设置预警库存
*/
export const supWarningStock = (adminMerchantSupplierShopId, supplierShopWarningStock) => fetch('/adminMerchantSupplierShop/warningStock', { adminMerchantSupplierShopId, supplierShopWarningStock }, 'POST', 'fetch', 0);

/*
*运营商上下架商品
*/
export const goodSwitch = (agentGoodsId) => fetch('/agentGoods/online/switch/' + agentGoodsId, {}, 'POST', 'fetch', 0);

/*
*运营商查看供货价修改历史列表
*/
export const supplyPriceModify = (pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, agentGoodsName, modifyDateBegin, modifyDateEnd) => fetch('/agentGoods/supplyPriceModify', { pageNum, pageSize, adminMerchantAgentId, adminMerchantSupplierId, agentGoodsName, modifyDateBegin, modifyDateEnd }, 'GET', 'fetch', 0);

/*
*运营商新增店铺商品分类
*/
export const saveShopCate = (adminMerchantAgentShopId, agentShopGoodsCategoryId, agentShopGoodsCategoryLevel, agentShopGoodsCategoryName, agentShopGoodsCategoryNameEn, agentShopGoodsCategoryPid, agentShopGoodsCategorySort, agentShopGoodsCategoryImageUrl) => fetch('/adminMerchantAgentShopGoodsCategories', { adminMerchantAgentShopId, agentShopGoodsCategoryId, agentShopGoodsCategoryLevel, agentShopGoodsCategoryName, agentShopGoodsCategoryNameEn, agentShopGoodsCategoryPid, agentShopGoodsCategorySort, agentShopGoodsCategoryImageUrl }, 'POST', 'fetch', 0);

/*
*运营商修改店铺商品分类
*/
export const modifyShopCate = (adminMerchantAgentShopId, agentShopGoodsCategoryId, agentShopGoodsCategoryLevel, agentShopGoodsCategoryName, agentShopGoodsCategoryNameEn, agentShopGoodsCategoryPid, agentShopGoodsCategorySort, agentShopGoodsCategoryImageUrl) => fetch('/adminMerchantAgentShopGoodsCategories', { adminMerchantAgentShopId, agentShopGoodsCategoryId, agentShopGoodsCategoryLevel, agentShopGoodsCategoryName, agentShopGoodsCategoryNameEn, agentShopGoodsCategoryPid, agentShopGoodsCategorySort, agentShopGoodsCategoryImageUrl }, 'PUT', 'fetch', 0);

/*
*运营商获取店铺商品分类列表
*/
export const shopCateList = (adminMerchantAgentShopId, agentShopGoodsCategoryPid) => fetch('/adminMerchantAgentShopGoodsCategories/recursive', { adminMerchantAgentShopId, agentShopGoodsCategoryPid }, 'GET', 'fetch', 0);

/*
*运营商删除店铺商品分类
*/
export const delShopCate = (agentShopGoodsCategoryId) => fetch('/adminMerchantAgentShopGoodsCategories/' + agentShopGoodsCategoryId, {}, 'DELETE', 'fetch', 0);

/*
*登陆
*/
export const loginSass = (username, password) => fetch('/login', { username, password }, 'POST', 'fetch', 4);

/*
*境外商户列表
*/
export const foreignList = (pageNum, pageSize, adminMerchantSearch, adminMerchantCountry, adminMerchantVerifyStatus, adminMerchantSource) => fetch('/adminMerchants/foreign', { pageNum, pageSize, adminMerchantSearch, adminMerchantCountry, adminMerchantVerifyStatus, adminMerchantSource }, 'GET', 'fetch', 0);

/*
*境外商户详情
*/
export const foreignInfo = (adminMerchantId) => fetch('/adminMerchants/foreign/' + adminMerchantId, {}, 'GET', 'fetch', 0);

/*
*商户审核通过
*/
export const verifyAgree = (adminMerchantId, adminUserId, verifyMsg) => fetch('/adminMerchants/verifyAgree', { adminMerchantId, adminUserId, verifyMsg }, 'POST', 'fetch', 0);

/*
*商户审核驳回
*/
export const verifyRefuse = (adminMerchantId, adminUserId, verifyMsg) => fetch('/adminMerchants/verifyRefuse', { adminMerchantId, adminUserId, verifyMsg }, 'POST', 'fetch', 0);

/*
*商户添加负责人
*/
export const operators = (adminMerchantId, operatorEmployeeBodies) => fetch('/adminMerchants/operators', { adminMerchantId, operatorEmployeeBodies }, 'POST', 'fetch', 0);

/*
*境内商户列表
*/
export const internalList = (pageNum, pageSize, adminMerchantSearch, adminMerchantVerifyStatus, adminMerchantSource) => fetch('/adminMerchants/internal', { pageNum, pageSize, adminMerchantSearch, adminMerchantVerifyStatus, adminMerchantSource }, 'GET', 'fetch', 0);

/*
*境内商户详情
*/
export const internalInfo = (adminMerchantId) => fetch('/adminMerchants/internal/' + adminMerchantId, {}, 'GET', 'fetch', 0);

/*
*服务商列表
*/
export const serviceList = (pageNum, pageSize, adminMerchantSearch, verifyStatus, shopTagId, adminMerchantOperatorId) => fetch('/adminMerchants/service', { pageNum, pageSize, adminMerchantSearch, verifyStatus, shopTagId, adminMerchantOperatorId }, 'GET', 'fetch', 0);

/*
*境外注册
*/
export const foreignReg = (username, password, merchantRole, email, phone, adminMerchantSource) => fetch('/adminMerchants/foreign', { username, password, merchantRole, email, phone, adminMerchantSource }, 'POST', 'fetch', 0);

/*
*境内注册
*/
export const internalReg = (username, password, merchantRole, email, phone, adminMerchantSource, sysShopTagId) => fetch('/adminMerchants/internal', { username, password, merchantRole, email, phone, adminMerchantSource, sysShopTagId }, 'POST', 'fetch', 0);

/*
*查询供货商详情
*/
export const supplierDetai = (adminUserId, adminMerchantId) => fetch('/adminMerchants/supplier/detail', { adminUserId, adminMerchantId }, 'GET', 'fetch', 0);

/*
*更新供货商店铺
*/
export const supplierShops = (companyTradeCode, filingFeeCustoms, filingFeeDirect, supplierShopId, sysShopTagIds) => fetch('/adminMerchantSupplierShop', { companyTradeCode, filingFeeCustoms, filingFeeDirect, supplierShopId, sysShopTagIds }, 'POST', 'fetch', 0);

/*
*查询代理商详情
*/
export const agentDetail = (adminUserId, adminMerchantId) => fetch('/adminMerchants/agent/detail', { adminUserId, adminMerchantId }, 'GET', 'fetch', 0);

/*
*更新服务商店铺
*/
export const agentShops = (agentShopId, sysShopTagIds) => fetch('/adminMerchantAgentShop', { agentShopId, sysShopTagIds }, 'POST', 'fetch', 0);

/*
*查询服务商详情
*/
export const serviceDetail = (adminUserId, adminMerchantId) => fetch('/adminMerchants/service/detail', { adminUserId, adminMerchantId }, 'GET', 'fetch', 0);

/*
*更新服务商店铺
*/
export const serviceShops = (serviceShopId, sysShopTagIds) => fetch('/adminMerchantServiceShops', { serviceShopId, sysShopTagIds }, 'POST', 'fetch', 0);

/*
*查询员工列表
*/
export const adminEmployeList = (pageNum, pageSize) => fetch('/adminEmployees', { pageNum, pageSize }, 'GET', 'fetch', 0);

/*
*查询最新合同
*/
export const getNew = (adminContractType) => fetch('/adminContracts/getNew', { adminContractType }, 'GET', 'fetch', 0);

/*
*WMM查询订单信息列表
*/
export const selectOrderInfoList = (pageNumber, pageSize, serverType, orderStatus, logisticStatus, payStatus, clearanceStatus, beginTime, endTime, orderNumber, logisticNumber, clearanceNumber, payNumber, supplierName, agentName, warehouseId) => fetch('/wmmorder/selectOrderInfoList', { pageNumber, pageSize, serverType, orderStatus, logisticStatus, payStatus, clearanceStatus, beginTime, endTime, orderNumber, logisticNumber, clearanceNumber, payNumber, supplierName, agentName, warehouseId }, 'GET', 'fetch', 3);

/*
*WMM导出订单信息列表
*/
export const exportOrderInfoList = (orderNumbers, serverType, clearanceStatus, beginTime, endTime, orderNumber, logisticNumber, clearanceNumber, payNumber, supplierName, agentName, status) => fetch('/wmmorder/exportOrderInfoList', { orderNumbers, serverType, clearanceStatus, beginTime, endTime, orderNumber, logisticNumber, clearanceNumber, payNumber, supplierName, agentName, status }, 'GET', 'fetch', 3);

/*
*WMM查询订单物流支付商品详细信息
*/
export const selectOrderDetailInfo = (orderNumber) => fetch('/wmmorder/selectOrderDetailInfo', { orderNumber }, 'GET', 'fetch', 3);

/*
*同步有赞
*/
export const syncYouzan = (agentGoodsId) => fetch('/agentGoods/syncYouzan', { agentGoodsId }, 'POST', 'fetch', 0);

/*
*oms订单列表
*/
export const customsOrderList = (debug, showAll, page, pagesize, orderServerType, orderNumber, logisticsMasterNumber, orderClearanceStatus, customsOrderClearanceCode, OrderPayId, agentId, supplierId, orderStatus, pushJWStatus, startTime, endTime) => fetch('/Order/customsOrderList', { debug, showAll, page, pagesize, orderServerType, orderNumber, logisticsMasterNumber, orderClearanceStatus, customsOrderClearanceCode, OrderPayId, agentId, supplierId, orderStatus, pushJWStatus, startTime, endTime }, 'POST', 'fetch', 6);

/*
* 报关订单列表 Java版
*/
export const customsOrderList4New = (params) => fetch('/orderadmin/declareCustomList', params, 'POST', 'fetch', 3);

/*
*oms订单详情
*/
export const customsOrderInfo = (debug, orderNumber) => fetch('/Order/customsOrderInfo', { debug, orderNumber }, 'get', 'fetch', 6);

/*
*oms订单商品列表
*/
export const customsOrderDetailsList = (debug, orderNumber) => fetch('/Order/customsOrderDetailsList', { debug, orderNumber }, 'POST', 'fetch', 6);

/*
*oms订单详情修改
*/
export const updataOrderInfo = (debug, orderNumber, orderPeopleCardName, orderPeopleCardNumber, orderPeopleCardPhone, customsOrderPayId, orderOtherPrice) => fetch('/Order/updataOrderInfo', { debug, orderNumber, orderPeopleCardName, orderPeopleCardNumber, orderPeopleCardPhone, customsOrderPayId, orderOtherPrice }, 'GET', 'fetch', 6);

/*
*修改oms订单商品
*/
export const updataOrderDetails = (debug, orderNumber, goodsSku, goodsKjtunit1, goodsKjtunit2, goodsLegalnum1, goodsLegalnum2) => fetch('/Order/updataOrderDetails', { debug, orderNumber, goodsSku, goodsKjtunit1, goodsKjtunit2, goodsLegalnum1, goodsLegalnum2 }, 'GET', 'fetch', 6);

/*
*oms订单和清单申报
*/
export const customsOrderFiling = (debug, orderNumber, type) => fetch('/Order/CustomsOrderFiling', { debug, orderNumber, type }, 'POST', 'fetch', 6);

/*
*oms订单撤销
*/
export const customsFilingCancel = (debug, orderNumber, closeRemark) => fetch('/Order/customsFilingCancel', { debug, orderNumber, closeRemark }, 'POST', 'fetch', 6);

/*
*oms订单取消
*/
export const customsCancelOrder = (debug, orderNumber, closeRemark) => fetch('/Order/customsCancelOrder', { debug, orderNumber, closeRemark }, 'POST', 'fetch', 6);

/*
* 系统标签
*/
export const sysShopTags = (tagType) => fetch('/sysShopTags', { tagType }, 'GET', 'fetch', 0);

/*
* 跨境学堂列表
*/
export const cbSchoolList = (pageNum, pageSize) => fetch('/cms/posts', { pageNum, pageSize }, 'GET', 'fetch', 7);

/*
* 跨境学堂新增
*/
export const cbSchoolAdd = (postId, postImage, postTitle, postTitleEn, postContent, postContentEn) => fetch('/cms/posts', { postId, postImage, postTitle, postTitleEn, postContent, postContentEn }, 'POST', 'fetch', 7);

/*
* 跨境学堂修改
*/
export const cbSchoolUpe = (postId, postImage, postTitle, postTitleEn, postContent, postContentEn) => fetch('/cms/posts', { postId, postImage, postTitle, postTitleEn, postContent, postContentEn }, 'PUT', 'fetch', 7);

/*
* 跨境学堂详情
*/
export const cbSchoolDetail = (postId) => fetch('/cms/posts/' + postId, {}, 'GET', 'fetch', 7);

/*
* 跨境学堂下架
*/
export const cbSchoolOff = (postId) => fetch('/cms/posts/off', { postId }, 'POST', 'fetch', 7);

/*
* 跨境学堂发布
*/
export const cbSchoolOn = (postId) => fetch('/cms/posts/publish', { postId }, 'GET', 'fetch', 7);

/*
*oms分销商列表
*/
export const getOrderAgentName = (debug) => fetch('/Order/getOrderAgentName', { debug }, 'POST', 'fetch', 6);

/*
*oms供货商列表
*/
export const getOrderSupplierName = (debug) => fetch('/Order/getOrderSupplierName', { debug }, 'POST', 'fetch', 6);

/*
*添加资料
*/
export const additions = (additionFile, additionIntro, additionName, adminMerchantAdditionId, adminMerchantId, adminUserId, validBeginTime, validEndTime) => fetch('/adminMerchantAdditions', { additionFile, additionIntro, additionName, adminMerchantAdditionId, adminMerchantId, adminUserId, validBeginTime, validEndTime }, 'POST', 'fetch', 0);

/*
*资料列表
*/
export const additionsList = (adminMerchantId) => fetch('/adminMerchantAdditions', { adminMerchantId }, 'GET', 'fetch', 0);

/*
*资料删除
*/
export const delitions = (adminMerchantAdditionId) => fetch('/adminMerchantAdditions/' + adminMerchantAdditionId, {}, 'DELETE', 'fetch', 0);

/*
*资料详情
*/
export const itionsMsg = (adminMerchantAdditionId) => fetch('/adminMerchantAdditions/' + adminMerchantAdditionId, {}, 'GET', 'fetch', 0);

/*
*查询商户账户结算信息
*/
export const getAccountSettle = (adminMerchantId) => fetch('/adminMerchantAccountSettle', { adminMerchantId }, 'GET', 'fetch', 0);

/*
*添加专款专用账户
*/
export const addSeparete = (adminMerchantId, accountSettleItems, accountSettleType) => fetch('/adminMerchantAccountSettle/separete', { adminMerchantId, accountSettleItems, accountSettleType }, 'POST', 'fetch', 0);

/*
*添加统一账户
*/
export const addUnique = (adminMerchantId, accountSettleItems, accountSettleType) => fetch('/adminMerchantAccountSettle/unique', { adminMerchantId, accountSettleItems, accountSettleType }, 'POST', 'fetch', 0);

/*
*供货商店铺商品设置预警库存
*/
export const skuWarningStock = (globalGoodsSkuId, globalGoodsWarningStock) => fetch('/globalGoodsFilings/sku/warningStock', { globalGoodsSkuId, globalGoodsWarningStock }, 'POST', 'fetch', 0);

/*
*代理店铺商品设置预警库存
*/
export const agentWarningStock = (agentGoodsSkuId, agentGoodsWarningStock) => fetch('/agentGoods/sku/warningStock', { agentGoodsSkuId, agentGoodsWarningStock }, 'POST', 'fetch', 0);

/*
*获取邮箱验证码
*/
export const getVerifyEmailCode = (email) => fetch('/verification/email', { email }, 'GET', 'fetch', 0);

/*
*获取手机验证码
*/
export const getVerifyPhoneCode = (phone) => fetch('/verification/phone', { phone }, 'GET', 'fetch', 0);

/*
* 修改密码
*/
export const forgetPassword = (adminMerchantType, phone, email, phoneCode, emailCode, newPassword) => fetch('/adminMerchants/forgetPassword', { adminMerchantType, phone, email, phoneCode, emailCode, newPassword }, 'POST', 'fetch', 0);

/*
*开启/关闭定时任务禁用商户
*/
export const switchJobEnableBody = (adminMerchantId, adminMerchantIsStop) => fetch('/adminMerchants/switchJobEnable', { adminMerchantId, adminMerchantIsStop }, 'POST', 'fetch', 0);

/*
*创建线路
*/
export const dmsLine = (
	serviceSettlement,
	dmsNewLineName, 
	dmsNewLineType,
	customsId,
	iePortId,
	dmsLineLogisticsId,
	warehouseId,
	isInsurance,
	minInsurancePrice,
	bondedWarehouseQuotationBodies,
	dmsLineId
	) => fetch('/dmsLine', {
	serviceSettlement,
	dmsNewLineName, 
	dmsNewLineType,
	customsId,
	iePortId,
	dmsLineLogisticsId,
	warehouseId,
	isInsurance,
	minInsurancePrice,
	bondedWarehouseQuotationBodies,
	dmsLineId
	}, 'POST', 'fetch', 9);
	
/*
*查询线路列表
*/
export const dmsLineList = (
	pageNum,
  pageSize,
) => fetch('/dmsLine/dmsLines', {
	pageNum,
  pageSize,
}, 'GET', 'fetch', 9)

/**
查询线路详情
*/
export const dmsLineId = (dmsLineId) => fetch('/dmsLine/' + dmsLineId + '', { }, 'GET', 'fetch', 9);

/**
配置商户
*/
export const configureMerchant = (dmsLineId, dmsLineMerchantSupplierId, dmsLineMerchantAgentId, dmsLineMerchantFeeReceiver, isDeductGoodsFee ) => fetch('/dmsLine/configureMerchant/', { dmsLineId, dmsLineMerchantSupplierId, dmsLineMerchantAgentId, dmsLineMerchantFeeReceiver, isDeductGoodsFee }, 'POST', 'fetch', 9);

/**
查询商户线路列表
*/
export const findMerchantLine = (pageNum, pageSize, dmsLineId) => fetch('/dmsLine/findMerchantLine', { pageNum, pageSize, dmsLineId }, 'GET', 'fetch', 9)

/**
查询分销商
*/
export const findMerchantGent = (agentName, agentId) => fetch('/dmsLine/findMerchantGent', { agentName, agentId }, 'GET', 'fetch', 9)

/**
查询配置商户信息
*/
export const findMerchantInFormation = (pageNum, pageSize, dmsLineId) => fetch('/dmsLine/findMerchantInFormation', { pageNum, pageSize, dmsLineId }, 'GET', 'fetch', 9)

/**
根据id查询线路列表
*/
export const findLineId = (lineId) => fetch('/dmsLine/findLineId', { lineId }, 'GET', 'fetch', 9)

/**
根据线路ID获取服务费结算模式
*/
export const findServiceFeeSettlementModelByLineId = (lineId) => fetch('/dmsLine/findLineSettlement', { lineId }, 'GET', 'fetch', 9)

/**
更改线路
*/
export const updateLine = (lineMechatId, dmsNewLineId) => fetch('/dmsLine/updateLine?lineMechatId=' + lineMechatId + '&dmsNewLineId=' + dmsNewLineId + '', {}, 'PUT', 'fetch', 9)

/**
更改供货商分销商
*/
export const updateMerchantAndSupplier = (params) => fetch('/dmsLine/updateMerchantAndSupplier', { params }, 'PUT', 'fetch', 9)

/**
根据线路商户配置编号查询明细
*/
export const findMerchantAndMerchantById = (lineMechatId) => fetch('/dmsLine/findMerchantAndMerchantById', { lineMechatId }, 'GET', 'fetch', 9)

/**
 * 
 * @param {*} assBillNo 
 * @returns 
 */
export const rePushJW = (assBillNo) => fetch('/orderJw/rePushToJwWms', { assBillNo }, 'GET', 'fetch', 8)

/**
 * 
 * @param {sku params} params 
 * @returns
 */
export const updateGlobalGoodsSkuInfo = (params) => fetch('/globalGoodsFilings/updateGoodsSku', { params }, 'PUT', 'fetch', 0)

/**
 * 获取分销商订单状态回调地址
 * @param {*} adminMerchantId 
 * @returns 
 */
export const getMerchantInterfaceInfo = (adminMerchantId) => fetch('/adminMerchants/distributor/' + adminMerchantId, {}, 'GET', 'fetch', 0);

/**
 * 修改分销商订单状态回调地址
 * @param {*} params 
 * @returns 
 */
export const updateMerchantInterfaceInfo = (params) => fetch('/adminMerchants/modifyAddress', { params }, 'PUT', 'fetch', 0);

/**
 * 查询分销商SKU
 * @param {*} params 
 * @returns 
 */
export const getDistributorSkus = (params) => fetch('/agentGoodsSku/getAgentGoodsSkuInfo', params, 'POST', 'fetch', 0);

/**
 * 获取黑名单列表
 * @param {*} params 
 * @returns 
 */
export const getBlacklists = (params) => fetch('/adminBlacklist/findAllBlacklist', params, 'GET', 'fetch', 4);

/**
 * 新增黑名单
 * @param {*} params 
 * @returns 
 */
export const addBlacklist = (params) => fetch('/adminBlacklist/insertBlacklist', params, 'POST', 'fetch', 4);

/**
 * 删除黑名单
 * @param {*} params 
 * @returns 
 */
export const deleteBlacklist = (params) => fetch('/adminBlacklist/deleteBlacklistState', { params }, 'PUT', 'fetch', 4);



/**
 * sku库存查询
 * @param {*} params 
 * @returns 
 */
export const searchSkuStock = (params) => fetch('/goodsgc/selectStockSku', params, 'GET', 'fetch', 0);

/**
 * sku警示库存更新
 * @param {*} params 
 * @returns 
 */
export const updateSkuWarningStock = (params) => fetch('/goodsgc/updateWarning', params, 'POST', 'fetch', 0);


/**
 * 分销商 数据分析-商品动销
 * @param {*} params 
 * @returns 
 */
export const getDynamicSalesGoods = (params) => fetch('/goodsFc', params, 'POST', 'fetch', 0);

/**
 * Admin 数据分析-商品动销
 * @param {*} params 
 * @returns 
 */
export const getDynamicSalesGoods4Admin = (params) => fetch('/goodsAdmin', params, 'POST', 'fetch', 0);

/**
 * Admin 数据分析-商品动销明细
 * @param {*} params 
 * @returns 
 */
export const getDynamicSalesGoodsDetail4Admin = (params) => fetch('/goodsAdmin', params, 'GET', 'fetch', 0);

/**
 * Admin 数据分析-查询商户报表
 * @param {*} params 
 * @returns 
 */
export const queryMerchantStatement4Admin = (params) => fetch('/goodsAdmin/report', params, 'POST', 'fetch', 0);

/**
 * Admin 数据分析-商品日报
 * @param {} params 
 * @returns 
 */
export const queryGoodsDailyReport4Admin = (params) => fetch('/commodityDaily/selectCommodityDaily', params, 'GET', 'fetch', 0);

/**
 * Admin 数据分析-商品日报详情
 * @param {} params 
 * @returns 
 */
export const queryGoodsDailyReportDetail4Admin = (params) => fetch('/commodityDaily/selectCommodityDetails', params, 'GET', 'fetch', 0);

/**
 * Admin 商品管理-库存查询
 * @param {} params 
 * @returns 
 */
export const queryStock4Admin = (params) => fetch('/commodityDaily/selectStock', params, 'GET', 'fetch', 0);

/**
 * Admin 商品管理-库存查询修改问题件
 * @param {} params 
 * @returns 
 */
export const updateProblemPiece = (params) => fetch('/commodityDaily/updateProblem', params, 'POST', 'fetch', 0);

/**
 * Admin 数据分析-地区销售报表-查询全部城市
 * @param {} params 
 * @returns 
 */
export const queryCityList = (params) => fetch('/adminRegionalSales/city', params, 'GET', 'fetch', 0);

/**
 * Admin 数据分析-地区销售报表-查询省的城市
 * @param {} params 
 * @returns 
 */
export const queryCityListByProvince = (params) => fetch('/adminRegionalSales/city', params, 'GET', 'fetch', 0);

/**
 * Admin 数据分析-地区销售报表-查询全部省份
 * @param {} params 
 * @returns 
 */
export const queryProvinceList = (params) => fetch('/adminRegionalSales/province', params, 'GET', 'fetch', 0);

/**
 * Admin 数据分析-地区销售报表-查询城市数据
 * @param {} params 
 * @returns 
 */
export const querySalesByCity = (params) => fetch('/adminRegionalSales/cityReport', params, 'GET', 'fetch', 0);

/**
 * Admin 数据分析-地区销售报表-查询省份数据
 * @param {} params 
 * @returns 
 */
export const querySalesByProvince = (params) => fetch('/adminRegionalSales/provincialReport', params, 'GET', 'fetch', 0);

/**
 * Admin 线路管理-物流公司管理
 * @param {} params 
 * @returns 
 */
export const queryAllLogisticsCompany = (params) => fetch('/Logistics/getLogisticsCompayList', params, 'GET', 'fetch', 2);

/**
 * Admin 修改商户有赞店铺是否开通了税费分离
 * @param {} params 
 * @returns 
 */
export const updateTaxSeparate4Youzan = (params) => fetch('/adminMerchants/internal/editYouZanShopIsTaxSeparate', { params }, 'PUT', 'fetch', 0);


/**
 * Admin 移库列表页分页查询
 * @param {} params 
 * @returns 
 */
export const queryMoveWarehouseList = (params) => fetch('/globalGoodsFilingSkuTransfer/select', params, 'GET', 'fetch', 0);

/**
 * Admin 移库修改
 * @param {} params 
 * @returns 
 */
export const updateMoveWarehouse = (params) => fetch('/globalGoodsFilingSkuTransfer/update', params, 'GET', 'fetch', 0);

/**
 * Admin 查询所在仓库
 * @param {} params 
 * @returns 
 */
export const queryWarehouseList = (params) => fetch('/globalGoodsFilingSkuTransfer/selectCustomsIeportList', params, 'GET', 'fetch', 0);

/**
 * Admin 更新境外供货商的备注
 * @param {} params 
 * @returns 
 */
export const updateRemark4Supplier = (params) => fetch('/adminMerchants/updateAdminMerchantInfo', params, 'POST', 'fetch', 0);

/**
 * Admin 查看商品分类税率
 * @param {} params 
 * @returns 
 */
export const queryGoodsTaxRate = (params) => fetch('/goodsCategory/selectCategory', params, 'GET', 'fetch', 0);

/**
 * Admin 修改商品分类税率
 * @param {} params 
 * @returns 
 */
export const updateGoodsTaxRate = (params) => fetch('/goodsCategory/updateCategory', { params }, 'PUT', 'fetch', 0);

/*
* 撤消单列表
*/
export const cancelOrderList = (params) => fetch('/cancellation/cancellationList', params, 'POST', 'fetch', 8);

/*
* 撤消单详情
*/
export const cancelOrderDetail = (params) => fetch('/cancellation/clearanceDetails', params, 'GET', 'fetch', 8);

/*
* 撤消单导出
*/
export const cancelOrderExport = (params) => fetch('/cancellation/exportClearance', params, 'POST', 'fetch', 8);

/*
* 撤消单提交 
*/
export const cancelOrderSubmit = (params) => fetch('/cancellation/revoke', params, 'POST', 'fetch', 8);

/*
* 同步清单状态 
*/
export const syncCustomsStatus = (params) => fetch('/syncCmsStatus/declare/clearance', params, 'POST', 'fetch', 8);


/*
* 修改员工账号状态
*/
export const updateEmployeeAccountStatus = (params) => fetch('/adminUser/updateEmployeeStatus', { params }, 'PUT', 'fetch', 4);

/*
* 修改员工账号密码
*/
export const updateEmployeeAccountPassword = (params) => fetch('/adminUser/updatePassword', params, 'POST', 'fetch', 4);

/*
* 查询WMS店铺列表
*/
export const queryWMSShopList = (params) => fetch('/wmsShop/list', params, 'GET', 'fetch', 5);

/*
* 查询商户绑定的CMS店铺信息
*/
export const queryMerchantWMSShop = (shopId) => fetch('/wmsShop/getMerchantShopInfo/' + shopId, {}, 'GET', 'fetch', 5);

/*
* 新增WMS店铺
*/
export const addMerchantWMSShop = (params) => fetch('/wmsShop/add', params, 'POST', 'fetch', 5);

/*
* 修改WMS店铺
*/
export const updateMerchantWMSShop = (params) => fetch('/wmsShop/modify', params, 'POST', 'fetch', 5);

/*
* 商户与cms店铺绑定/更新
*/
export const bindMerchantWMSShop = (params) => fetch('/wmsShop/adminMerchantWmsShop', params, 'POST', 'fetch', 5);

// 团购活动相关接口
/*
* 团购活动列表
*/
export const queryGroupBuyActivityList = (params) => fetch('/presale/group', params, 'GET', 'fetch', 3);

/*
* 团购活动详情
*/
export const queryGroupBuyActivityDetail = (groupId) => fetch('/presale/group/details/' + groupId, {}, 'GET', 'fetch', 3);

/*
* 团购活动详情里的商品列表
*/
export const queryGroupBuyActivityGoodsList = (groupId, params) => fetch('/presale/group/goods-details/' + groupId, params, 'GET', 'fetch', 3);

/*
* 新增或更新团购活动
*/
export const addOrUpdateGroupBuyActivity = (params) => fetch('/presale/group', params, 'POST', 'fetch', 3);

/*
* 开启团购活动
*/
export const turnOnGroupBuyActivity = (groupId) => fetch('/presale/group/begin/' + groupId, {}, 'PUT', 'fetch', 3);

/*
* 团购活动手动截单
*/
export const cutOrder4GroupBuyActivity = (groupId) => fetch('/presale/group/finish/' + groupId, {}, 'PUT', 'fetch', 3);

/*
* 团购活动作废
*/
export const invalid4GroupBuyActivity = (groupId) => fetch('/presale/group/cancel/' + groupId, {}, 'PUT', 'fetch', 3);

/*
* 团购活动商品手动截单
*/
export const cutGoods4GroupBuyActivity = (params) => fetch('/presale/group/sku-finish', { params }, 'PUT', 'fetch', 3);

/*
* 开启团购活动
*/
export const startGroupBuyActivity = (groupId) => fetch('/presale/group/begin/' + groupId, {}, 'PUT', 'fetch', 3);

/*
* 同步团购活动
*/
export const syncGroupBuyActivity = (groupId) => fetch('/presale/group/base-info/send/' + groupId, {}, 'GET', 'fetch', 3);

/*
* 预售商品列表
*/
export const presaleGoodsList = (params) => fetch('/presale/goods', params, 'GET', 'fetch', 0);

/*
* 预售商品详情
*/
export const presaleGoodsDetail = (params) => fetch('/presale/goods/detail', params, 'GET', 'fetch', 0);

/*
* 预售商品选品池，即备案成功的商品列表
*/
export const presaleGoodsFilingSkuList = (params) => fetch('/presale/goods/filingSku/list', params, 'GET', 'fetch', 0);

/*
* 从预售商品选品池中移除
*/
export const removeGoodsFromPresalePool = (params) => fetch('/presale/goods', params, 'DELETE', 'fetch', 0);

/*
* 从预售商品选品池中移除
*/
export const addGoods2PresalePool = (params) => fetch('/presale/goods', params, 'POST', 'fetch', 0);

/*
* 采购单里的商品列表
*/
export const goodsList4PurchaseOrder = (params) => fetch('/presale/overseas/purchaseSheet', params, 'POST', 'fetch', 3);

/*
* 采购单顶部进度条
*/
export const purchaseOrderProgree = (params) => fetch('/presale/overseas/progress', params, 'GET', 'fetch', 3);

/*
* 团购单商品确认有货接口
*/
export const confirmQty4PurchaseOrder = (params) => fetch('/presale/overseas/confirmQty', { params }, 'PUT', 'fetch', 3);

/*
* 团购单商品确认部分有货接口
*/
export const confirmQtyPart4PurchaseOrder = (params) => fetch('/presale/overseas/confirmQtyPart', { params }, 'PUT', 'fetch', 3);

/*
* 批量确认有货接口
*/
export const batchConfirmQty4PurchaseOrder = (params) => fetch('/presale/overseas/confirmQty/batch', { params }, 'PUT', 'fetch', 3);

/*
* 境外付款接口
*/
export const overseasPay4PurchaseOrder = (params) => fetch('/presale/overseas/pay', { params }, 'PUT', 'fetch', 3);

/*
* 出口报关
*/
export const exportCustomsDeclare = (params) => fetch('/presale/overseas/exportCustoms', { params }, 'PUT', 'fetch', 3);

/*
* 干线登记
*/
export const transportRegister = (params) => fetch('/presale/overseas/transport', { params }, 'PUT', 'fetch', 3);

/*
* 进境报关
*/
export const entryCustomsDeclare = (params) => fetch('/presale/overseas/importCustoms', { params }, 'PUT', 'fetch', 3);

/*
* 境外提货
*/
export const overseasPickup = (params) => fetch('/presale/overseas/takeDelivery', { params }, 'PUT', 'fetch', 3);

/*
* 添加备注
*/
export const addOverseasPickupRemark = (params) => fetch('/presale/overseas/goods/remark', { params }, 'PUT', 'fetch', 3);

/*
* 查看境外采购节点记录（查看国际物流轨迹）
*/
export const getOverseasPickupLogistics = (params) => fetch('/presale/overseas/purchaseNodes', params, 'GET', 'fetch', 3);



/*
* 获取SKU的税率和售价高额税临界值
*/
export const getSkuTaxRateInfo = (params) => fetch('/globalGoodsFilingSku/tax-rate-info', params, 'GET', 'fetch', 0);

/*
* 计算预售商品溢价
*/
export const calculateGoodsPremium = (params) => fetch('/presale/goods/profit-price-calculate', params, 'POST', 'fetch', 0);

/*
* 计算预售团购价
*/
export const calculateGroupBuyPrice = (params) => fetch('/presale/goods/group-price-calculate', params, 'POST', 'fetch', 0);

/*
* 查询审核中的商品团购价
*/
export const queryAuditGoodsGroupBuyPrice = (params) => fetch('/presale/goods/group-buying-price/auditing', params, 'GET', 'fetch', 0);

/*
* 审核商品团购价
*/
export const auditGoodsGroupBuyPrice = (params) => fetch('/presale/goods/group-buying-price/request', params, 'POST', 'fetch', 0);

/*
* 团购定价商品列表
*/
export const getPresaleGoodsAuditPriceList = (params) => fetch('/presale/goods/group-buying-price/request', params, 'GET', 'fetch', 0);

/*
* 预售价详情
*/
export const getAuditDetailPrice = (params) => fetch('/presale/goods/priceDetail', params, 'GET', 'fetch', 0);

/*
* 团购定价审核
*/
export const auditGroupPrice = (params) => fetch('/presale/goods/group-buying-price/audit', { params }, 'PUT', 'fetch', 0);

/*
* 上传文件入库
*/
export const uploadSaveFile = (params) => fetch('/upload/saveFile', params, 'POST', 'fetch', 5);

/**
 * 组合商品套餐列表
 */
export const compositionGoodsList = (params) => fetch('/presale/packageList', params, 'GET', 'fetch', 0);

/**
 * 创建组合商品套餐
 */
export const createCompositionGoods = (params) => fetch('/presale/productPackage', params, 'POST', 'fetch', 0);

/**
 * 移除组合商品套餐
 */
export const removeCompositionGoods = (params) => fetch('/presale/deletePackage?combinationSpuId=' + params.combinationSpuId, {}, 'DELETE', 'fetch', 0);

/**
 * 更新组合商品套餐
 */
export const updateCompositionGoods = (params) => fetch('/presale/updatePackage', params, 'POST', 'fetch', 0);

/**
 * 组合商品套餐详情
 */
export const compositionGoodsDetail = (params) => fetch('/presale/selectAll', params, 'GET', 'fetch', 0);

/**
 * 活动下的套餐
 */
export const groupCompositionList = (groupId, params) => fetch('/presale/group/combo-goods-details/' + groupId, params, 'GET', 'fetch', 3);

/**
 * 获取商品计算器已发布商品列表
 */
export const queryPublishedGoodsListByCalculator = (params) => fetch('/globalGoodsFilingSku/goods-calculator-distributed-list', params, 'GET', 'fetch', 0);

/**
 * 计算代理商利润
 */
export const calculateAgentProfit = (params) => fetch('/globalGoodsFilingSku/agent-profit-calculate', params, 'POST', 'fetch', 0);

/**
 * 计算零售价
 */
export const calculateRetailPrice = (params) => fetch('/globalGoodsFilingSku/retail-price-calculate', params, 'POST', 'fetch', 0);

/**
 * 提交零售价审核
 */
export const submitRetailPriceAudit = (params) => fetch('/globalGoodsFilingSku/retail-price-apply', params, 'POST', 'fetch', 0);

/**
 * 获取零售价审核
 */
export const queryRetailPriceAudit = (params) => fetch('/globalGoodsFilingSku/retail-price-apply', params, 'GET', 'fetch', 0);

/**
 * 获取零售价审核详情
 */
export const queryRetailPriceAuditDetail = (params) => fetch('/globalGoodsFilingSku/retail-price-apply/detail', params, 'GET', 'fetch', 0);

/**
 * 审核零售价
 */
export const auditRetailPrice = (params) => fetch('/globalGoodsFilingSku/retail-price-apply/audit/' + params.applyId + '/' + params.applyStatus, {}, 'POST', 'fetch', 0);

/**
 * 问题单列表订单查询
 */
export const queryProblemOrderList = (params) => fetch('/question/questionList', params, 'POST', 'fetch', 3);

/**
 * 问题单配置更新
 */
export const updateProblemOrderSetting = (params) => fetch('/question/updateSettings', params, 'POST', 'fetch', 3);

/**
 * 问题单配置查询
 */
export const queryProblemOrderSetting = (params) => fetch('/question/selectSettings', params, 'GET', 'fetch', 3);

/**
 * 问题单状态修改
 */
export const alterProblemOrderStatus = (params) => fetch('/question/updateQuestion', params, 'GET', 'fetch', 3);

/**
 * 同步发货状态
 */
export const syncOrderShipStatus = (params) => fetch('/orderJw/synchronizeShipment', params, 'POST', 'fetch', 3);

/**
 * 查询消息分类列表
 */
export const queryMsgCateList = (params) => fetch('/msg/category', params, 'GET', 'fetch', 5);

/**
 * 添加消息分类
 */
export const addMsgCate = (params) => fetch('/msg/category', params, 'POST', 'fetch', 5);

/**
 * 编辑消息分类
 */
export const editMsgCate = (params) => fetch('/msg/category', { params }, 'PUT', 'fetch', 5);

/**
 * 删除消息分类
 */
export const deleteMsgCate = (params) => fetch('/msg/category/' + params.categoryId, {}, 'DELETE', 'fetch', 5);

/**
 * 查询一级或二级消息分类列表
 */
export const queryMsgCateList4Level = (params) => fetch('/msg/category/drop-down-box', params, 'GET', 'fetch', 5);

/**
 * 根据分类id查询分类信息
 */
export const queryMsgCateDetail = (params) => fetch('/msg/category/' + params.categoryId, {}, 'GET', 'fetch', 5);

/**
 * 消息列表查询
 */
export const queryMsgList = (params) => fetch('/msg/manage', params, 'GET', 'fetch', 5);

/**
 * 添加消息
 */
export const addMsg = (params) => fetch('/msg/manage', params, 'POST', 'fetch', 5);

/**
 * 编辑消息
 */
export const editMsg = (params) => fetch('/msg/manage', { params }, 'PUT', 'fetch', 5);

/**
 * 删除消息
 */
export const deleteMsg = (params) => fetch('/msg/manage/' + params.msgId, params, 'DELETE', 'fetch', 5);

/**
 * 根据消息ID查询消息信息
 */
export const queryMsgDetail = (params) => fetch('/msg/manage/' + params.msgId, params, 'GET', 'fetch', 5);

/**
 * 消息订阅分页列表
 */
export const subscribeMsgList = (params) => fetch('/msg/subscribe/page', params, 'GET', 'fetch', 5);

/**
 * 订阅/取消订阅消息
 */
export const updateSubscribe = (params) => fetch('/msg/subscribe/' + params.msgId + '?agentId=' + params.agentId, {}, 'POST', 'fetch', 5);

/**
 * 充值中心界面_顶部数据
 */
export const topupCenterData = (params) => fetch('/abroad/info', params, 'GET', 'fetch', 1);

/**
 * 充值中心界面_充值记录列表
 */
export const topupCenterRecordList = (params) => fetch('/abroad/records', params, 'GET', 'fetch', 1);

/**
 * 统一账户中国银行充值接口
 */
export const topupOnline = (params) => fetch('/abroad/unique/ipps', params, 'POST', 'fetch', 1);







/**
* 函数防抖
*/
export function debounce(fn, delay) {
  // 记录上一次的延时器
  var timer = null;
  var mtDelay = delay || 200;
  return function() {
    var args = arguments;
    var that = this;
    // 清除上一次延时器
    clearTimeout(timer)
    timer = setTimeout(function() {
      fn.apply(that, args)
    }, mtDelay);
  }
}