<template>
    <div class="login_Box">
        <!-- head -->
        <div class="head">
          <div class="loginImg">
            <img src='../../assets/img/btcslogo.png' v-if='logoOther' style="width:auto;"/>
            <img src="https://www.polo-e.net/dist/img/MCPLlogo.png" @click="turnMain"  v-else/>
          </div>
        </div>

        <!-- body -->
        <div class="body">
            <div class="loginBox">
                <img src="../../assets/img/login/loginPic.png">
                <div class="loginInput">
                    <div class="mpce-title">MPCE综合服务管理平台</div>
                    <div class="mpce-box"></div>
                    <div class="nameInput">
                        <div class="tag-icon"><img class="icon-img" src="../../assets/img/login/userName.png" /></div>
                        <input type="text" autocomplete="off" ref="nameInput" v-model="loginName" placeholder="请输入账号"/>
                    </div>
                    <div class="nameInput">
                        <div class="tag-icon"><img class="icon-img" src="../../assets/img/login/passWord.png" /></div>
                        <input :type="inputType" autocomplete="off" ref="pswInput" v-model="loginPsw" placeholder="请输入密码"/>
                        <img class="icon-end" src="../../assets/img/login/on.png" v-if="inputType=='text'" @click="switchIcon('off')"/>
                        <img class="icon-end" src="../../assets/img/login/off.png" v-if="inputType=='password'" @click="switchIcon('on')"/>
                    </div>
                    <!-- <el-select v-model="roleValue" placeholder="请选择登录角色" class="bl-roleChoose">
                        <el-option
                            v-for="item in options"
                            :key = "item.label"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <div class="loginBtn btsc09876" v-if='logoOther' @click="btnSave">登 录</div>
                    <div class="loginBtn" v-else @click="btnSave">登 录</div>
                    <!-- <div class="noUser">没有账号？<span class="textBtn" @click="turnRegister">立即注册</span></div> -->
                    <div class="forgetPsd">
                        <div class="btn-forget" @click="turnForget">忘记密码</div>
                        <div class="btn-split"></div>
                        <div class="btn-reg" @click="turnRegister">注册</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- foot -->
        <!-- <newBondedFoot ref='newbondedfoot' id="nifoot"></newBondedFoot> -->
            <el-dialog
            title="请选择登录角色"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <div class="selectRole" v-for="item in roleList" :key="item.adminRoleId" @click="selectLogin(item)">{{item.adminRoleName}}</div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="loginIn">确定登入</el-button>
            </span>
          </el-dialog>
        <!-- <div class="ftb-foot">
            <div class="ftb-footContent">CopyRight &#169 上海博逻科技信息有限公司 经营许可证编号：沪ICP备15055071号&emsp;&emsp;
                <router-link to="contactUs.html" class="ftb-btn">联系我们</router-link>&nbsp;&nbsp;|&nbsp;
                <router-link to="policiesToB.html" class="ftb-btn">法律声明</router-link>
            </div>
            <div class="ftb-gjkgBox">
                <img src="https://www.polo-e.net/dist/img/GSWJ.png">
                <div class="ftb-btn" style="float:left;cursor:pointer;" @click="gswjWatch">工商营业执照</div>
                <div class="ftb-line">|</div>
                <div class="ftb-footContent" style="float:left;">增值电信业务经营许可证: 沪B2-20180588</div>
                <div style="clear:both;"></div>
            </div>
        </div> -->
    </div>
</template>
<script>
    import { loginNew } from '~/service/getData';
    import { loginSass, supplierDetai, agentDetail, serviceDetail } from '~/service/report';
    import axios from 'axios';
    import { Local, PromptBox } from '~/assets/js/public';
    export default{
        data(){
            return{
                loginName: '',
                loginPsw: '',
                dialogVisible: false,
                logoOther: false,
                options: [
                    {
                        'label': '全球供货商 - 跨境电子商务企业',
                        'value': 'G-C'
                    }, {
                        'label': '境内代理商 - 销售渠道代理商',
                        'value': 'F-C'
                    },
                    {
                        'label': '综合服务商 - 跨境综合服务供应商',
                        'value': 'S-W,S-N'
                    },
                    {
                        'label': '管理员',
                        'value': 'admin'
                    }
                ],
                roleValue: '',
                warnMsg: '密码错误',
                warnShow: '',
                roleList: [],
                adminUserId: '', // 登陆人userId
                adminMerchantType: '', // 商户类型
                adminRoleCode: '', // 角色类型
                iconImg: '../../assets/img/login/on.png',
                inputType: 'password'
            }
        },
        created() {

        },
        components: {
            // newBondedFoot
        },
        mounted(){
          // 针对BTSC做的特别处理，包括更换logo，主体颜色等
          if(RegExp(/btsc09876/).test(window.location.hash)){
              this.logoOther = true;
              sessionStorage.setItem('logoOther', 'btsc09876');
          }else{
              this.logoOther = false;
              sessionStorage.clear();
          }
        },
        methods: {
            nameInputOnfocus() {
                this.$refs.nameInput.focus();// 点击获取焦点
            },
            pswInputOnfocus() {
                this.$refs.pswInput.focus();
            },
            turnMain(){
                if(!Local.get('userId')){
                    window.location.href = 'https://www.polo-e.net/'; // 跳转到站外
                }else{
                    let userId = Local.get('userId');
                    window.location.href = 'https://www.polo-e.net/userId' + userId; // 跳转到站外
                }
            },
            turnForget(){
                if(this.logoOther == false){
                    window.location.href = 'registy.html#/forgetPwd';
                }else{
                    window.location.href = 'registy.html#/forgetPwd?btsc09876';
                }
            },
            turnRegister(){
                if(this.logoOther == false){
                    window.location.href = 'registy.html';
                }else{
                    window.location.href = 'registy.html#/registryEntry?btsc09876';
                }
            },
            switchIcon(type){
                if(type == 'on'){
                    this.inputType = 'text';
                }else{
                    this.inputType = 'password';
                }
            },
            btnSave(){
                if(!this.loginName){
                    this.$message.error('账号不能为空！');
                    return false;
                }
                if(!this.loginPsw){
                    this.$message.error('密码不能为空！');
                    return false;
                }
                let formData = new FormData();
                formData.append('username', this.loginName); //
                formData.append('password', this.loginPsw); //
                let config = {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                };
                let that = this;
                let origin = window.location.origin;
                if(origin.indexOf('mpce') > 0 ) {
                    origin = 'https://mpce.polo-e.net';
                } else {
                    origin = 'https://test.polo-e.net';
                    // origin = 'https://mpce.polo-e.net';
                }
                axios.post(origin + '/mpcc-saas-gateway/login', formData).then(function (response) {
                    if (response.data.code == '200') {
                        Local.set('authorization', response.headers.authorization);
                        Local.set('adminUserId', response.data.data.adminUserId);
                        Local.set('adminUserNickname', response.data.data.adminUserNickname);
                        Local.set('adminUserUsername', response.data.data.adminUserUsername);
                        if(response.data.data.adminUserLastLoginDate > response.data.data.adminUserRegisterDate){
                            // 上一次登录时间》注册时间
                            Local.set('adminNewUser', false); // 老用户
                        } else {
                            Local.set('adminNewUser', true); // 新用户
                        }
                        that.$message.success('登录成功！');
                        that.roleList = response.data.data.adminUserRoles;
                        console.log(that.roleList[0].adminRoleCode)
                        if(that.roleList[0].adminRoleCode == 1 || 
                           that.roleList[0].adminRoleCode == 2 || that.roleList[0].adminRoleCode.indexOf('test')  > 0 ||  that.roleList[0].adminRoleCode.indexOf('"baoguan"')  > 0){
                            window.location.href = 'admin.html';
                            Local.set('slidebarList', JSON.stringify(that.roleList[0])); // 菜单
                        } else {
                            // 登录角色不等于SuperAdmin弹出选择框
                            that.adminUserId = response.data.data.adminUserId;
                            // that.dialogVisible = true;
                            that.selectLogin(that.roleList[0]);
                        }
                        that.setCookie(response.data.data.adminUserId, response.data.data.adminUserUsername, that.roleList[0].adminRoleCode);
                    }else{
                        that.$message.error(response.data.msg);
                    }
                });
            },
            setCookie(id, name, role) { // 保存cookie
                console.log(id, name);
                var days = 7;
                var exp = new Date();
                exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
                document.cookie = 'adminUserUsername=' + name + ';domain=polo-e.net' + ";expires=" + exp.toGMTString();
                document.cookie = 'adminUserId=' + id + ';domain=polo-e.net' + ";expires=" + exp.toGMTString();
                document.cookie = 'adminRoleCode=' + role + ';domain=polo-e.net' + ";expires=" + exp.toGMTString();
            },
            async selectLogin(role){
                this.adminRoleCode = role.adminRoleCode;
                if(role.adminRoleCode == '11' || role.adminRoleCode == '21'){
                    // 境内经销商和境外经销商
                    let res = await supplierDetai(this.adminUserId, '');
                    if(res.code == '200'){
                        this.adminMerchantType = res.data.adminMerchantType;
                        Local.set('adminMerchantId', res.data.adminMerchantId); // 商户Id
                        Local.set('adminUserUsername', res.data.adminUserUsername); // 用户名
                        Local.set('adminMerchantCompanyLogo', res.data.adminMerchantCompanyLogo); // 公司Logo
                        Local.set('adminMerchantSupplierShopId', res.data.adminMerchantSupplierShopId); // 代理店铺id
                        Local.set('adminMerchantType', res.data.adminMerchantType); // 商户类型：1境内，2境外 = ['MERCHANT_INTERNAL', 'MERCHANT_FOREIGH'];
                        Local.set('adminMerchantSupplierShopWarningStock', res.data.adminMerchantSupplierShopWarningStock); // 预警库存
                        Local.set('adminMerchantVerifyStatus', res.data.adminMerchantVerifyStatus); // 审核状态 'VERIFY_NONE', 'VERIFY_WAIT', 'VERIFY_SUCCESS', 'VERIFY_FAILURE'; 未认证,待审核,审核驳回,已认证
                        Local.set('adminMerchantSource', res.data.adminMerchantSource); // 来源 (有赞，帛士达等)
                    }else{
                        this.$message.error(res.msg);
                    }
                    Local.set('slidebarList', JSON.stringify(role)); // 菜单
                    window.location.href = 'demo2.html';
                }else if(role.adminRoleCode == '10' || role.adminRoleCode == '20'){
                    // 境内代理商和境外代理商
                    let res = await agentDetail(this.adminUserId, '');
                    if(res.code == '200'){
                        this.adminMerchantType = res.data.adminMerchantType;
                        Local.set('adminMerchantId', res.data.adminMerchantId); // 商户Id
                        Local.set('adminUserUsername', res.data.adminUserUsername); // 用户名
                        Local.set('adminMerchantCompanyLogo', res.data.adminMerchantCompanyLogo); // 公司Logo
                        Local.set('adminMerchantAgentShopId', res.data.adminMerchantAgentShopId); // 代理店铺id
                        Local.set('adminMerchantType', res.data.adminMerchantType); // 商户类型：1境内，2境外 = ['MERCHANT_INTERNAL', 'MERCHANT_FOREIGH'];
                        Local.set('adminMerchantAgentShopWarningStock', res.data.adminMerchantAgentShopWarningStock); // 预警库存
                        Local.set('adminMerchantAgentShopAutoOnline', res.data.adminMerchantAgentShopAutoOnline); // 是否自动上架商品
                        Local.set('adminMerchantVerifyStatus', res.data.adminMerchantVerifyStatus); // 审核状态 'VERIFY_NONE', 'VERIFY_WAIT', 'VERIFY_SUCCESS', 'VERIFY_FAILURE'
                        Local.set('adminMerchantSource', res.data.adminMerchantSource); // 来源 (有赞，帛士达等)
                    }else{
                        this.$message.error(res.msg);
                    }
                    Local.set('slidebarList', JSON.stringify(role)); // 菜单
                    window.location.href = 'distributor.html';
                }else if(role.adminRoleCode == 'InternalService' || role.adminRoleCode == 'ForeignService'){
                    // 境内服务商和境外服务商
                    let res = await serviceDetail(this.adminUserId, '');
                    if(res.code == '200'){
                        this.adminMerchantType = res.data.adminMerchantType;
                        Local.set('adminMerchantId', res.data.adminMerchantId); // 商户Id
                        Local.set('adminUserUsername', res.data.adminUserUsername); // 用户名
                        Local.set('adminMerchantCompanyLogo', res.data.adminMerchantCompanyLogo); // 公司Logo
                        Local.set('adminMerchantServiceShopId', res.data.adminMerchantServiceShopId); // 服务商店铺id
                        Local.set('adminMerchantType', '境内服务商商户'); // 商户类型：1境内，2境外 = ['MERCHANT_INTERNAL', 'MERCHANT_FOREIGH'];
                        Local.set('adminMerchantVerifyStatus', res.data.adminMerchantVerifyStatus); // 审核状态 'VERIFY_NONE', 'VERIFY_WAIT', 'VERIFY_SUCCESS', 'VERIFY_FAILURE'
                        Local.set('adminMerchantSource', res.data.adminMerchantSource); // 来源 (有赞，帛士达等)
                    }else{
                        this.$message.error(res.msg);
                    }
                    Local.set('slidebarList', JSON.stringify(role)); // 菜单
                    window.location.href = 'serviceProvider.html';
                }else if(role.adminRoleCode == 'Brand'){
                    // 品牌方
                    let res = await serviceDetail(this.adminUserId, '');
                    // if(res.code == '200'){
                    //     // this.adminMerchantType = res.data.adminMerchantType;
                    //     // Local.set('adminMerchantId', res.data.adminMerchantId); // 商户Id
                    //     // Local.set('adminUserUsername', res.data.adminUserUsername); // 用户名
                    //     // Local.set('adminMerchantCompanyLogo', res.data.adminMerchantCompanyLogo); // 公司Logo
                    // }else{
                    //     this.$message.error(res.msg);
                    // }
                    Local.set('slidebarList', JSON.stringify(role)); // 菜单
                    // window.location.href = 'admin.html#/commodityManagement/listedProducts';
                    window.location.href = 'brand.html';
                }else{
                    // (role.adminRoleCode == 'SuperAdmin' || role.adminRoleCode == 'Admin' || role.adminRoleCode == 'test' || role.adminRoleCode == 'Financialcontroller')
                    window.location.href = 'admin.html';
                    sessionStorage.setItem("slidebarList", JSON.stringify(this.roleList[0]));
                    Local.set("slidebarList", JSON.stringify(this.roleList[0]));
                }
            },
            loginIn(){
                if(!this.adminRoleCode){
                    this.$message.error('请选择登录角色！');
                    return false;
                }
                if(this.adminRoleCode == '1' || this.adminRoleCode == '2'){
                    window.location.href = 'admin.html';
                }else if(this.adminRoleCode == 'InternalSupplier' || this.adminRoleCode == '11'){
                    // 境内经销商和境外经销商
                    window.location.href = 'demo2.html';
                }else if(this.adminRoleCode == '10' || this.adminRoleCode == 'ForeignAgent'){
                    // 境内代理商和境外代理商
                    window.location.href = 'distributor.html';
                }else if(this.adminRoleCode == '20' || this.adminRoleCode == '21'){
                    // 境内服务商和境外服务商
                    window.location.href = 'serviceProvider.html';
                }
            },
        }
    }
</script>
<style lang="scss">
    .login_Box {
        background-color:#FFF;
        .head {
            background: #FFF;
            width:100%;
            box-shadow:0 2px 2px 0 rgba(0,0,0,0.06);
            .loginImg {
                width: 1100px;
                margin:0 auto;
                height: 70px;
                img {
                    width:80px;
                    height:45px;
                    margin-top:13px;
                    cursor:pointer;
                }
            }
        }
        .body {
            background-image:url('../../assets/img/login/newBackground.png');
            // background-repeat:no-repeat;
            // width: 1800px;
            height: 610px;
            // margin:0px auto;
            padding:110px 0px 0px 0px;
            .loginBox {
                height: 500px;
                max-width: 1050px;
                margin:0px auto;
                background-color: #FFF;
                box-shadow:0 2px 4px 0 rgba(0,0,0,0.27);
                padding:0px 75px;
                img {
                    // height: 413px;
                    // width: 640px;
                    float: left;
                    margin:43px 0px;
                }
                .loginInput {
                    width: 350px;
                    height: 380px;
                    float: right;
                    padding-top: 80px;
                    .mpce-title{
                        height:33px;
                        font-size:24px;
                        font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:33px;
                        text-align: center;
                    }
                    .mpce-box{
                        width:40px;
                        height:4px;
                        background:rgba(255,94,16,1);
                        margin: 10px auto 50px;
                    }
                    .nameInput {
                        height:40px;
                        // width: 100%;
                        width: 350px;
                        margin-bottom: 24px;
                        position: relative;
                        .tag-icon{
                            width: 14px;
                            height: 16px;
                            background:rgba(248,248,248,1);
                            border-radius:2px 0px 0px 2px;
                            border:1px solid rgba(229,229,229,1);
                            float: left;
                            padding: 11px 12px;
                            .icon-img{
                                width:16px;
                                height:16px;
                                margin: 0;
                            }
                        }
                        input {
                            // width: 289px;
                            width: 82%;
                            height: 38px;
                            line-height: 38px;
                            border:1px solid #e5e5e5;
                            border-left: none;
                            border-radius:2px;
                            padding:0px 10px;
                            font-size:14px;
                            color:#333;
                            display: block;
                        }
                        .icon-end{
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            margin: 0;
                            top: 12px;
                            right: 10px;
                        }
                        .controlLabel {
                            font-size:14px;
                            color:#666;
                            position: absolute;
                            z-index: 0;
                            -webkit-transition: all 0.28s ease;
                            transition: all 0.28s ease;
                            margin:-30px 0px 0px 10px;
                        }
                    }
                    .bl-roleChoose {
                        width: 100%;
                        margin-top: 30px;
                        .el-select-dropdown__item {
                            padding:5px 11px;
                            height: 40px;
                            font-size:14px;
                            color:#333;
                        }
                        .el-input__inner {
                            height:40px;
                            line-height: 40px;
                            border-radius: 2px;
                            border:1px solid #e5e5e5;
                            font-size:14px;
                            color:#333;
                            padding:0px 10px;
                            &:focus {
                                border-color: #e5e5e5;
                            }
                        }
                        .el-input {
                            .is-focus {
                                .el-input__inner {
                                    border-color: #e5e5e5;
                                    font-size:14px;
                                    color:#333;
                                }
                            }
                        }
                        input{
                            &:-webkit-input-placeholder {
                                color:#666;
                            }
                            &::-webkit-input-placeholder {
                                color:#666;
                            }
                            &:-ms-input-placeholder {
                                color:#666;
                            }
                            &:-moz-placeholder {
                                color:#666;
                            }
                            &::-moz-placeholder {
                                color:#666;
                            }
                        }
                    }
                    .loginBtn {
                        background-color: #7ad2cf;
                        box-shadow: 0 2px 5px 0 rgba(99,221,217,.5);
                        border-radius:2px;
                        width: 100%;
                        font-size:18px;
                        color:#FFF;
                        text-align: center;
                        letter-spacing:1.25px;
                        margin:40px 0px 10px 0px;
                        line-height: 50px;
                        cursor: pointer;
                    }
                    .noLoginMsg {
                        background:#ddd;
                        border-radius:2px;
                        width: 100%;
                        font-size:20px;
                        color:#999;
                        text-align: center;
                        letter-spacing:1.25px;
                        margin:40px 0px 10px 0px;
                        line-height: 50px;
                        cursor:default;
                    }
                    .noUser{
                        height:20px;
                        font-size:14px;
                        color:rgba(153,153,153,1);
                        line-height:20px;
                        text-align: center;
                        margin-top: 28px;
                        .textBtn {
                            font-size:14px;
                            cursor: pointer;
                            color: #7AD2CF;
                        }
                    }
                    .forgetPsd{
                        float: right;
                        text-align: right;
                        height:20px;
                        font-size:14px;
                        color:rgba(153,153,153,1);
                        line-height:20px;
                        .btn-forget{
                            width:70px;
                            margin-right: 12px;
                            float: left;
                            cursor: pointer;
                        }
                        .btn-split{
                            float: left;
                            width:1px;
                            height:14px;
                            margin-right: 12px;
                            background:rgba(229,229,229,1);
                        }
                        .btn-reg{
                            float: right;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .btsc09876{
                     background-image: linear-gradient(90deg, #475988 0%, #70c6d5 100%) !important;
                     color: #fff;
        }
        .selectRole{
            width:100px;
            height:30px;
            line-height:30px;
            margin-left:20px;
            float:left;
            text-align: center;
            cursor: pointer;
            background-color:#409EFF;
            font-size:14px;
            color: #FFF;
        }
    }
</style>
