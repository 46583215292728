import Vue from 'vue';
import { $v, PromptBox } from '~/assets/js/public';
import axios from 'axios';
import fetch from '~/assets/config/fetch';

let apikey = '';


/**
登陆  F-P,F-C,G-C
*/
export const loginNew = (username, password, type) => fetch('/loginSer/login', {
    username,
    password,
    type
}, 'POST');

/**
*获取个人资料信息
*/
export const selectUserInfo = ( userId ) => fetch('/registerUserSer/selectUserInfo', { userId }, 'POST');

/**
*获取公司资料信息
*/
export const getCustomer = ( userId ) => fetch('/registerCompanySer/getCustomer', { userId }, 'POST');

/**
*新增或者修改资料信息  submitType add,updage
*/
export const updCustomer = ( mobile, userId, submitType ) => fetch('/registerUserSer/regUserInfo', { mobile, userId, submitType }, 'POST');

/**
*修改公司资料信息  submitType updage
*/
export const updcompany = ( registeredName, country, subject, linkName, businessLicenseImage, address, website, logo, customer, userId, submitType, authentication, ip, macAddress, stackUrl ) => fetch('/registerCompanySer/saveGCCustomer',
    { registeredName, country, subject, linkName, businessLicenseImage, address, website, logo, customer, userId, submitType, authentication, ip, macAddress, stackUrl }, 'POST');

/**
*主营类目
*/
export const getShopCategory = () => fetch('/companySer/getShopCategory', {}, 'POST');

/**
*国家下拉框
*/
export const selectbusinessType1 = () => fetch('/companySer/selectbusinessType', {}, 'POST');

/**
全球服务商
*/
export const serviceAllInfoList = ( pageNumber, pageSize, serviceType ) => fetch('/serviceInfoSer/serviceInfoList', { pageNumber, pageSize, serviceType }, 'POST');

/**
服务商类型
*/
export const getDictCode = ( fieldname ) => fetch('/companySer/getDictCode', { fieldname }, 'POST');

/**
咨询服务商
*/
export const saveServiceInfo = ( shopId, userId, name, mobile, email, company, remark ) => fetch('/serviceInfoSer/saveServiceInfo', { shopId, userId, name, mobile, email, company, remark }, 'POST');

/**
获取全部分类
*/
export const getCategoryCodeList = () => fetch('/goodsManageSer/getCategoryCodeList', {}, 'POST');

/**
*备案下拉框
*/
export const selectbusinessType = () => fetch('/filingSer/selectbusinessType');

/**
*单位分类线路
*/
export const getInitInfo = (userId) => fetch('/goodsManageSer/getInitInfo', { userId }, 'POST');

/**
*创建商品列表
*/
export const selectProduct = ( userId, type, state, servertype, pageNumber, pageSize, cargoCode, cargoNameCH, addTimeBegin, addTimeEnd, categoryCode3, reaord, pushState ) => fetch('/filingItemSer/selectProduct', { userId, type, state, servertype, pageNumber, pageSize, cargoCode, cargoNameCH, addTimeBegin, addTimeEnd, categoryCode3, reaord, pushState }, 'POST');

/**
*创建商品详情
*/
export const selectProductDetail = (id) => fetch('/filingItemSer/selectProductDetail', { id }, 'POST');

/**
*创建商品删除
*/
export const deleteProduct = (ids) => fetch('/filingItemSer/deleteProduct', { ids }, 'POST');

/**
*发起备案
*/
export const sendProduct = (userId, ids) => fetch('/filingItemSer/sendProduct', { userId, ids }, 'POST');



/**
*获取预计运费 weight毛重或者净重
*/
export const getFreight = (shopId, lineId, weight) => fetch('/goodsManageSer/getFreight', { shopId, lineId, weight }, 'POST');

/**
*获取库内操作费
*/
export const selectShopPackPriceById = (shopId, lineId) => fetch('/ShopSendCtrl/selectShopPackPriceById', { shopId, lineId }, 'POST');

/**
*获取清关费
*/
export const getCustomPrice = (shopId, lineId) => fetch('/goodsManageSer/getCustomPrice', { shopId, lineId }, 'POST');

/**
*全部运营商
*/
export const getOperatorListInfo = ( pageSize, pageNumber, cateCode, searchVal, subject, fxqd, country ) => fetch('/customerManage/search', {
    pageSize,
    pageNumber,
    cateCode,
    searchVal,
    subject,
    fxqd,
    country
}, 'GET');

/**
*全部运营商New
*/
export const newGetOperatorListInfo = ( pageSize, pageNumber, cateCode, searchVal, subject, fxqd, country ) => fetch('/customerManage/findFx', {
    pageSize,
    pageNumber,
    cateCode,
    searchVal,
    subject,
    fxqd,
    country
}, 'GET');

/**
*运营商品类/类型
*/
export const findByField = (fieldName) => fetch('/CodeDictionariesManage/findByField', { fieldName }, 'GET');

/**
*运营商详情
*/
export const getOperatorByUserId = (userId) => fetch('/customerManage/findByUserId', { userId }, 'GET');

/**
*运营商详情
*/
export const getOperatorDetailsInfo = (id) => fetch('/customerManage/find', { id }, 'GET');


// 订单管理
/**
*订单信息列表searchKey(orderNumber,shopName,fxUserId,goodsName,goodsId)
*/
export const getOrderList = ( pageSize, pageNumber, userId, orderNumber, lastStatus, shopName, orderCommitTimeBegin, orderCommitTimeEnd, serverType, searchKey, searchValue, fxUserId) => fetch('/SupOrderMangerCtrl/getOrderList', {
    pageSize,
    pageNumber,
    userId,
    orderNumber,
    lastStatus,
    shopName,
    orderCommitTimeBegin,
    orderCommitTimeEnd,
    serverType,
    searchKey,
    searchValue,
    fxUserId
}, 'POST');

/**
*面单打印列表searchKey(orderNumber,shopName,fxUserId,goodsName,goodsId)
*/
export const getWaitOrderList = ( pageSize, pageNumber, userId, orderNumber, lastStatus, shopName, orderCommitTimeBegin, orderCommitTimeEnd, serverType, searchKey, searchValue, customsName, waitPrint ) => fetch('/SupOrderMangerCtrl/getWaitOrderList', {
    pageSize,
    pageNumber,
    userId,
    orderNumber,
    lastStatus,
    shopName,
    orderCommitTimeBegin,
    orderCommitTimeEnd,
    serverType,
    searchKey,
    searchValue,
    customsName,
    waitPrint
}, 'POST');

/**
*获取Fedex运单号
*/
export const getFedexTrackNumber = ( orderNumbers, userId ) => fetch('/PopFedexWebService/getMasterTrackNumber', {
    orderNumbers,
    userId
}, 'POST');

/**
*订单详情
*/
export const getOrderDetailsInfo = ( orderNumber, userId ) => fetch('/SupOrderMangerCtrl/getOrderDetails', {
    orderNumber,
    userId
}, 'POST');

/**
*业务列表
*/
export const getBusinessInfo = (userId) => fetch('/companySer/getBusinessType', { userId }, 'POST');

/**
*全球商品库列表
*/
export const getGoodsList = (userId, pageNumber, pageSize, putOnSale, putawayStatus, cargoName, stockFlag, businessType, categoryCode3, orderType) => fetch('/SupSendGoodmanageCtrl/getGoodsList', { userId, pageNumber, pageSize, putOnSale, putawayStatus, cargoName, stockFlag, businessType, categoryCode3, orderType }, 'POST');

/**
*修改库存
*/
export const editStock = (EData) => fetch('/SupSendGoodmanageCtrl/editStock', { EData }, 'POST');

/**
*设置警示库存
*/
export const cautionStock = (userId, cautionStock) => fetch('/SupSendGoodmanageCtrl/cautionStock', { userId, cautionStock }, 'POST');

/**
*全球商品库信息
*/
export const getGoods = (goodsCode) => fetch('/SupSendGoodmanageCtrl/getGoods', { goodsCode }, 'POST');


// 财务管理
/**
*账户信息
*/
export const getAccountInfo = (userId) => fetch('/accountManageSer/getAccountInfo', { userId }, 'POST');

/**
*是否开通银联账户
*/
export const getOpenUnion = (userId) => fetch('/accountManageSer/ifOpenUnion', { userId }, 'POST');

/**
*充值
*/
export const addDepositRecharge = (userId, recharge, certificate) => fetch('/accountManageSer/addDepositRecharge', { userId, recharge, certificate }, 'POST');

/**
*提现明细
*/
export const withdrawDepositDetails = (userId, orderNumbers, pageSize, pageNumber) => fetch('/SupFundInfoCtrl/orderDetails', { userId, orderNumbers, pageSize, pageNumber }, 'POST');

/**
*可提现数量
*/
export const withdrawDepositNum = (userId) => fetch('/SupFundInfoCtrl/orderNumbers', { userId }, 'POST');

/**
*提现订单详情
*/
export const supWithdrawals = (userId, orderNumbers) => fetch('/SupFundInfoCtrl/supWithdrawals', { userId, orderNumbers }, 'POST');

/**
*提现订单详情
*/
export const operatorWithdrawals = (userId, orderNumbers) => fetch('/FundInfoCtrl/Withdrawals', { userId, orderNumbers }, 'POST');

/**
*保证金明细
*/
export const getDepositLogList = ( userId, pageSize, pageNumber, logType, addDateBegin, addDateEnd) => fetch('/accountManageSer/depositLogList', {
    userId,
    pageSize,
    pageNumber,
    logType,
    addDateBegin,
    addDateEnd
}, 'POST');

/**
*保证金详情
*/
export const getDepositLogDetail = (id) => fetch('/accountManageSer/depositLogDetail', {
    id
}, 'POST');

/**
*提现流水列表
*/
export const getUserReceive = (userId, pageSize, pageNumber, status, applyDateBegin, applyDateEnd) => fetch('/SupFundInfoCtrl/getUserReceive', {
    userId,
    pageSize,
    pageNumber,
    status,
    applyDateBegin,
    applyDateEnd
}, 'POST');

/**
*运营商提现流水列表
*/
export const operatorGetUserReceive = (userId, pageSize, pageNumber, status, applyDateBegin, applyDateEnd, id) => fetch('/SupFundInfoCtrl/getUserReceive', {
    userId,
    pageSize,
    pageNumber,
    status,
    applyDateBegin,
    applyDateEnd,
    id
}, 'POST');


/**
*提现流水列表
*/
export const getUserReceiveLog = (id, pageSize, pageNumber) => fetch('/SupFundInfoCtrl/getUserReceiveLog', {
    id,
    pageSize,
    pageNumber
}, 'POST');

/**
*订单明细
*/
export const accountOrderDetails = (userId, pageSize, pageNumber, payDateBegin, payDateEnd, orderNumber, serverType) => fetch('/SupFundInfoCtrl/getOrderDetails', {
    userId,
    pageSize,
    pageNumber,
    payDateBegin,
    payDateEnd,
    orderNumber,
    serverType
}, 'POST');

/**
*供货商银联账户注册
*/
export const saveUnionInfo = ( userId, currency, beneficiaryBankName, bankSWIFT, bankAddress, beneficiaryAccountName, beneficiaryAccountAddress, accountNumber, specialRemittanceInformation, countryOfBeneficiary, certificate) => fetch('/accountManageSer/saveUnionInfo', {
    userId,
    currency,
    beneficiaryBankName,
    bankSWIFT,
    bankAddress,
    beneficiaryAccountName,
    beneficiaryAccountAddress,
    accountNumber,
    specialRemittanceInformation,
    countryOfBeneficiary,
    certificate
}, 'POST');

/**
*运营商银联账户注册
*/
export const operatorSaveUnionInfo = ( userId, registerName, registerPlace, registerNo, registerDate, tel, enterType, corporateName, IDType, IDNumber, IDDate, environment, mainBusiness, businessLinkName, businessMobile, businessEmail, businessTel, financialLinkName, financialMobile, financialEmail, financialTel, certificate, bankAccountName, bankAccount, bankName, bankProvince, bankCity ) => fetch('/accountManageSer/saveUnionInfo', {
    userId,
    registerName,
    registerPlace,
    registerNo,
    registerDate,
    tel,
    enterType,
    corporateName,
    IDType,
    IDNumber,
    IDDate,
    environment,
    mainBusiness,
    businessLinkName,
    businessMobile,
    businessEmail,
    businessTel,
    financialLinkName,
    financialMobile,
    financialEmail,
    financialTel,
    certificate,
    bankAccountName,
    bankAccount,
    bankName,
    bankProvince,
    bankCity
}, 'POST');

/**
*银联账户修改
*/
export const editUnionInfo = ( userId, currency, beneficiaryBankName, bankSWIFT, bankAddress, beneficiaryAccountName, beneficiaryAccountAddress, accountNumber, specialRemittanceInformation, countryOfBeneficiary, certificate) => fetch('/accountManageSer/editUnionInfo', {
    userId,
    currency,
    beneficiaryBankName,
    bankSWIFT,
    bankAddress,
    beneficiaryAccountName,
    beneficiaryAccountAddress,
    accountNumber,
    specialRemittanceInformation,
    countryOfBeneficiary,
    certificate
}, 'POST');

/**
*运营商银联账户修改
*/
export const operatorEditUnionInfo = ( userId, registerName, registerPlace, registerNo, registerDate, tel, enterType, corporateName, IDType, IDNumber, IDDate, environment, mainBusiness, businessLinkName, businessMobile, businessEmail, businessTel, financialLinkName, financialMobile, financialEmail, financialTel, certificate, bankAccountName, bankAccount, bankName, bankProvince, bankCity ) => fetch('/accountManageSer/editUnionInfo', {
    userId,
    registerName,
    registerPlace,
    registerNo,
    registerDate,
    tel,
    enterType,
    corporateName,
    IDType,
    IDNumber,
    IDDate,
    environment,
    mainBusiness,
    businessLinkName,
    businessMobile,
    businessEmail,
    businessTel,
    financialLinkName,
    financialMobile,
    financialEmail,
    financialTel,
    certificate,
    bankAccountName,
    bankAccount,
    bankName,
    bankProvince,
    bankCity
}, 'POST');

/**
*银联账户注册被驳回
*/
export const getUnionStateMsg = (userId) => fetch('/accountManageSer/getUnionInfo', {
    userId
}, 'POST');

/**
*公司信息
*/
export const getCustomerMsg = (userId) => fetch('/registerCompanySer/getCustomer', {
    userId
}, 'POST');
/**
*供货商确认生成合同
*/
export const partyaSubmit = (partyaUserId, partybUserId, contractId, reqDate, partyType) => fetch('/contractSignManage/signRequest', { partyaUserId, partybUserId, contractId, reqDate, partyType }, 'POST');
/**
*供销合同列表
*/
export const contractSearch = (pageNumber, pageSize, partyaUserId, partybUserId, signStatus, verifyStatus, partyaSearchVal, partybSearchVal, partyaFxqd, partybFxqd) => fetch('/contractSignManage/search', {
    pageNumber, pageSize, partyaUserId, partybUserId, signStatus, verifyStatus, partyaSearchVal, partybSearchVal, partyaFxqd, partybFxqd
}, 'POST');

/**
*分销商商品列表sku flag Y分页  N不分页
*/
export const fxGoodsList = (userId, supplierId, pageNumber, pageSize, putOnSale, registeredName, cargoName, businessType, categoryCode3, flag) => fetch('/FxShopCtrl/goodsList', { userId, supplierId, pageNumber, pageSize, putOnSale, registeredName, cargoName, businessType, categoryCode3, flag }, 'POST');

/**
 *分销商商品列表spu
 */
export const goodsListSpu = (userId, supplierId, pageNumber, pageSize, putOnSale, registeredName, cargoName, businessType, categoryCode3, putawayStatus, orderType) => fetch('/FxShopCtrl/goodsListSpu', { userId, supplierId, pageNumber, pageSize, putOnSale, registeredName, cargoName, businessType, categoryCode3, putawayStatus, orderType }, 'POST');


/**
*根据 id 查询合同签署信息
*/
export const contractFindById = (userId, pageNumber, pageSize) => fetch('/contractSignManage/find', { userId, pageNumber, pageSize }, 'POST');

/**
*全球商品库修改供货价
*/
export const savePriceDifference = (EData) => fetch('/priceDifferenceCtrl/savePriceDifference', { EData }, 'POST');

/**
*修改密码
*/
export const editPwd = (email, pwd) => fetch('/registerUserSer/editPwd', { email, pwd }, 'POST');

/**
*发送邮箱验证码
*/
export const sendEmailPwd = (email, name) => fetch('/registerUserSer/sendEmailPwd', { email, name }, 'POST');

/**
*拒绝合同
*/
export const signRefuse = (contractSignId) => fetch('/contractSignManage/signRefuse', { contractSignId }, 'POST');

/**
*根据id查询合同信息
*/
export const findContract = (id) => fetch('/contractSignManage/find', { id }, 'GET');

/**
*回执合同信息
*/
export const signResponse = (partyType, contractSignId) => fetch('/contractSignManage/signResponse', { partyType, contractSignId }, 'POST');


/**
*店铺Banner查询
*/
export const shopBannerManageSearch = (pageSize, pageNumber, userId, name, validStartDate, validEndDate) => fetch('/shopBannerManage/search', { pageSize, pageNumber, userId, name, validStartDate, validEndDate }, 'POST');

/**
*店铺Banner活动开启关闭
*/
export const activeEnable = (id, userId) => fetch('/shopBannerManage/enable', { id, userId }, 'POST');

/**
*店铺Banner活动新增/修改
*/
export const activeSave = (id, name, type, pcBanner, mobileBanner, redirectGoodsId, specialOfferId, importance, validStartDate, validEndDate, userId) => fetch('/shopBannerManage/save', {
    id,
    name,
    type,
    pcBanner,
    mobileBanner,
    redirectGoodsId,
    specialOfferId,
    importance,
    validStartDate,
    validEndDate,
    userId
}, 'POST');

/**
专题预览
*/
export const specialPreview = (id) => fetch('/specialOfferManage/preview', {
    id
}, 'GET');

/**
专题活动详情
*/
export const getSpecialOfferGoods = (specialOfferId) => fetch('/specialOfferManage/getSpecialOfferGoods', {
    specialOfferId
}, 'GET');

/**
专题活动保存
*/
export const specialActiveSave = (id, name, imageUrl, pcImageUrl, importance, validStartDate, validEndDate, userId, goodsIds) => fetch('/specialOfferManage/save', {
    id,
    name,
    imageUrl,
    pcImageUrl,
    importance,
    validStartDate,
    validEndDate,
    userId,
    goodsIds
}, 'POST');


/**
*店铺Banner活动详情查询
*/
export const activeFind = (id) => fetch('/shopBannerManage/find', { id }, 'GET');

/**
*店铺Banner活动详情查询
*/
export const goodsSearch = (userId, pageNumber, pageSize, cargoName) => fetch('/GoodsManage/search', { userId, pageNumber, pageSize, cargoName }, 'POST');

/**
*店铺专题活动查询
*/
export const offerManageSearch = (pageSize, pageNumber, userId, name, validStartDate, validEndDate) => fetch('/specialOfferManage/search', { pageSize, pageNumber, userId, name, validStartDate, validEndDate }, 'POST');

/**
*店铺专题活动开启关闭
*/
export const offerActiveEnable = (id, userId) => fetch('/specialOfferManage/enable', { id, userId }, 'POST');

/**
*运营商商品查询
*/
export const GoodsManageSearch = (pageSize, pageNumber, addUser, cargoName) => fetch('/GoodsManage/search', { pageSize, pageNumber, addUser, cargoName }, 'GET');

/**
*运营商商品详情
*/
export const getFxGoods = (id) => fetch('/FxShopCtrl/getFxGoods', { id }, 'POST');

/**
*根据条件查询合同签订数量
*/
export const searchCount = (partyaUserId, partybUserId, signStatus) => fetch('/contractSignManage/searchCount', { partyaUserId, partybUserId, signStatus }, 'GET');

/**
*控制台获取商品信息数量
*/
export const statisticaQuantity = (userId) => fetch('/filingItemSer/statisticaQuantity', { userId }, 'POST');

/**
*供货商控制台低于库存阈值的商品
*/
export const cautionStockCount = (userId) => fetch('/filingItemSer/cautionStock', { userId }, 'POST');

/**
*运营商控制台低于库存阈值的商品
*/
export const cautioncFStock = (userId) => fetch('/filingItemSer/cautioncFStock', { userId }, 'POST');

/**
 *供货商订单数
*/
export const success = (userId) => fetch('/companySer/supSuccess', { userId }, 'POST');

/**
 *运营商订单数
*/
export const fxSuccess = (userId) => fetch('/companySer/fxSuccess', { userId }, 'POST');

/**
 *全球供货商商品列表
*/
export const supfindGoods = (pageNumber, pageSize, businessType, categoryCode1, categoryCode2, categoryCode3, cargoName) => fetch('/goodsCategory/recursive', { pageNumber, pageSize, businessType, categoryCode1, categoryCode2, categoryCode3, cargoName }, 'GET');/**

/
*运营商商品下架
*/
export const putOnSaleGood = (id, putOnSale) => fetch('/FxShopCtrl/putOnSale', { id, putOnSale }, 'POST');

/**
*获取支付宝二维码
*/
export const getScanUrl = (userId, totalMoney, payType) => fetch('/aliPayImpl/pay', { userId, totalMoney, payType }, 'POST');

/**
*运营商订单列表
*/
export const operatorGetOrderList = (pageSize, pageNumber, userId, orderNumber, lastStatus, payDateBegin, payDateEnd, serverType, searchKey, searchValue, payMethod) => fetch('/OrderMangerCtrl/getOrderList', {
    pageSize,
    pageNumber,
    userId,
    orderNumber,
    lastStatus,
    payDateBegin,
    payDateEnd,
    serverType,
    searchKey,
    searchValue,
    payMethod
}, 'POST');

/**
*运营商订单详情
*/
export const operatorGetOrderDetails = (orderNumber, userId) => fetch('/OrderMangerCtrl/getOrderDetails', { orderNumber, userId }, 'POST');

/*
*运营商商品开启自动上架功能
*/
export const autoPutOnSale = (userId, autoPutOnSale) => fetch('/SupSendGoodmanageCtrl/autoPutOnSale', { userId, autoPutOnSale }, 'POST');

/*
*供货询价列表
*/
export const selectPriceDifference = (customerId, operatorId, pageNumber, pageSize, addTimeBegin, addTimeEnd, readed, cargoNameCh) => fetch('/priceDifferenceCtrl/selectPriceDifference', { customerId, operatorId, pageNumber, pageSize, addTimeBegin, addTimeEnd, readed, cargoNameCh }, 'POST');

/*
*运营商提现订单
*/
export const operatorOrderNumbers = (userId) => fetch('/FundInfoCtrl/orderNumbers', { userId }, 'POST');

/*
*运营商提现列表
*/
export const operatorOrderDetails = (userId, orderNumbers, pageSize, pageNumber) => fetch('/FundInfoCtrl/orderDetails', { userId, orderNumbers, pageSize, pageNumber }, 'POST');

/**
 *运营商商品列表
 */
export const operatorGoodsListSpu = (userId, supplierId, pageNumber, pageSize, cargoName, businessType, categoryCode3, categoryComCode1) => fetch('/FxShopCtrl/goodsListSpu', {
    userId,
    supplierId,
    pageNumber,
    pageSize,
    cargoName,
    businessType,
    categoryCode3,
    categoryComCode1
}, 'POST');

/*
*运营商商品新增分类
*/
export const saveCategory = (categoryName, categoryLevel, parentLevelId, isShowIndex, userId) => fetch('/goodsCateforyCtrl/saveCategory', { categoryName, categoryLevel, parentLevelId, isShowIndex, userId }, 'POST');

/*
*运营商商品分类列表
*/
export const getShopCategoryCodeList = (userId) => fetch('/goodsCateforyCtrl/getCategoryCodeList', { userId }, 'POST');

/*
*运营商商品分类列表删除
*/
export const deleteCategory = (categoryCode) => fetch('/goodsCateforyCtrl/deleteCategory', { categoryCode }, 'POST');

/*
*运营商商品分类列表修改
*/
export const updateCategory = (categoryCode, categoryName, categoryLevel, parentLevelId, isShowIndex) => fetch('/goodsCateforyCtrl/updateCategory', { categoryCode, categoryName, categoryLevel, parentLevelId, isShowIndex }, 'POST');

/*
*运营商是否自动上架
*/
export const findOnSaleStatus = (userId) => fetch('/SupSendGoodmanageCtrl/findOnSaleStatus', { userId }, 'POST');
/*
*运营商商品上下架数量
*/
export const findGoodsNum = (operatorId) => fetch('/priceDifferenceCtrl/findGoodsNum', { operatorId }, 'POST');

/*
*运营商获取供货商修改供货价商品数量
*/
export const findPriceDifference = (operatorId) => fetch('/priceDifferenceCtrl/findPriceDifference', { operatorId }, 'POST');

/*
*运营商已读
*/
export const updatePriceDifference = (operatorId) => fetch('/priceDifferenceCtrl/updatePriceDifference', { operatorId }, 'POST');
/*
*热销保存
*/
export const hotSaleSave = (userId, goodsIds) => fetch('/hotSaleManage/save', { userId, goodsIds }, 'POST');

/*
*热销列表
*/
export const hotSaleSearch = (userId, cargoName, businessType, categoryCode1, categoryCode2, categoryCode3, categoryComCode1, categoryComCode2, categoryComCode3, pageSize, pageNumber) => fetch('/hotSaleManage/search', {
    userId,
    cargoName,
    businessType,
    categoryCode1,
    categoryCode2,
    categoryCode3,
    categoryComCode1,
    categoryComCode2,
    categoryComCode3,
    pageSize,
    pageNumber
}, 'POST');

/*
*热销列表
*/
export const hotSaleDelete = (userId, hotSaleIds) => fetch('/hotSaleManage/delete', { userId, hotSaleIds }, 'POST');

/*
*运营商账户信息
*/
export const getOperatorCustomer = (userId) => fetch('/registerCompanySer/getCustomer', { userId }, 'POST');

/*
*运营商保存公司信息
*/
export const operatorSaveFCCustomer = (identityPositiveImage, identityBackImage, registeredName, fxqd, subject, linkName, businessLicenseId, businessLicenseImage, address, website, logo, customer, successcase, userId, submitType, authentication, ip, macAddress, stackUrl) => fetch('/registerCompanySer/saveFCCustomer', {
    identityPositiveImage,
    identityBackImage,
    registeredName,
    fxqd,
    subject,
    linkName,
    businessLicenseId,
    businessLicenseImage,
    address,
    website,
    logo,
    customer,
    successcase,
    userId,
    submitType,
    authentication,
    ip,
    macAddress,
    stackUrl
}, 'POST');

/*
*根据合同类型查询最新版本的合同信息
*/
export const getNewest = (type) => fetch('/contractManage/getNewest', { type }, 'GET');

/*
*运营商用户管理
*/
export const getFxUsers = (pageNumber, pageSize, userId, searchVal, orderVal) => fetch('/userManage/getFxUsers', { pageNumber, pageSize, userId, searchVal, orderVal }, 'POST');

/*
*运营商用户管理详情列表
*/
export const orderDetailSearch = (pageNumber, pageSize, userId, cUserId, lastStatus, serverType, payMethod, orderCommitTimeBegin, orderCommitTimeEnd, searchKey, searchVal) => fetch('/orderManage/search', {
    pageNumber,
    pageSize,
    userId,
    cUserId,
    lastStatus,
    serverType,
    payMethod,
    orderCommitTimeBegin,
    orderCommitTimeEnd,
    searchKey,
    searchVal
}, 'POST');

/*
*供货商查询已合作的运营商信息
*/
export const queryFxUsers = (pageNumber, pageSize, userId, fxqd, searchVal) => fetch('/contractSignManage/queryFxUsers', { pageNumber, pageSize, userId, fxqd, searchVal }, 'POST');

/*
*供货商查询已合作的所有运营商信息
*/
export const getALLFxUsers = (userId) => fetch('/contractSignManage/queryFxUsers', { userId }, 'POST');

/*
*运营商查询已合作的供货商信息
*/
export const querySupUsers = (pageNumber, pageSize, userId, country, searchVal) => fetch('/contractSignManage/querySupUsers', { pageNumber, pageSize, userId, country, searchVal }, 'POST');

/*
*供货商运营商修改入驻协议
*/
export const editStackUrl = (userId, stackUrl) => fetch('/registerCompanySer/editStackUrl', { userId, stackUrl }, 'POST');

/*
*运营商支付方式
*/
export const payMethodSearch = () => fetch('/payMethodManage/search', {}, 'GET');

/*
*询价列表
*/
export const serviceInfoList = (pageSize, pageNumber, status, userId, registeredName) => fetch('/facilitatorInfoSer/serviceInfoList', { pageSize, pageNumber, status, userId, registeredName }, 'POST');

/*
*处理询价
*/
export const dealServiceInfo = (id, status, userId, deal) => fetch('/facilitatorInfoSer/dealServiceInfo', { id, status, userId, deal }, 'POST');

/*
*询价详情
*/
export const serviceDetails = (id) => fetch('/facilitatorInfoSer/serviceDetails', { id }, 'POST');

/*
*服务商产品列表
*/
export const getServiceInfoList = (pageSize, pageNumber, userId, productType, productName, putOnSale) => fetch('/serviceProductSer/serviceInfoList', { pageSize, pageNumber, userId, productType, productName, putOnSale }, 'POST');

/**
Saas服务商(境外)编辑商户信息
*/
export const saveSWCustomer = (registeredName, serviceType, country, linkName, certification, address, website, logo, customer, successcase, userId, ip, macAddress, submitType, authentication) => fetch('/registerCompanySer/saveSWCustomer', {
    registeredName,
    serviceType,
    country,
    linkName,
    certification,
    address,
    website,
    logo,
    customer,
    successcase,
    userId,
    ip,
    macAddress,
    submitType,
    authentication
}, 'POST');

/**
Saas服务商(境内)编辑商户信息
*/
export const saveSNCustomer = (registeredName, serviceType, province, city, district, linkName, identityPositiveImage, identityBackImage, businessLicenseId, businessLicenseImage, address, website, logo, customer, successcase, userId, ip, macAddress, submitType, authentication) => fetch('/registerCompanySer/saveSWCustomer', {
    registeredName,
    serviceType,
    province,
    city,
    district,
    linkName,
    identityPositiveImage,
    identityBackImage,
    businessLicenseId,
    businessLicenseImage,
    address,
    website,
    logo,
    customer,
    successcase,
    userId,
    ip,
    macAddress,
    submitType,
    authentication
}, 'POST');

/*
*产品上下架
*/
export const putOnSaleStatus = (userId, id, putOnSale) => fetch('/serviceProductSer/putOnSaleStatus', { userId, id, putOnSale }, 'POST');

/*
*编辑服务商产品
*/
export const editServiceProduct = (userId, id, productName, quotation, beginTime, endTime, productType, showImage1, showImage2, showImage3, showImage4, showImage5, productDesc) => fetch('/serviceProductSer/editServiceProduct', {
    userId,
    id,
    productName,
    quotation,
    beginTime,
    endTime,
    productType,
    showImage1,
    showImage2,
    showImage3,
    showImage4,
    showImage5,
    productDesc
}, 'POST');

/*
*产品详情
*/
export const serviceProductDetails = (id) => fetch('/serviceProductSer/serviceProductDetails', { id }, 'POST');

/*
*供货商/运营商--产品列表
*/
export const showProductList = (pageSize, pageNumber, userId) => fetch('/serviceInfoSer/showProductList', { pageSize, pageNumber, userId }, 'POST');

/*
*供货商/运营商--产品详情
*/
export const showServiceProductDetails = (id) => fetch('/serviceInfoSer/serviceProductDetails', { id }, 'POST');

/*
*供货商/运营商--服务商企业信息
*/
export const getCustomerInfo = (userId) => fetch('/serviceInfoSer/getCustomerInfo', { userId }, 'POST');

/*
*查询店铺推广等级
*/
export const promoterLevelSearch = (shopId) => fetch('/shopPromoterLevelManage/shop', { shopId }, 'GET');

/*
*查询店铺推广等级
*/
export const promoterLevelSearchDetails = (levelId) => fetch('/shopPromoterLevelManage/detail', { levelId }, 'GET');

/*
*编辑推广等级
*/
export const promoterLevelEdit = (amount, id, name, promotOrderNum, promotOrderPrice, promotUserNum, ratio, shopId) => fetch('/shopPromoterLevelManage/edit', { amount, id, name, promotOrderNum, promotOrderPrice, promotUserNum, ratio, shopId }, 'POST');

/*
*添加推广等级
*/
export const promoterLevelAdd = (amount, name, promotOrderNum, promotOrderPrice, promotUserNum, ratio, shopId) => fetch('/shopPromoterLevelManage/add', { amount, name, promotOrderNum, promotOrderPrice, promotUserNum, ratio, shopId }, 'POST');

/*
*查询店铺申请推广员记录
*/
export const findApplyFor = (shopId, verifyStatus, searchVal, pageNum, pageSize) => fetch('/ShopPromoterManage/findApplyFor', { shopId, verifyStatus, searchVal, pageNum, pageSize }, 'GET');

/*
*推广员审核通过
*/
export const verifyAgree = (shopPromoterId, shopPromoterLevelId) => fetch('/ShopPromoterManage/verifyAgree', { shopPromoterId, shopPromoterLevelId }, 'GET');

/*
*批量推广员审核通过
*/
export const batchVerifyAgree = (shopPromoterIds, shopPromoterLevelId) => fetch('/ShopPromoterManage/batchVerifyAgree', { shopPromoterIds, shopPromoterLevelId }, 'GET');

/*
*推广员审核不通过
*/
export const verifyRefuse = (shopPromoterId, verifyMsg) => fetch('/ShopPromoterManage/verifyRefuse', { shopPromoterId, verifyMsg }, 'GET');

/*
*推广员审核不通过
*/
export const applyForDetail = (promoterId) => fetch('/ShopPromoterManage/applyForDetail', { promoterId }, 'GET');

/*
*查询店铺推广员（审核后）
*/
export const shopPromoterManageSearch = (shopId, levelId, searchVal, beginDate, endDate, pageNum, pageSize) => fetch('/ShopPromoterManage/search', { shopId, levelId, searchVal, beginDate, endDate, pageNum, pageSize }, 'GET');

/*
*查询店铺推广员详情（审核后）
*/
export const shopPromoterManageDetail = (promoterId) => fetch('/ShopPromoterManage/detail', { promoterId }, 'GET');

/*
*查询店铺推广员编辑（审核后）
*/
export const shopPromoterManageEdit = (bankName, bankNum, id, levelId, name, phone, remark) => fetch('/ShopPromoterManage/edit', { bankName, bankNum, id, levelId, name, phone, remark }, 'POST');

/*
*运营商推广订单列表
*/
export const promoterOrderDetailSearch = (userId, shopPromoterId, orderCommitTimeBegin, orderCommitTimeEnd, orderSearchKey, orderSearchVal, pageNum, pageSize) => fetch('/ShopPromoterManage/orders', {
    userId,
    shopPromoterId,
    orderCommitTimeBegin,
    orderCommitTimeEnd,
    orderSearchKey,
    orderSearchVal,
    pageNum,
    pageSize
}, 'GET');

/*
*运营商全部推广订单列表
*/
export const ShopPromoterManageAllOrders = (userId, recDateBegin, recDateEnd, orderSearchKey, orderSearchVal, pageNum, pageSize) => fetch('/ShopPromoterManage/allOrders', {
    userId,
    recDateBegin,
    recDateEnd,
    orderSearchKey,
    orderSearchVal,
    pageNum,
    pageSize
}, 'GET');

/**
*备案下拉框（new）
*/
export const selectbusinessTypeNew = (orderBy) => fetch('/filingSer/selectbusinessType', { orderBy }, 'POST');

/**
*单位分类线路（new）
*/
export const getInitInfoNew = (userId, orderBy) => fetch('/goodsManageSer/getInitInfo', { userId, orderBy }, 'POST');

/**
保证金列表
*/
export const selectDepositList = (pageNum, pageSize, companyName, country, adminRoleIds) => fetch('/deposit/selectDepositList', {
    pageNum,
    pageSize,
    companyName,
    country,
    adminRoleIds
}, 'GET', 'fetch', 1);

/**
保证金明细表
*/
export const selectDepositLogList = (pageNum, pageSize, omsDepositLogCustomsId, omsDepositLogAddTimeBegin, omsDepositLogAddTimeEnd, omsDepositLogTypes, omsDepositLogCustomsType) => fetch('/deposit/selectDepositLogList', {
    pageNum,
    pageSize,
    omsDepositLogCustomsId,
    omsDepositLogAddTimeBegin,
    omsDepositLogAddTimeEnd,
    omsDepositLogTypes,
    omsDepositLogCustomsType
}, 'GET', 'fetch', 1);

/**
供货商保证金明细表
*/
export const selectDepositLogListAbroad = (pageNum, pageSize, omsDepositLogCustomsId, omsDepositLogCustomsType, omsDepositLogAddTimeBegin, omsDepositLogAddTimeEnd, omsDepositLogTypes, orderAccountStatus, orderAccountType, orderAccountNumber) => fetch('/deposit/selectDepositLogList', {
    pageNum,
    pageSize,
    omsDepositLogCustomsId,
    omsDepositLogCustomsType,
    omsDepositLogAddTimeBegin,
    omsDepositLogAddTimeEnd,
    omsDepositLogTypes,
    orderAccountStatus,
    orderAccountType,
    orderAccountNumber
}, 'GET', 'fetch', 1);

/**
设置授信额度
*/
export const updateOmsDepositCreditLine = (id, omsDepositCreditLine) => fetch('/deposit/updateOmsDepositCreditLine', {
    id,
    omsDepositCreditLine
}, 'POST', 'fetch', 1);

/**
保证金统计表
*/
export const selectDepositLogCount = (omsDepositLogCustomsId, omsDepositLogAddTimeBegin, omsDepositLogAddTimeEnd, omsDepositLogTypes, omsDepositLogCustomsType) => fetch('/deposit/selectDepositLogCount', {
    omsDepositLogCustomsId,
    omsDepositLogAddTimeBegin,
    omsDepositLogAddTimeEnd,
    omsDepositLogTypes,
    omsDepositLogCustomsType
}, 'GET', 'fetch', 1);

/**
保证金详情
*/
export const selectDepositLogInfo = (id) => fetch('/deposit/selectDepositLogInfo', {
    id
}, 'GET', 'fetch', 1);

/**
保证金充值审核列表
*/
export const selectDepositAbroadList = (pageNum, pageSize, omsDepositAbroadStatus, id) => fetch('/deposit/selectDepositAbroadList', {
    pageNum,
    pageSize,
    omsDepositAbroadStatus,
    id
}, 'GET', 'fetch', 1);

/**
保证金充值审核详情
*/
export const selectDepositAbroadInfo = (id) => fetch('/deposit/selectDepositAbroadInfo', {
    id
}, 'GET', 'fetch', 1);

/**
新版订单详情
*/
export const selectOrderDetail = (orderNumber) => fetch('/order/selectOrderDetail', {
    orderNumber
}, 'GET', 'fetch', 3);

/**
新版订单列表
*/
export const selectOrderDtoList = (customsId, status, serverType, payType, beginTime, endTime, orderNumber, logisticsNumber, goodsName, person, phone, pageSize, pageNumber) => fetch('/order/selectOrderDtoList', {
    customsId,
    status,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    goodsName,
    person,
    phone,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);


/**
保证金充值审核
*/
export const updateDepositAbroad = (id, omsDepositAbroadPriceAmount, omsDepositAbroadRemark, omsDepositAbroadShUserId, omsDepositAbroadStatus) => fetch('/deposit/updateDepositAbroad', {
    id,
    omsDepositAbroadPriceAmount,
    omsDepositAbroadRemark,
    omsDepositAbroadShUserId,
    omsDepositAbroadStatus
}, 'POST', 'fetch', 1);

/**
收费单
*/
export const insertDepositLog = (omsDepositLogAddUser, omsDepositLogCustomsId, omsDepositLogOrderId, omsDepositLogCustomsType, omsDepositLogPrice, omsDepositLogRemark, omsDepositLogType) => fetch('/deposit/insertDepositLog', {
    omsDepositLogAddUser,
    omsDepositLogCustomsId,
    omsDepositLogOrderId,
    omsDepositLogCustomsType,
    omsDepositLogPrice,
    omsDepositLogRemark,
    omsDepositLogType
}, 'POST', 'fetch', 1);

/**
银联审核列表
*/
export const selectUnionList = (pageNum, pageSize, omsUserReceivePayUnionAbroadStatus, adminRoleIds, companyName) => fetch('/union/selectUnionList', {
    pageNum,
    pageSize,
    omsUserReceivePayUnionAbroadStatus,
    adminRoleIds,
    companyName
}, 'GET', 'fetch', 1);

/**
查看境外银联账户
*/
export const selectUnionAbroadInfo = (customsId) => fetch('/union/selectUnionAbroadInfo', {
    customsId
}, 'GET', 'fetch', 1);

/**
查看境内银联账户
*/
export const selectUnionDomesticInfo = (customsId) => fetch('/union/selectUnionDomesticInfo', {
    customsId
}, 'GET', 'fetch', 1);

/**
修改分销商户自有电商平台信息
*/
export const editAdminMerchantAgentEbp = (params) => fetch('/adminMerchants/editAdminMerchantAgentEbp', params, 'POST', 'fetch', 0);

/**
审核境外银联账户
*/
export const updateUnionAbroad = (omsUserReceivePayUnionAbroadCustomsId, omsUserReceivePayUnionAbroadRemark, omsUserReceivePayUnionAbroadStatus, omsUserReceivePayUnionAbroadAppUser) => fetch('/union/updateUnionAbroad', {
    omsUserReceivePayUnionAbroadCustomsId,
    omsUserReceivePayUnionAbroadRemark,
    omsUserReceivePayUnionAbroadStatus,
    omsUserReceivePayUnionAbroadAppUser
}, 'POST', 'fetch', 1);

/**
审核境内银联账户
*/
export const updateUnionDomestic = (id, omsUserReceivePayUnionDomesticAccountName, omsUserReceivePayUnionDomesticAccountNo, omsUserReceivePayUnionDomesticAppTime, omsUserReceivePayUnionDomesticAppUser, omsUserReceivePayUnionDomesticBankAccount, omsUserReceivePayUnionDomesticBankAccountName, omsUserReceivePayUnionDomesticBankCity, omsUserReceivePayUnionDomesticBankName, omsUserReceivePayUnionDomesticBankProvince, omsUserReceivePayUnionDomesticBusiness, omsUserReceivePayUnionDomesticBusinessLicense, omsUserReceivePayUnionDomesticBusinessLinkName, omsUserReceivePayUnionDomesticBusinessMail, omsUserReceivePayUnionDomesticBusinessMobile, omsUserReceivePayUnionDomesticBusinessTel, omsUserReceivePayUnionDomesticCorporateName, omsUserReceivePayUnionDomesticCustomsId, omsUserReceivePayUnionDomesticEnvironment, omsUserReceivePayUnionDomesticFinancialLinkEmail, omsUserReceivePayUnionDomesticFinancialLinkMobile, omsUserReceivePayUnionDomesticFinancialLinkName, omsUserReceivePayUnionDomesticFinancialLinkTel, omsUserReceivePayUnionDomesticIdLastDate, omsUserReceivePayUnionDomesticIdNumber, omsUserReceivePayUnionDomesticIdType, omsUserReceivePayUnionDomesticName, omsUserReceivePayUnionDomesticNo, omsUserReceivePayUnionDomesticPlace, omsUserReceivePayUnionDomesticRegisterDate, omsUserReceivePayUnionDomesticRemark, omsUserReceivePayUnionDomesticStatus, omsUserReceivePayUnionDomesticTel, omsUserReceivePayUnionDomesticType) => fetch('/union/updateUnionDomestic', {
    id,
    omsUserReceivePayUnionDomesticAccountName,
    omsUserReceivePayUnionDomesticAccountNo,
    omsUserReceivePayUnionDomesticAppTime,
    omsUserReceivePayUnionDomesticAppUser,
    omsUserReceivePayUnionDomesticBankAccount,
    omsUserReceivePayUnionDomesticBankAccountName,
    omsUserReceivePayUnionDomesticBankCity,
    omsUserReceivePayUnionDomesticBankName,
    omsUserReceivePayUnionDomesticBankProvince,
    omsUserReceivePayUnionDomesticBusiness,
    omsUserReceivePayUnionDomesticBusinessLicense,
    omsUserReceivePayUnionDomesticBusinessLinkName,
    omsUserReceivePayUnionDomesticBusinessMail,
    omsUserReceivePayUnionDomesticBusinessMobile,
    omsUserReceivePayUnionDomesticBusinessTel,
    omsUserReceivePayUnionDomesticCorporateName,
    omsUserReceivePayUnionDomesticCustomsId,
    omsUserReceivePayUnionDomesticEnvironment,
    omsUserReceivePayUnionDomesticFinancialLinkEmail,
    omsUserReceivePayUnionDomesticFinancialLinkMobile,
    omsUserReceivePayUnionDomesticFinancialLinkName,
    omsUserReceivePayUnionDomesticFinancialLinkTel,
    omsUserReceivePayUnionDomesticIdLastDate,
    omsUserReceivePayUnionDomesticIdNumber,
    omsUserReceivePayUnionDomesticIdType,
    omsUserReceivePayUnionDomesticName,
    omsUserReceivePayUnionDomesticNo,
    omsUserReceivePayUnionDomesticPlace,
    omsUserReceivePayUnionDomesticRegisterDate,
    omsUserReceivePayUnionDomesticRemark,
    omsUserReceivePayUnionDomesticStatus,
    omsUserReceivePayUnionDomesticTel,
    omsUserReceivePayUnionDomesticType
}, 'POST', 'fetch', 1);

/**
国家下拉框
*/
export const getCustomsCountryList = (showAll, state, page, pagesize, debug) => fetch('/Customs/getCustomsCountryList', {
    showAll,
    state,
    page,
    pagesize,
    debug
}, 'GET', 'fetch', 2);

/**
商品库备案(new)
*/
export const  globalGoodsFilings = (pageNum, pageSize, filingStatus, customsIeportCode, adminMerchantId, beginDate, endDate, globalGoodsSearch) => fetch('/globalGoodsFilings', {
  pageNum,
  pageSize,
  filingStatus,
  customsIeportCode,
  adminMerchantId,
  beginDate,
  endDate,
  globalGoodsSearch
}, 'GET', 'fetch', 0);

/**
选品中心查询商品信息
*/
export const  findAllInfo = (pageNum, pageSize, adminMerchantSupplierCompanyName, pmsGlobalGoodsSkuBarCode, pmsGlobalGoodsBrandName, pmsGlobalGoodsName, pmsGlobalGoodsSkuMainPicture, pmsGoodsCategoryName) => fetch('/globalGoods/findAllInfo', { pageNum, pageSize, adminMerchantSupplierCompanyName, pmsGlobalGoodsSkuBarCode, pmsGlobalGoodsBrandName, pmsGlobalGoodsName, pmsGlobalGoodsSkuMainPicture, pmsGoodsCategoryName }, 'GET', 'fetch', 0);

/**
 查询全部供货商
 */
export const getAllCompanyName = (pageNum, pageSize, adminMerchantSearch, adminMerchantCountry) => fetch('/adminMerchants/supplier', {
  pageNum,
  pageSize,
  adminMerchantSearch,
  adminMerchantCountry,
}, 'GET', 'fetch', 0);

/**
商品库备案(new)
商品备案修改
*/
export const  putGlobalGoodsFilings = (params) => fetch('/globalGoodsFilings', {
  params
}, 'PUT', 'fetch', 0);

/**
商品库备案提交(new)
*/
export const  submitGlobalGoodsFilings = (params) => fetch('/globalGoodsFilings/submit', { params }, 'PUT', 'fetch', 0);

/**
供货订单列表(new)
*/
export const  selectOrderDtoListGc = (customsId, fcCustomsId, status, serverType, payType, beginTime, endTime, orderNumber, logisticsNumber, goodsName, person, phone, cardName, pageNumber, pageSize) => fetch('/ordergc/selectOrderDtoList', {
    customsId,
    fcCustomsId,
    status,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    goodsName,
    person,
    phone,
    cardName,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);

/**
供货订单详情(new)
*/
export const  selectOrderDetailGc = (orderNumber) => fetch('/ordergc/selectOrderDetail', {
    orderNumber
}, 'GET', 'fetch', 3);

/**
运营商订单列表(new)
*/
export const  selectOrderDtoListFc = (customsId, status, serverType, payType, beginTime, endTime, orderNumber, logisticsNumber, goodsName, person, phone, cardName, pageNumber, pageSize, payOrderNumber) => fetch('/orderfc/selectOrderDtoList', {
    customsId,
    status,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    goodsName,
    person,
    phone,
    cardName,
    pageNumber,
    pageSize,
    payOrderNumber
}, 'GET', 'fetch', 3);

/**
运营商订单详情(new)
*/
export const  selectOrderDetailFc = (orderNumber) => fetch('/orderfc/selectOrderDetail', {
    orderNumber
}, 'GET', 'fetch', 3);

/**
供货商/分销商结算单列表(new)
*/
export const  selectOrderAccountSettlement = (customsId, status, type, customsName, number, customsType, beginTime, endTime, pageSize, pageNumber) => fetch('/orderAccountSettlement/selectOrderAccountSettlement', {
    customsId,
    status,
    type,
    customsName,
    customsType,
    number,
    beginTime,
    endTime,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
获取订单收入对账单(new)
*/
export const  selectOrderAccount = (customsId, customsType, status, type, beginTime, endTime, orderNumber, source, pageSize, pageNumber) => fetch('/orderAccount/selectOrderAccount', {
    customsId,
    customsType,
    status,
    type,
    beginTime,
    endTime,
    orderNumber,
    source,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
获取订单支出对账单(new)
*/
export const  selectOrderAccount2 = (accountNumber) => fetch('/orderAccount/selectOrderAccount2', {
    accountNumber
}, 'GET', 'fetch', 3);

/**
新增结算单(new)
*/
export const  addOrderAccounSettlementt = (accountNumber) => fetch('/orderAccountSettlement/addOrderAccounSettlementt', {
    accountNumber
}, 'POST', 'fetch', 3);

/**
新增结算单(new)
*/
export const  selectOrderAccountSettlementDetails = (accountSettlementNumber) => fetch('/orderAccountSettlement/selectOrderAccountSettlementDetails', {
    accountSettlementNumber
}, 'GET', 'fetch', 3);

/**
运营商统计(new)
*/
export const  selectFcOrderAccounttype = (customsId) => fetch('/orderAccountGenera/selectFcOrderAccounttype', {
    customsId
}, 'GET', 'fetch', 3);

/**
供货商统计(new)
*/
export const  selectGcOrderAccounttype = (customsId) => fetch('/orderAccountGenera/selectGcOrderAccounttype', {
    customsId
}, 'GET', 'fetch', 3);

/**
MCPL统计(new)
*/
export const  selectOrderAccountmpcl = () => fetch('/orderAccountGenera/selectOrderAccountmpcl', {}, 'GET', 'fetch', 3);

/**
查询全部运营商(new)
*/
export const  selectAllAgent = (pageNum, pageSize) => fetch('/adminMerchants/agent', { pageNum, pageSize }, 'GET', 'fetch', 0);

/**
查询全部供货商(new)
*/
export const  selectAllSupplier = (pageNum, pageSize) => fetch('/adminMerchants/supplier', { pageNum, pageSize }, 'GET', 'fetch', 0);

/**
新增手工对账单(new)
*/
// export const  addOrderPerson = (customsId, customsName, customsType, imagesUrl, price, remarks, source, type) => fetch('/orderAccountPerson/addOrderPerson', {
//     customsId,
//     customsName,
//     customsType,
//     imagesUrl,
//     price,
//     remarks,
//     source,
//     type
// }, 'POST', 'fetch', 3);

/**
admin结算单结算(new)
*/
export const  updateOrderAccountSettlement = (accountSettlementNumber, payType, payNumber) => fetch('/orderAccountSettlement/updateOrderAccountSettlement', { accountSettlementNumber, payType, payNumber }, 'GET', 'fetch', 3);

/**
admin收支明细列表(new)
*/
export const  selectOrderAccountGenera = (customsId, customsType, status, type, beginTime, endTime, orderNumber, source, customsName, number, pageSize, pageNumber) => fetch('/orderAccountGenera/selectOrderAccountGenera', {
    customsId,
    customsType,
    status,
    type,
    beginTime,
    endTime,
    orderNumber,
    source,
    customsName,
    number,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
全球商品库(new)
*/
export const  globalGoods = (pageNum, pageSize) => fetch('/globalGoods', {
    pageNum, pageSize
  }, 'GET', 'fetch', 0);

  /**
根据商户id查询商品
*/
export const  globalGoodsById = (adminMerchantId) => fetch('/globalGoods', {
    adminMerchantId
  }, 'GET', 'fetch', 0);
/**
分销商控制台统计(new)
*/
export const  selectOrderFcPrice = (customsId) => fetch('/orderfc/selectOrderFcPrice', {
    customsId
  }, 'GET', 'fetch', 3);

/**
供货商控制台统计(new)
*/
export const  selectOrderGcPrice = (customsId) => fetch('/ordergc/selectOrderGcPrice', {
    customsId
  }, 'GET', 'fetch', 3);

/**
等级列表(new)
*/
// export const  getLevelList = (pageNum, pageSize, agentShopId) => fetch('/adminMerchantAgentShopPromoterLevels', {
//     pageNum,
//     pageSize,
//     agentShopId
//   }, 'GET', 'fetch', 0);
export const getLevelList = () => {

}

export const putInventory = () => {
    
}

  /**
  推广下线统计(new)
  */
export const getPromoterLower = (pageNum, pageSize, promoterSearch, agentShopId, promoterParentId) => fetch('/adminMerchantAgentShopPromoters/promoterLower', {
  pageNum, pageSize, promoterSearch, agentShopId, promoterParentId
  }, 'GET', 'fetch', 0);

/**
查询等级详情(new)
*/
export const  levelSearch = (promoterLevelId) => fetch('/adminMerchantAgentShopPromoterLevels/' + promoterLevelId + '', {}, 'GET', 'fetch', 0);

/**
创建等级(new)
*/
export const  createdLevel = (agentShopId, promoterLevelAmount, promoterLevelId, promoterLevelName, promoterLevelOrderNum, promoterLevelOrderPrice, promoterLevelRatio, promoterLevelUserNum) => fetch('/adminMerchantAgentShopPromoterLevels', {
    agentShopId,
    promoterLevelAmount,
    promoterLevelId,
    promoterLevelName,
    promoterLevelOrderNum,
    promoterLevelOrderPrice,
    promoterLevelRatio,
    promoterLevelUserNum
  }, 'POST', 'fetch', 0);

/**
编辑等级(new)
*/
export const  editLevel = (params) => fetch('/adminMerchantAgentShopPromoterLevels', {
    params
  }, 'PUT', 'fetch', 0);

/**
审核列表(new)
*/
export const  getAgentShopPromoters = (pageNum, pageSize, agentShopId, promoterVerifyStatus, promoterSearch) => fetch('/adminMerchantAgentShopPromoters/promoterVerifyInfo', {
    pageNum,
    pageSize,
    agentShopId,
    promoterVerifyStatus,
    promoterSearch
  }, 'GET', 'fetch', 0);

/**
审核驳回(new)
*/
export const  reviewOverrule = (agentShopPromoterId, verifyMsg) => fetch('/adminMerchantAgentShopPromoters/verifyRefuse', {
    agentShopPromoterId,
    verifyMsg
}, 'POST', 'fetch', 0);

/**
审核成功(new)
*/
export const  reviewOnSuccess = (promoterAmount, promoterIds) => fetch('/adminMerchantAgentShopPromoters/batchVerifyAgree', {
  promoterAmount, promoterIds
}, 'POST', 'fetch', 0);

/**
查询推广员申请记录详情(new)
*/
export const  applyForDetails = (agentShopPromoterId) => fetch('/adminMerchantAgentShopPromoters/applyFor/' + agentShopPromoterId + '', {}, 'GET', 'fetch', 0);

/**
推广员管理列表(new)
*/
export const  getPromoterManageList = (pageNum, pageSize, promoterSearch, agentShopId) => fetch('/adminMerchantAgentShopPromoters', {
pageNum, pageSize, promoterSearch, agentShopId
}, 'GET', 'fetch', 0);

/**
查询推广员详情(new)
*/
export const  applyPromoterDetails = (agentShopPromoterId) => fetch('/adminMerchantAgentShopPromoters/' + agentShopPromoterId + '', {}, 'GET', 'fetch', 0);

/**
修改推广员详情(new)
*/
export const  editPromoterDetails = (params) => fetch('/adminMerchantAgentShopPromoters', {
    params
}, 'PUT', 'fetch', 0);

/**
推广员订单集合(new)
*/
export const  selectPromoterOrderList = (customsId, status, serverType, payType, beginTime, endTime, orderNumber, logisticsNumber, goodsName, person, phone, promoterId, pageNumber, pageSize) => fetch('/orderfc/selectPromoterOrderList', {
    customsId,
    status,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    goodsName,
    person,
    phone,
    promoterId,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);

/**
银联支付(new)
*/
export const  unionpay = (accountSettlementNumber) => fetch('/pay/unionpay', {
    accountSettlementNumber
}, 'GET', 'fetch', 3);

/**
境外银联账户注册(new)
*/
export const  insertUnionAbroad = (omsUserReceivePayUnionAbroadAccountAddress, omsUserReceivePayUnionAbroadAccountCountry, omsUserReceivePayUnionAbroadAccountName, omsUserReceivePayUnionAbroadAccountNumber, omsUserReceivePayUnionAbroadBankAddress, omsUserReceivePayUnionAbroadBankName, omsUserReceivePayUnionAbroadBankSwift, omsUserReceivePayUnionAbroadCertificate, omsUserReceivePayUnionAbroadCurrency, omsUserReceivePayUnionAbroadCustomsId, omsUserReceivePayUnionAbroadMsg ) => fetch('/union/insertUnionAbroad', {
    omsUserReceivePayUnionAbroadAccountAddress,
    omsUserReceivePayUnionAbroadAccountCountry,
    omsUserReceivePayUnionAbroadAccountName,
    omsUserReceivePayUnionAbroadAccountNumber,
    omsUserReceivePayUnionAbroadBankAddress,
    omsUserReceivePayUnionAbroadBankName,
    omsUserReceivePayUnionAbroadBankSwift,
    omsUserReceivePayUnionAbroadCertificate,
    omsUserReceivePayUnionAbroadCurrency,
    omsUserReceivePayUnionAbroadCustomsId,
    omsUserReceivePayUnionAbroadMsg
}, 'POST', 'fetch', 1);

/**
境外银联账户编辑(new)
*/
export const  editUnionAbroad = (omsUserReceivePayUnionAbroadAccountAddress, omsUserReceivePayUnionAbroadAccountCountry, omsUserReceivePayUnionAbroadAccountName, omsUserReceivePayUnionAbroadAccountNumber, omsUserReceivePayUnionAbroadBankAddress, omsUserReceivePayUnionAbroadBankName, omsUserReceivePayUnionAbroadBankSwift, omsUserReceivePayUnionAbroadCertificate, omsUserReceivePayUnionAbroadCurrency, omsUserReceivePayUnionAbroadCustomsId, omsUserReceivePayUnionAbroadMsg ) => fetch('/union/updateUnionAbroad', {
    omsUserReceivePayUnionAbroadAccountAddress,
    omsUserReceivePayUnionAbroadAccountCountry,
    omsUserReceivePayUnionAbroadAccountName,
    omsUserReceivePayUnionAbroadAccountNumber,
    omsUserReceivePayUnionAbroadBankAddress,
    omsUserReceivePayUnionAbroadBankName,
    omsUserReceivePayUnionAbroadBankSwift,
    omsUserReceivePayUnionAbroadCertificate,
    omsUserReceivePayUnionAbroadCurrency,
    omsUserReceivePayUnionAbroadCustomsId,
    omsUserReceivePayUnionAbroadMsg
}, 'POST', 'fetch', 1);

/**
境内银联账户注册(new)
*/
export const  insertUnionDomestic = (omsUserReceivePayUnionDomesticBankAccount, omsUserReceivePayUnionDomesticBankAccountName, omsUserReceivePayUnionDomesticBankCity, omsUserReceivePayUnionDomesticBankName, omsUserReceivePayUnionDomesticBankProvince, omsUserReceivePayUnionDomesticBusiness, omsUserReceivePayUnionDomesticBusinessLicense, omsUserReceivePayUnionDomesticBusinessLinkName, omsUserReceivePayUnionDomesticBusinessMail, omsUserReceivePayUnionDomesticBusinessMobile, omsUserReceivePayUnionDomesticBusinessTel, omsUserReceivePayUnionDomesticCorporateName, omsUserReceivePayUnionDomesticCustomsId, omsUserReceivePayUnionDomesticEnvironment, omsUserReceivePayUnionDomesticFinancialLinkEmail, omsUserReceivePayUnionDomesticFinancialLinkMobile, omsUserReceivePayUnionDomesticFinancialLinkName, omsUserReceivePayUnionDomesticFinancialLinkTel, omsUserReceivePayUnionDomesticIdLastDate, omsUserReceivePayUnionDomesticIdNumber, omsUserReceivePayUnionDomesticIdType, omsUserReceivePayUnionDomesticName, omsUserReceivePayUnionDomesticNo, omsUserReceivePayUnionDomesticPlace, omsUserReceivePayUnionDomesticRegisterDate, omsUserReceivePayUnionDomesticTel, omsUserReceivePayUnionDomesticType) => fetch('/union/insertUnionDomestic', {
    omsUserReceivePayUnionDomesticBankAccount,
    omsUserReceivePayUnionDomesticBankAccountName,
    omsUserReceivePayUnionDomesticBankCity,
    omsUserReceivePayUnionDomesticBankName,
    omsUserReceivePayUnionDomesticBankProvince,
    omsUserReceivePayUnionDomesticBusiness,
    omsUserReceivePayUnionDomesticBusinessLicense,
    omsUserReceivePayUnionDomesticBusinessLinkName,
    omsUserReceivePayUnionDomesticBusinessMail,
    omsUserReceivePayUnionDomesticBusinessMobile,
    omsUserReceivePayUnionDomesticBusinessTel,
    omsUserReceivePayUnionDomesticCorporateName,
    omsUserReceivePayUnionDomesticCustomsId,
    omsUserReceivePayUnionDomesticEnvironment,
    omsUserReceivePayUnionDomesticFinancialLinkEmail,
    omsUserReceivePayUnionDomesticFinancialLinkMobile,
    omsUserReceivePayUnionDomesticFinancialLinkName,
    omsUserReceivePayUnionDomesticFinancialLinkTel,
    omsUserReceivePayUnionDomesticIdLastDate,
    omsUserReceivePayUnionDomesticIdNumber,
    omsUserReceivePayUnionDomesticIdType,
    omsUserReceivePayUnionDomesticName,
    omsUserReceivePayUnionDomesticNo,
    omsUserReceivePayUnionDomesticPlace,
    omsUserReceivePayUnionDomesticRegisterDate,
    omsUserReceivePayUnionDomesticTel,
    omsUserReceivePayUnionDomesticType
}, 'POST', 'fetch', 1);

/**
境内银联账户编辑(new)
*/
export const  editUnionDomestic = (omsUserReceivePayUnionDomesticBankAccount, omsUserReceivePayUnionDomesticBankAccountName, omsUserReceivePayUnionDomesticBankCity, omsUserReceivePayUnionDomesticBankName, omsUserReceivePayUnionDomesticBankProvince, omsUserReceivePayUnionDomesticBusiness, omsUserReceivePayUnionDomesticBusinessLicense, omsUserReceivePayUnionDomesticBusinessLinkName, omsUserReceivePayUnionDomesticBusinessMail, omsUserReceivePayUnionDomesticBusinessMobile, omsUserReceivePayUnionDomesticBusinessTel, omsUserReceivePayUnionDomesticCorporateName, omsUserReceivePayUnionDomesticCustomsId, omsUserReceivePayUnionDomesticEnvironment, omsUserReceivePayUnionDomesticFinancialLinkEmail, omsUserReceivePayUnionDomesticFinancialLinkMobile, omsUserReceivePayUnionDomesticFinancialLinkName, omsUserReceivePayUnionDomesticFinancialLinkTel, omsUserReceivePayUnionDomesticIdLastDate, omsUserReceivePayUnionDomesticIdNumber, omsUserReceivePayUnionDomesticIdType, omsUserReceivePayUnionDomesticName, omsUserReceivePayUnionDomesticNo, omsUserReceivePayUnionDomesticPlace, omsUserReceivePayUnionDomesticRegisterDate, omsUserReceivePayUnionDomesticTel, omsUserReceivePayUnionDomesticType) => fetch('/union/updateUnionDomestic', {
    omsUserReceivePayUnionDomesticBankAccount,
    omsUserReceivePayUnionDomesticBankAccountName,
    omsUserReceivePayUnionDomesticBankCity,
    omsUserReceivePayUnionDomesticBankName,
    omsUserReceivePayUnionDomesticBankProvince,
    omsUserReceivePayUnionDomesticBusiness,
    omsUserReceivePayUnionDomesticBusinessLicense,
    omsUserReceivePayUnionDomesticBusinessLinkName,
    omsUserReceivePayUnionDomesticBusinessMail,
    omsUserReceivePayUnionDomesticBusinessMobile,
    omsUserReceivePayUnionDomesticBusinessTel,
    omsUserReceivePayUnionDomesticCorporateName,
    omsUserReceivePayUnionDomesticCustomsId,
    omsUserReceivePayUnionDomesticEnvironment,
    omsUserReceivePayUnionDomesticFinancialLinkEmail,
    omsUserReceivePayUnionDomesticFinancialLinkMobile,
    omsUserReceivePayUnionDomesticFinancialLinkName,
    omsUserReceivePayUnionDomesticFinancialLinkTel,
    omsUserReceivePayUnionDomesticIdLastDate,
    omsUserReceivePayUnionDomesticIdNumber,
    omsUserReceivePayUnionDomesticIdType,
    omsUserReceivePayUnionDomesticName,
    omsUserReceivePayUnionDomesticNo,
    omsUserReceivePayUnionDomesticPlace,
    omsUserReceivePayUnionDomesticRegisterDate,
    omsUserReceivePayUnionDomesticTel,
    omsUserReceivePayUnionDomesticType
}, 'POST', 'fetch', 1);

/**
热门商品列表(new)
*/
export const  getHotSaleList = (pageNum, pageSize, adminMerchantId, agentGoodsName) => fetch('/adminMerchantAgentShopHotSale/getHotSaleList', {
    pageNum,
    pageSize,
    adminMerchantId,
    agentGoodsName
}, 'GET', 'fetch', 0);

// 修改商品服务费
export const  postSkuPrice = (agentGoodsSkuPrice, agentGoodsSkuPriceType, agentGoodsSkuId) => fetch('/agentGoods/sku/serverPrice', {
    agentGoodsSkuId,
    agentGoodsSkuPrice,
    agentGoodsSkuPriceType,
}, 'POST', 'fetch', 0);

// Admin SKU列表
export const  getAdminSkuList = (params) => fetch('/admingoods/skus', params, 'GET', 'fetch', 0);

/**
Admin 修改指导零售价
*/
export const updateGuidePriceByAdmin = ( params ) => fetch('/admingoods/sku/sellingPrice', params, 'PUT', 'fetch', 0);

// Admin 修改代收货款
export const  postDSPriceByAdmin = (params) => fetch('/admingoods/sku/goodsSkuPrice', params, 'POST', 'fetch', 0);

/**
Admin 商品是否可分销
*/
export const updateCanDistributeByAdmin = ( adminMerchantAgentId, adminMerchantSupplierId, agentGoodsFilingSkuId, distributionType, globalGoodsFilingSkuId ) => fetch('/admingoods/productDistribution', { adminMerchantAgentId, adminMerchantSupplierId, agentGoodsFilingSkuId, distributionType, globalGoodsFilingSkuId }, 'POST', 'fetch', 0);

/**
Admin 商品是否可分配库存
*/
export const updateCanDistributeStockByAdmin = ( params ) => fetch('/admingoods/sku/updateAgentSkuUseStockStatus', params, 'PUT', 'fetch', 0);

/**
Admin 分配库存
*/
export const updateStockByAdmin = ( params ) => fetch('/admingoods/sku/updateAgentSkuStock', params, 'PUT', 'fetch', 0);


/**
删除热销商品(new)
*/
export const  saveHotSale = (adminMerchantId, agentGoodsIds) => fetch('/adminMerchantAgentShopHotSale/saveHotSale', {
    adminMerchantId,
    agentGoodsIds
}, 'POST', 'fetch', 0);

/**
删除推广员详情(new)
*/
export const  deleteHotSale = (id) => fetch('/adminMerchantAgentShopHotSale/deleteHotSale/' + id + '', {}, 'DELETE', 'fetch', 0);

/**
专题列表(new)
*/
export const  getActivityList = (pageNum, pageSize, adminMerchantId, dateBegin, dateEnd, name) => fetch('/adminMerchantAgentShopActivity/getActivityList', {
    pageNum,
    pageSize,
    adminMerchantId,
    dateBegin,
    dateEnd,
    name
}, 'GET', 'fetch', 0);

/**
banner列表(new)
*/
export const  getBannerList = (pageNum, pageSize, adminMerchantId, dateBegin, dateEnd, name) => fetch('/adminMerchantAgentShopBanner/getBannerList', {
    pageNum,
    pageSize,
    adminMerchantId,
    dateBegin,
    dateEnd,
    name
}, 'GET', 'fetch', 0);

/**
新建专题活动(new)
*/
export const  saveActivity = (adminMerchantId, dataEnd, dateBegin, goodsIds, imageMobile, imagePc, name, nameEn, status) => fetch('/adminMerchantAgentShopActivity/saveActivity', {
    adminMerchantId,
    dataEnd,
    dateBegin,
    goodsIds,
    imageMobile,
    imagePc,
    name,
    nameEn,
    status
}, 'POST', 'fetch', 0);

/**
停用、启用专题活动(new)
*/
export const  editActivityStatus = (params) => fetch('/adminMerchantAgentShopActivity/editActivityStatus', {
    params
}, 'PUT', 'fetch', 0);

/**
编辑专题活动(new)
*/
export const  editActivity = (params) => fetch('/adminMerchantAgentShopActivity/editActivity', {
    params
}, 'PUT', 'fetch', 0);

/**
境内商户详情(new)
*/
export const  withinBordersDetails = (adminMerchantId) => fetch('/adminMerchants/internal/' + adminMerchantId + '', {}, 'GET', 'fetch', 0);

/**
修改账户联系方式(new)
*/
export const  editAccountTel = (params) => fetch('/adminMerchants/account', {
    params
}, 'PUT', 'fetch', 0);

/**
保存banner(new)
*/
export const  saveBanner = (activityId, adminMerchantId, dateEnd, dateBegin, goodsId, imageMobile, imagePc, name, nameEn, status, type) => fetch('/adminMerchantAgentShopBanner/saveBanner', {
    activityId,
    adminMerchantId,
    dateEnd,
    dateBegin,
    goodsId,
    imageMobile,
    imagePc,
    name,
    nameEn,
    status,
    type
}, 'POST', 'fetch', 0);

/**
编辑专题活动(new)
*/
export const  editBanner = (params) => fetch('/adminMerchantAgentShopBanner/editBanner', {
    params
}, 'PUT', 'fetch', 0);

/**
专题活动详情(new)
*/
export const  getActivityDetails = (id) => fetch('/adminMerchantAgentShopActivity/getActivity/' + id, {}, 'GET', 'fetch', 0);

/**
停用、启用Banner活动(new)
*/
export const  editBannerStatus = (params) => fetch('/adminMerchantAgentShopBanner/editBannerStatus', {
    params
}, 'PUT', 'fetch', 0);

/**
banner详情(new)
*/
export const  getBannerDetails = (id) => fetch('/adminMerchantAgentShopBanner/getBanner/' + id + '', {}, 'GET', 'fetch', 0);

/**
修改密码(new)
*/
export const  editPassword = (params) => fetch('/adminMerchants/account/password', {
    params
}, 'PUT', 'fetch', 0);

/**
修改境内企业信息(new)
*/
export const  editCustomerInfoDomestic = (params) => fetch('/adminMerchants/internal', {
    params
}, 'PUT', 'fetch', 0);

/**
认证提交境内企业(new)
*/
export const  verifyDomestic = (adminMerchantBusinessLicenseNoInternal, adminMerchantBusinessLicenseUrlInternal, adminMerchantCompanyAddress, adminMerchantCompanyLogo, adminMerchantCompanyName, adminMerchantCompanyProfile, adminMerchantCompanyWebsite, adminMerchantId, adminMerchantIdCardBackInternal, adminMerchantIdCardFrontInternal, adminMerchantLegalPerson, adminMerchantServiceAgreement) => fetch('/adminMerchants/internal/verify', {
    adminMerchantBusinessLicenseNoInternal,
    adminMerchantBusinessLicenseUrlInternal,
    adminMerchantCompanyAddress,
    adminMerchantCompanyLogo,
    adminMerchantCompanyName,
    adminMerchantCompanyProfile,
    adminMerchantCompanyWebsite,
    adminMerchantId,
    adminMerchantIdCardBackInternal,
    adminMerchantIdCardFrontInternal,
    adminMerchantLegalPerson,
    adminMerchantServiceAgreement
}, 'POST', 'fetch', 0);

/**
境外商户详情(new)
*/
export const  foreignDetails = (adminMerchantId) => fetch('/adminMerchants/foreign/' + adminMerchantId + '', {}, 'GET', 'fetch', 0);

/**
修改境外企业信息(new)
*/
export const  editCustomerInfoOverseas = (params) => fetch('/adminMerchants/foreign', {
    params
}, 'PUT', 'fetch', 0);

/**
认证提交境内企业(new)
*/
export const  verifyOverseas = (adminMerchantBusinessCertificateForeign, adminMerchantCompanyAddress, adminMerchantCompanyLogo, adminMerchantCompanyName, adminMerchantCompanyProfile, adminMerchantCompanyWebsite, adminMerchantCountry, adminMerchantId, adminMerchantLegalPerson, adminMerchantServiceAgreement) => fetch('/adminMerchants/foreign/verify', {
    adminMerchantBusinessCertificateForeign,
    adminMerchantCompanyAddress,
    adminMerchantCompanyLogo,
    adminMerchantCompanyName,
    adminMerchantCompanyProfile,
    adminMerchantCompanyWebsite,
    adminMerchantCountry,
    adminMerchantId,
    adminMerchantLegalPerson,
    adminMerchantServiceAgreement
}, 'POST', 'fetch', 0);

/**
供货商保证金充值(new)
*/
export const  insertDepositAbroad = (omsDepositAbroadCertificate, omsDepositAbroadCustomsId, omsDepositAbroadCustomsType, omsDepositAbroadPrice) => fetch('/deposit/insertDepositAbroad', {
    omsDepositAbroadCertificate,
    omsDepositAbroadCustomsId,
    omsDepositAbroadCustomsType,
    omsDepositAbroadPrice
}, 'POST', 'fetch', 1);

/**
全部角色列表(new)
*/
export const  getAllCharacter = (Authorization) => fetch('/adminRole', {
    Authorization
}, 'GET', 'fetch', 4);

/**
供货商订单对账单(new)
*/
export const  selectSupplierrOrderAccount = (customsId, orderNumber, customsType, beginTime, endTime, pageSize, pageNumber) => fetch('/orderAccount/selectSupplierrOrderAccount', {
    customsId,
    orderNumber,
    customsType,
    beginTime,
    endTime,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
供货商服务费对账单(new)
*/
export const  selectSupplierServiceAccount = (customsId, orderNumber, customsType, beginTime, endTime, pageSize, pageNumber, type) => fetch('/orderAccount/selectSupplierServiceAccount', {
    customsId,
    orderNumber,
    customsType,
    beginTime,
    endTime,
    pageSize,
    pageNumber,
    type
}, 'GET', 'fetch', 3);

/**
服务费汇总(new)
*/
export const  selectSupplierServiceAccountSummary = (customsId, customsType, type) => fetch('/orderAccount/selectSupplierServiceAccountSummary', {
    customsId,
    customsType,
    type
}, 'GET', 'fetch', 3);

/**
订单汇总(new)
*/
export const  selectSupplierOrderAccountSummary = (customsId, customsType) => fetch('/orderAccount/selectSupplierOrderAccountSummary', {
    customsId,
    customsType
}, 'GET', 'fetch', 3);

/**
运营商订单对账单(new)
*/
export const  selectCustomerOrderAccount = (customsId, orderNumber, customsType, beginTime, endTime, pageSize, pageNumber) => fetch('/orderAccount/selectCustomerOrderAccount', {
    customsId,
    orderNumber,
    customsType,
    beginTime,
    endTime,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
运营商订单汇总(new)
*/
export const  selectCustomerAccountSummary = (customsId, customsType) => fetch('/orderAccount/selectCustomerAccountSummary', {
    customsId,
    customsType
}, 'GET', 'fetch', 3);

/**
保证金明细列表(new)
*/
export const  newSelectDepositLogList = (pageNum, pageSize, omsDepositLogCustomsId, omsDepositLogCustomsType, omsDepositLogAddTimeBegin, omsDepositLogAddTimeEnd, omsDepositLogTypes, id) => fetch('/deposit/selectDepositLogList', {
    pageNum,
    pageSize,
    omsDepositLogCustomsId,
    omsDepositLogCustomsType,
    omsDepositLogAddTimeBegin,
    omsDepositLogAddTimeEnd,
    omsDepositLogTypes,
    id
}, 'GET', 'fetch', 1);

/**
保证金明细列表统计(new)
*/
export const  selectByCustomsId = (customsId) => fetch('/deposit/selectByCustomsId', {
    customsId
}, 'GET', 'fetch', 1);

/**
供货商面单打印列表(new)
*/
export const  printOrderDtoList = (customsId, status, serverType, clearanceId, beginTime, endTime, orderNumber, goodsName, printStatus, pageNumber, pageSize) => fetch('/ordergc/selectOrderDtoList', {
    customsId,
    status,
    serverType,
    clearanceId,
    beginTime,
    endTime,
    orderNumber,
    goodsName,
    printStatus,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);


/**
威时沛运面单(new)
*/
export const  getWtdExpress = (orderNumbers) => fetch('/express/getWtdExpress', {
    orderNumbers
}, 'GET', 'fetch', 5);

/**
FedexEx运单号(new)
*/
export const  getFedexExpress = (params) => fetch('/express/getFedexExpress', {
    params
}, 'PUT', 'fetch', 5);

/**
手工对账单详情(new)
*/
export const  selectOrderAccountPerson = (id) => fetch('/ordergc/selectOrderAccountPerson', {
    id
}, 'GET', 'fetch', 3);

/**
手工对账单详情(new)
*/
export const  sendSimpleEmail = (to, subject, text) => fetch('/email/sendSimpleEmail', {
    to,
    subject,
    text
}, 'POST', 'fetch', 5);

/**
发送邮箱验证吗(new)
*/
export const  sendEmail = (email) => fetch('/email/code', {
    email
}, 'GET', 'fetch', 0);

/**
保证金停用(new)
*/
export const  updateOmsDepositStopLine = (id, omsDepositStopLine) => fetch('/deposit/updateOmsDepositStopLine', {
    id,
    omsDepositStopLine
}, 'POST', 'fetch', 1);

/**
保证金充值(new)
*/
export const  b2bUnionPay = (customsId, customsType, price) => fetch('/depositDomestic/b2bUnionPay', {
    customsId,
    customsType,
    price
}, 'POST', 'fetch', 1);

/**
经营概览(new)
*/
export const  getManageInfoCount = (customsId) => fetch('/orderGcIndex/getManageInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
合同管理count(new)
*/
export const  getContractInfoCount = (customsId) => fetch('/orderGcIndex/getContractInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
商品信息(new)
*/
export const  getGoodsInfoCount = (customsId) => fetch('/orderGcIndex/getGoodsInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
订单信息(new)
*/
export const  ggetOrderInfoCount = (customsId) => fetch('/orderGcIndex/getOrderInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
库存count(new)
*/
export const  getStockInfoCount = (customsId) => fetch('/orderGcIndex/getStockInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
运营商调价通知(new)
*/
export const  getAdjustPriceInfo = (customsId) => fetch('/orderFcIndex/getAdjustPriceInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
运营商合同管理(new)
*/
export const  getContractInfo = (customsId) => fetch('/orderFcIndex/getContractInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
运营商商品信息(new)
*/
export const  getGoodsInfo = (customsId) => fetch('/orderFcIndex/getGoodsInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
运营商经营概况(new)
*/
export const  getManageInfo = (customsId) => fetch('/orderFcIndex/getManageInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
运营商订单信息(new)
*/
export const  getOrderInfo = (customsId) => fetch('/orderFcIndex/getOrderInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
运营商库存(new)
*/
export const  getStockInfo = (customsId) => fetch('/orderFcIndex/getStockInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
账户管理合同(new)
*/
export const  getNew = (adminContractType) => fetch('/adminContracts/getNew', {
    adminContractType
}, 'GET', 'fetch', 0);

// /**
// 权限员工列表(new)
// */
// export const  adminUserEmployees = (Authorization) => fetch('/adminUser/employees', {
//     Authorization
// }, 'GET', 'fetch', 4);

/**
银联结算账户信息查询(new)
*/
export const  getDepositInfo = (customsId) => fetch('/orderAccountSettlement/getDepositInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
查询服务费明细
*/
export const  selectServiceFee = (customsId, personSource, beginTime, endTime) => fetch('/orderAccountSettlement/selectServiceFee?customsId=' + customsId + '&personSource=' + personSource + '&beginTime=' + beginTime + '&endTime=' + endTime, {}, 'GET', 'fetch', 3);

/**
查询账户结算明细列表(new)
*/
export const  selectSettlementList = (customsId, orderNumber, settlementType, settlementStatus, beginTime, endTime, settlementNumber, pageSize, pageNumber) => fetch('/orderAccountSettlement/selectSettlementList', {
    customsId,
    orderNumber,
    settlementType,
    settlementStatus,
    beginTime,
    endTime,
    settlementNumber,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
查询对账单明细列表(new)
*/
export const  selectAccountList = (customsId, settlementNumber, accountType, beginTime, endTime, orderNumber, accountStatus, payMethod, pageSize, pageNumber) => fetch('/orderAccountSettlement/selectAccountList', {
    customsId,
    settlementNumber,
    accountType,
    beginTime,
    endTime,
    orderNumber,
    accountStatus,
    payMethod,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
订单明细汇总
*/
export const  selectSumOrderAccount = (customsId, pageSize, pageNum) => fetch('/orderAccountSettlement/selectSumOrderAccount', {
    customsId,
    pageSize,
    pageNum
}, 'GET', 'fetch', 3);

/**
手工单详情(new)
*/
export const  selectPersonAccountInfo = (accountNumber) => fetch('/orderAccountSettlement/selectPersonAccountInfo', {
    accountNumber
}, 'GET', 'fetch', 3);

/**
备案费详情(new)
*/
export const  selectClearanceRecordInfo = (accountNumber) => fetch('/orderAccountSettlement/selectClearanceRecordInfo', {
    accountNumber
}, 'GET', 'fetch', 3);

/**
admin查询已合作所有商户(new)
*/
export const  verifySuccess = (merchantRole, merchantName) => fetch('/adminMerchants/verifySuccess', { merchantRole, merchantName }, 'GET', 'fetch', 0);

/**
admin查询全部运营商(new)
*/
export const  getAllAgent = (pageNum, pageSize, adminMerchantSearch, adminMerchantCountry) => fetch('/adminMerchants/agent', {
    pageNum,
    pageSize,
    adminMerchantSearch,
    adminMerchantCountry
}, 'GET', 'fetch', 0);

/**
admin查询全部供货商(new)
*/
export const  getAllSupplier = (pageNum, pageSize, adminMerchantSearch, adminMerchantCountry) => fetch('/adminMerchants/supplier', {
    pageNum,
    pageSize,
    adminMerchantSearch,
    adminMerchantCountry
}, 'GET', 'fetch', 0);

/**
admin查询全部服务商(new)
*/
export const  getAllService = (pageNum, pageSize, adminMerchantSearch, verifyStatus, shopTagId, adminMerchantOperatorId) => fetch('/adminMerchants/service', {
    pageNum,
    pageSize,
    adminMerchantSearch,
    verifyStatus,
    shopTagId,
    adminMerchantOperatorId
}, 'GET', 'fetch', 0);

/**
admin查询平台对账中心汇总(new)
*/
export const  selectAccountsInfoCollect = (startTime, endTime) => fetch('/adminOrderAccountSettlement/selectAccountsInfoCollect', {
    startTime,
    endTime
}, 'GET', 'fetch', 3);

/**
admin查询平台对账中心订单汇总(new)
*/
export const  selectMerchantOrder = (startTime, endTime) => fetch('/adminOrderAccountSettlement/selectMerchantOrder', {
    startTime,
    endTime
}, 'GET', 'fetch', 3);

/**
admin商户管理(new)
*/
export const  selectMerchantDeposit = (merchantType, merchantStatus, merchantId, merchantName, pageSize, pageNumber) => fetch('/adminOrderAccountSettlement/selectMerchantDeposit', {
    merchantType,
    merchantStatus,
    merchantId,
    merchantName,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
admin查询平台对账中心订单列表明细(new)
*/
export const selectMerchantOrderInfo = (orderStatus, supplierId, agentId, orderNumber, logisticsNumber, startTime, endTime, pageNum, pageSize) => fetch('/adminOrderAccountSettlement/selectMerchantOrderInfo', {
    orderStatus, 
    supplierId, 
    agentId, 
    orderNumber,
    logisticsNumber, 
    startTime, 
    endTime, 
    pageNum, 
    pageSize
}, 'GET', 'fetch', 3);

/**
admin订单详情(new)
*/
export const  selectMerchantOrderDetail = (orderNumber) => fetch('/adminOrderAccountSettlement/selectMerchantOrderDetail', {
    orderNumber
}, 'GET', 'fetch', 3);

/**
admin查询对账单明细列表(new)
*/
export const  adminSelectAccountList = (customsId, settlementNumber, accountType, accountNumber, merchantName, beginTime, endTime, orderNumber, accountStatus, payMethod, withdrawStatus, pageSize, pageNumber) => fetch('/orderAccountSettlement/selectAccountList', {
    customsId,
    settlementNumber,
    accountType,
    accountNumber,
    merchantName,
    beginTime,
    endTime,
    orderNumber,
    accountStatus,
    payMethod,
    withdrawStatus,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
admin商户财务管理修改商户警示额度(new)
*/
export const updateMerchantCreditLine = (customsId, creditLine) => fetch('/adminOrderAccountSettlement/updateMerchantCreditLine', {
    customsId,
    creditLine
}, 'GET', 'fetch', 3);

/**
admin商户财务管理修改商户停用额度(new)
*/
export const updateMerchantStopLine = (customsId, stopLine) => fetch('/adminOrderAccountSettlement/updateMerchantStopLine', {
    customsId,
    stopLine
}, 'GET', 'fetch', 3);

/**
admin新增手工账单(new)
*/
export const addOrderPerson = (customsId, customsName, customsType, imagesUrl, price, endTime, remarks, source, type, subject, addUser) => fetch('/orderAccountPerson/addOrderPerson', {
    customsId,
    customsName,
    customsType,
    imagesUrl,
    price,
    endTime,
    remarks,
    source,
    type,
    subject,
    addUser
}, 'POST', 'fetch', 3);

/**
admin查询手工单审核列表(new)
*/
export const  selectAccountPersonList = (addUser, shStatus, startTime, endTime, customsId, customsName, accountPersonId, pageSize, pageNumber) => fetch('/adminOrderAccountSettlement/selectAccountPersonList', {
    addUser,
    shStatus,
    startTime,
    endTime,
    customsId,
    customsName,
    accountPersonId,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
admin查询手工单详情(new)
*/
export const  selectAccountPersonInfo = (accountPersonId) => fetch('/adminOrderAccountSettlement/selectAccountPersonInfo', {
    accountPersonId
}, 'GET', 'fetch', 3);

/**
查询一级类目
*/
export const  queryCategoryTree = (supplierId, agentId) => fetch('/category/queryCategoryTree', {
    supplierId, agentId
}, 'GET', 'fetch', 10);

/**
查询二级类目
*/
export const  queryChildrenCategory = (supplierId, agentId, categoryId) => fetch('/category/queryChildrenCategory', {
    supplierId, agentId, categoryId
}, 'GET', 'fetch', 10);

/**
syncWeimob
*/
export const  syncWeimob = (agentGoodsId, categoryId) => fetch('/agentGoods/syncWeimob?agentGoodsId=' + agentGoodsId + '&categoryId=' + categoryId, { }, 'POST', 'fetch', 0);

/**
admin手工单审核(new)
*/
export const  updateAccountPersonStatus = (accountPersonId, status, shUser, shRemark) => fetch('/adminOrderAccountSettlement/updateAccountPersonStatus', {
    accountPersonId,
    status,
    shUser,
    shRemark
}, 'GET', 'fetch', 3);

/**
提现审核列表(new)
*/
export const  selectMerchantWithdrawList = (status, startTime, endTime, customsId, customsName, withdrawId, pageSize, pageNumber) => fetch('/withdraw/selectMerchantWithdrawList', {
    status,
    startTime,
    endTime,
    customsId,
    customsName,
    withdrawId,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
提现审核列表详情(new)
*/
export const  selectMerchantWithdrawInfo = (withdrawId) => fetch('/adminOrderAccountSettlement/selectMerchantWithdrawInfo', {
    withdrawId
}, 'GET', 'fetch', 3);

/**
提现审核(new)
*/
export const  updateWithdrawStatus = (shRemark, shUser, status, withdrawId) => fetch('/withdraw/updateWithdrawStatus', {
    shRemark,
    shUser,
    status,
    withdrawId
}, 'POST', 'fetch', 3);

/**
提现审核订单详情(new)
*/
export const  selectWithdrawOrder = (withdrawId, pageSize, pageNumber) => fetch('/adminOrderAccountSettlement/selectWithdrawOrder', {
    withdrawId,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
    提现审核关联订单详情(new 2022/3/2)
*/
export const selectWithdrawOrderListNew = (withdrawId, pageSize, pageIndex) => fetch('/withdraw/admin/withdrawRequestList', {
    withdrawId,
    pageSize,
    pageIndex
}, 'GET', 'fetch', 1);

/**
    Supplier 提现明细详情(new 2022/3/4)
*/
export const selectWithdrawOrderListNew4Supplier = (withdrawId, pageSize, pageIndex) => fetch('/withdraw/supplier/withdrawRequestList', {
    withdrawId,
    pageSize,
    pageIndex
}, 'GET', 'fetch', 1);

/**
    Admin 提现明细全部(new 2022/3/4)
*/
export const selectWithdrawOrderListAll = (params) => fetch('/withdraw/admin/withdrawList', params, 'GET', 'fetch', 1);

/**
新增提现(new new)
*/
export const  merchantWithdrawNew = (customsId, customsName, settleIds, addUser) => fetch('/withdraw', {
    customsId,
    customsName,
    settleIds,
    addUser
}, 'POST', 'fetch', 3);

/**
 * 可提现列表
 * @returns 
 */
export const canWithdrawList = (params) => fetch('/withdraw/withdrawableList', params, 'GET', 'fetch', 3);

/**
 * 全部提现
 * @returns 
 */
export const merchantWithdrawAll = (params) => fetch('/withdraw/merchantWithdrawAll', params, 'POST', 'fetch', 3);

/**
 * 计算提现金额和手续费
 * @returns 
 */
export const getMerchantWithdrawAllAmountAndFee = (params) => fetch('/withdraw/calc/withdrawAmount', params, 'POST', 'fetch', 3);

/**
新增提现(new)
*/
export const  merchantWithdraw = (customsId, customsName, orderNumber, addUser) => fetch('/orderAccountSettlement/merchantWithdraw', {
    customsId,
    customsName,
    orderNumber,
    addUser
}, 'POST', 'fetch', 3);

/**
充值列表(new)
*/
export const  getMerchantWithdraw = (omsDepositAbroadStatus, pageSize, pageNum, beginTime, endTime, customsId, customsName, depositId) => fetch('/depositAbroad/selectDepositAbroadList', {
    omsDepositAbroadStatus,
    pageSize,
    pageNum,
    beginTime,
    endTime,
    customsId,
    customsName,
    depositId
}, 'GET', 'fetch', 1);

/**
人工充值(new)
*/
export const  newInsertDepositAbroad = (subject, addUser, omsDepositAbroadCertificate, omsDepositAbroadCustomsId, omsDepositAbroadCustomsName, omsDepositAbroadCustomsType, omsDepositAbroadPrice, omsDepositAbroadRemark) => fetch('/depositAbroad/insertDepositAbroad', {
    subject,
    addUser,
    omsDepositAbroadCertificate,
    omsDepositAbroadCustomsId,
    omsDepositAbroadCustomsName,
    omsDepositAbroadCustomsType,
    omsDepositAbroadPrice,
    omsDepositAbroadRemark
}, 'POST', 'fetch', 1);

/**
充值审核(new)
*/
export const  newUpdateDepositAbroad = (params) => fetch('/depositAbroad/updateDepositAbroad', {
    params
}, 'PUT', 'fetch', 1);

/**
查询商户账户流水明细(new)
*/
export const  selectAcountDepositLogList = (orderId, orderNumber, customsId, logType, startTime, endTime, pageSize, pageNumber) => fetch('/orderAccountSettlement/selectDepositLogList', {
    orderId,
    orderNumber,
    customsId,
    logType,
    startTime,
    endTime,
    pageSize,
    pageNumber
}, 'GET', 'fetch', 3);

/**
商户财务管理数据汇总(new)
*/
export const  selectMerchantInfo = (customsId) => fetch('/adminOrderAccountSettlement/selectMerchantInfo', {
    customsId
}, 'GET', 'fetch', 3);

/**
商户充值明细列表查询(new)
*/
export const  selectMerchantRecharge = (customsId, abroadType, status, startTime, endTime, orderId, companyName, pageNumber, pageSize) => fetch('/orderAccountSettlement/selectMerchantRecharge', {
    customsId,
    abroadType,
    status,
    startTime,
    endTime,
    orderId,
    companyName,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);

/**
商户充值明细列表查询(new)
*/
export const  selectMerchantRechargeSum = (customsId, abroadType, status, startTime, endTime, orderId, companyName, pageNumber, pageSize) => fetch('/orderAccountSettlement/selectMerchantRechargeSum', {
    customsId,
    abroadType,
    status,
    startTime,
    endTime,
    orderId,
    companyName,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);

/**
创建众筹商品(new)
*/
export const  creatGlobalGoodsSkuCrowd = (crowdfundBeginTime, crowdfundEndTime, crowdfundIntro, crowdfundPrice, crowdfundQuantity, globalGoodsSkuId, skuCrowdfundId) => fetch('/globalGoodsSkuCrowd', {
    crowdfundBeginTime,
    crowdfundEndTime,
    crowdfundIntro,
    crowdfundPrice,
    crowdfundQuantity,
    globalGoodsSkuId,
    skuCrowdfundId
}, 'POST', 'fetch', 0);

/**
编辑众筹商品(new)
*/
export const  editGlobalGoodsSkuCrowd = (params) => fetch('/globalGoodsSkuCrowd', {
    params
}, 'PUT', 'fetch', 0);

/**
查询众筹商品(new)
*/
export const  getGlobalGoodsSkuCrowd = (pageNum, pageSize, adminMerchantId, adminMerchantAgentId, goodsSkuName, goodsCategoryId) => fetch('/globalGoodsSkuCrowd', {
    pageNum,
    pageSize,
    adminMerchantId,
    adminMerchantAgentId,
    goodsSkuName,
    goodsCategoryId
}, 'GET', 'fetch', 0);

/**
查询众筹商品列表(new)
*/
export const  globalGoodsSkus = (pageNum, pageSize, adminMerchantId, goodsCategoryId) => fetch('/globalGoods/skus', {
    pageNum,
    pageSize,
    adminMerchantId,
    goodsCategoryId
}, 'GET', 'fetch', 0);

/**
众筹商品详情(new)
*/
export const  getGlobalGoodsSkuCrowdDetails = (skuCrowdfundId) => fetch('/globalGoodsSkuCrowd/' + skuCrowdfundId + '', {}, 'GET', 'fetch', 0);

/**
代理商认筹商品(new)
*/
export const  firstSubscribe = (adminMerchantId, quantity, skuCrowdfundId) => fetch('/globalGoodsSkuCrowd/item/subscribe', {
    adminMerchantId,
    quantity,
    skuCrowdfundId
}, 'POST', 'fetch', 0);

/**
代理商追加认筹商品(new)
*/
export const  additionalSubscribe = (crowdfundItemId, quantity) => fetch('/globalGoodsSkuCrowd/item/subscribe/append', {
    crowdfundItemId,
    quantity
}, 'POST', 'fetch', 0);

/**
admin订单统计(new)
*/
export const  selectAgentOrderList = (pageSize, pageNumber, agentId) => fetch('/orderadmin/selectAgentOrderList', {
    pageSize,
    pageNumber,
    agentId
}, 'GET', 'fetch', 3);

/**
admin订单统计导出(new)
*/
export const  exportAgentOrderList = (
  fccustomsId,
  gccustomsId,
  status,
  orderSource,
  serverType,
  payType,
  beginTime,
  endTime,
  orderNumber,
  logisticsNumber,
  clearanceCode,
  goodsName,
  person,
  phone,
  promoterId,
  printStatus,
  payOrderNumber,
  pageNumber,
  pageSize
) => fetch('/orderadmin/exportOrderDtoList', {
  fccustomsId,
  gccustomsId,
  status,
  orderSource,
  serverType,
  payType,
  beginTime,
  endTime,
  orderNumber,
  logisticsNumber,
  clearanceCode,
  goodsName,
  person,
  phone,
  promoterId,
  printStatus,
  payOrderNumber,
  pageNumber,
  pageSize
}, 'GET', 'fetch', 3);

/**
admin全部订单查询(new)
*/
export const selectAllOrderDtoList = (
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    clearanceCode,
    goodsName,
    person,
    phone,
    promoterId,
    printStatus,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId,
    pageNumber,
    pageSize
) => fetch('/orderadmin/selectOrderDtoList', {
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    clearanceCode,
    goodsName,
    person,
    phone,
    promoterId,
    printStatus,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);

/**
admin全部订单查询(new)
*/
export const selectAllOrderDtoListV2 = (
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    clearanceCode,
    goodsName,
    person,
    phone,
    promoterId,
    printStatus,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId,
    groupBuyingId,
    crossBorderMode,
    isGroup,
    pageNumber,
    pageSize
) => fetch('/orderadmin/selectOrderDtoList/v2', {
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    clearanceCode,
    goodsName,
    person,
    phone,
    promoterId,
    printStatus,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId,
    groupBuyingId,
    crossBorderMode,
    isGroup,
    pageNumber,
    pageSize
}, 'GET', 'fetch', 3);

/**
admin全部订单查询汇总(new)
*/
export const  selectOrderDtoListCollect = (
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    clearanceCode,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId
) => fetch('/orderadmin/selectOrderDtoListCollect', {
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    clearanceCode,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId
}, 'GET', 'fetch', 3);

/**
admin全部订单查询汇总(new)
*/
export const  selectOrderDtoListCollectV2 = (
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    clearanceCode,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId,
    groupBuyingId
) => fetch('/orderadmin/selectOrderDtoListCollect/v2', {
    fccustomsId,
    gccustomsId,
    status,
    orderSource,
    serverType,
    payType,
    clearanceCode,
    beginTime,
    endTime,
    orderNumber,
    logisticsNumber,
    payOrderNumber,
    isLogisticsNumberNull,
    warehouseId,
    groupBuyingId
}, 'GET', 'fetch', 3);

/**
H5订单物流轨迹(newMerchant)
*/
export const  selectLogistics4Merchant = (orderNumber) => fetch('/logistics/selectLogisticsForMerchant', {
    orderNumber
}, 'GET', 'fetch', 8);

/**
H5订单物流轨迹(new)
*/
export const  selectLogistics = (orderNumber) => fetch('/logistics/selectLogistics', {
    orderNumber
}, 'GET', 'fetch', 8);

/**
admin订单详情(new)
*/
export const  adminOrderDetail = (orderNumber) => fetch('/orderadmin/selectOrderDetail', {
    orderNumber
}, 'GET', 'fetch', 3);

/**
增加通知手机号(new)
*/
export const  setMessageNumbe = (adminMerchantId, messageNumber) => fetch('/adminMerchants/updateMessageNumber', {
    adminMerchantId, messageNumber
}, 'POST', 'fetch', 0);

/**
查询商户完成的指引步骤
*/
export const  adminMerchantId = (adminMerchantId) => fetch('/adminMerchantGuide', {
    adminMerchantId
}, 'GET', 'fetch', 0);

/**
查询所有入库单
*/
export const  getAllentryInventory = (checkStatus, settleStatus, pageNum, pageSize) => fetch('/entryInventory/selectAll', {
    checkStatus, settleStatus, pageNum, pageSize
}, 'GET', 'fetch', 3);

/**
查询所有入库单费用标签
*/
export const  getAllLabel = () => fetch('/entryInventory/selectLabel', {
}, 'GET', 'fetch', 3);


/**
查询入库编号
*/
export const  getTheInNum = () => fetch('/entryInventory/getEntryInventoryNumber', {
}, 'GET', 'fetch', 3);

/**
查询入库编号
*/
export const  getInventoryNumber = (entryInventoryNumber, rolers) => fetch('/entryInventory/selectByEntryInventoryNumber', {
  entryInventoryNumber, rolers
}, 'GET', 'fetch', 3);

/**
审核入库编号
*/
export const  postInventoryNumber = (entryInventoryId, checkStatus, verifyUser) => fetch('/entryInventory/checkEntryInventory', {
  entryInventoryId, checkStatus, verifyUser
}, 'POST', 'fetch', 3);




/**
新增入库单
*/
export const  postInventory = (adminMerchantId, arrivalDate, boxNumber, clearanceNumber, customsInspection, entryInventoryId, entryInventoryNumber, entryInventoryPrices, goodsInspection, grossWeight, inspectionAdvancePrice, inspectionServerPrice, ladingNumber, netWeight, qty, transportDetail, transportWay ) => fetch('/entryInventory/add', {
  adminMerchantId,
  arrivalDate,
  boxNumber,
  clearanceNumber,
  customsInspection,
  entryInventoryId,
  entryInventoryNumber,
  entryInventoryPrices,
  goodsInspection,
  grossWeight,
  inspectionAdvancePrice,
  inspectionServerPrice,
  ladingNumber,
  netWeight,
  qty,
  transportDetail,
  transportWay
}, 'POST', 'fetch', 3);

/**
行邮税率
*/
export const  taxCategory = (taxCategoryPid) => fetch('/tax/taxCategory', {
  taxCategoryPid
}, 'GET', 'fetch', 3);

/**
查询批次列表
*/
export const getNameData = (
    pageNum,
    pageSize,
    // adminMerchantId,
    // skuGoodsNumber,
    // skuGoodsName,
    ) => fetch('/globalGoodsFilingSkuBatch/batchItems', {
    pageNum,
    pageSize,
    // adminMerchantId,
    // skuGoodsNumber,
    // skuGoodsName,
}, 'GET', 'fetch', 0);
/**
获取批次编号
*/
export const  getSkuBatchId = () => fetch('/globalGoodsFilingSkuBatch/getSkuBatchId', {}, 'GET', 'fetch', 0);

/*
*根据供货商查询发布商品
*/
export const globalGoodsFilingList = ( adminMerchantId, pageNum, pageSize ) => fetch('/globalGoodsFilings/skus/online', { adminMerchantId, pageNum, pageSize }, 'GET', 'fetch', 0);


/**
新增批次
*/
export const postSkuBatchId = (
    adminUserId,
    adminUserUsername,
    filingSkuBatchId,
    modeShipping,
    portArrive,
    portDeparture,
    transportSchedule,
    filingSkuBatchItems
    ) => fetch('/globalGoodsFilingSkuBatch', {
      adminUserId,
      adminUserUsername,
      filingSkuBatchId,
      modeShipping,
      portArrive,
      portDeparture,
      transportSchedule,
      filingSkuBatchItems
}, 'POST', 'fetch', 0);

/**
查询批次详情
*/
export const batchManageId = (filingSkuBatchId) => fetch('/globalGoodsFilingSkuBatch/' + filingSkuBatchId + '', { }, 'GET', 'fetch', 0);

/**
修改批次信息
*/
export const putSkuBatchById = ( params ) => fetch('/globalGoodsFilingSkuBatch', { params }, 'PUT', 'fetch', 0);

/**
删除批次
*/
export const delBatchId = ( filingSkuBatchId ) => fetch('/globalGoodsFilingSkuBatch/' + filingSkuBatchId, {}, 'DELETE', 'fetch', 0);

/**
查询商户货款明细(new)
*/
export const  selectMerchantPaymentDetail = (customsId, orderSource, pageSize, pageNumber, startTime, endTime) => fetch('/orderAccountSettlement/selectMerchantPaymentDetail?customsId=' + customsId + '&orderSource=' + orderSource + '&pageSize=' + pageSize + '&pageNumber=' + pageNumber + '&startTime=' + startTime  + '&endTime=' + endTime, {}, 'GET', 'fetch', 3);

/**
商户对账中心
*/
export const selectMerchantFinanceInfo = ( adminMerchantType, adminMerchantStatus, orderStatus, adminMerchantName, adminMerchantId, pageNum, pageSize ) => fetch('/orderadmin/selectMerchantFinanceInfo', { adminMerchantType, adminMerchantStatus, orderStatus, adminMerchantName, adminMerchantId, pageNum, pageSize }, 'GET', 'fetch', 3);

/**
业务月报 当月
*/
export const getAddBusinessMonthlyReport = ( beginTime, endTime, pageNum, pageSize ) => fetch('/businessReport/selectAllByAdminBusiness', { beginTime, endTime, pageNum, pageSize }, 'GET', 'fetch', 1);

/**
业务月报 累计
*/
export const getBusinessMonthlyReport = ( beginTime, endTime, pageNum, pageSize ) => fetch('/businessReport/getBusinessReportTotalList', { beginTime, endTime, pageNum, pageSize }, 'GET', 'fetch', 1);


/**
业务日报
*/
export const getBusinessDailyReport = ( adminMerchantId, warehouseId, beginTime, endTime, pageNum, pageSize ) => fetch('/businessReport/selectOrderDay', { adminMerchantId, warehouseId, beginTime, endTime, pageNum, pageSize }, 'GET', 'fetch', 1);

/**
业务日报详情
*/
export const getBusinessDailyReportDetail = ( omsOrderAddTime, pageNum, pageSize) => fetch('/businessReport/selectOrderSingle', { omsOrderAddTime, pageNum, pageSize }, 'GET', 'fetch', 1);

/**
 * 业务日报单商户查询
 * @param {*} omsOrderAddTime 
 * @param {*} adminMerchantId 
 * @param {*} pageNum 
 * @param {*} pageSize 
 * @returns 
 */
export const getBusinessDailyReportMerchant = (params) => fetch('/businessReport/selectDistributor', params, 'GET', 'fetch', 1);

/**
 * 业务日报分销商详情
 * @param {*} omsOrderAddTime 
 * @param {*} adminMerchantId 
 * @param {*} pageNum 
 * @param {*} pageSize 
 * @returns 
 */
export const getBusinessDailyReportMerchantOrderDetail = (params) => fetch('/businessReport/selectDistributorDetails', params, 'GET', 'fetch', 1);

/**
获取订单列表
*/
export const getManualSettleOrderList = ( params ) => fetch('/account/orderList', params, 'POST', 'fetch', 8);

/**
查询商户的结算单列表
*/
export const getManualSettleBillList = ( merchantId, params ) => fetch('/account/' + merchantId + '/list', params, 'POST', 'fetch', 8);

/**
手动结算
*/
export const manualSettle = ( merchantId, accountIds ) => fetch('/account/' + merchantId + '/doSettle', accountIds, 'POST', 'fetch', 8);



/**
 * 获取发票列表
 */
export const getInvoiceList = (queryParamsPageObj, bodyParams) => fetch('/invoice/list?currentPage=' + queryParamsPageObj.currentPage + '&pageSize=' + queryParamsPageObj.pageSize, bodyParams, 'POST', 'fetch', 1); 

/**
 * 新增发票
 */
export const addInvoice = (bodyParams) => fetch('/invoice/save', bodyParams, 'POST', 'fetch', 1);

/**
 * 更新发票
 */
 export const updateInvoice = (bodyParams) => fetch('/invoice/update', bodyParams, 'POST', 'fetch', 1);

/**
 * 导出发票
 */
 export const exportInvoice = (bodyParams) => fetch('/invoice/export', {}, 'GET', 'fetch', 1);

/**
 * 审核发票
 */
 export const verifyInvoice = (paramsInvoiceId, bodyParams) => fetch('/invoice/verify/' + paramsInvoiceId, bodyParams, 'POST', 'fetch', 1);


/**
停用、启用商户
*/
export const  switchEnable = (adminMerchantId, switchStatus) => fetch('/adminMerchants/switchEnable?adminMerchantId=' + adminMerchantId + '&switchStatus=' + switchStatus, {}, 'POST', 'fetch', 0);

/**
分销商展示删除接口
*/
export const  merchantDelete = (id, userId) => fetch('/merchantInCountryDisplay', { id, userId }, 'DELETE', 'fetch', 0);

/**
分销商展示新增接口
*/
export const  merchantInCountryDisplay = (createUserId, introduce, logo, name, priority, qrCode, website) => fetch('/merchantInCountryDisplay', { createUserId, introduce, logo, name, priority, qrCode, website }, 'POST', 'fetch', 0);

/**
分销商展示编辑接口
*/
export const  redactMerchantInCountryDisplay = (params) => fetch('/merchantInCountryDisplay', { params }, 'PUT', 'fetch', 0);

/**
分销商展示单条查询接口
*/
export const  merchantInCountryDisplayOne = (id) => fetch('/merchantInCountryDisplay/detail/' + id, {}, 'GET', 'fetch', 0);

/**
分销商展示列表查询分页接口_管理后台
*/
export const  merchantInCountryDisplayManage = ( pageNum, pageSize, name, priority, isDisplayed ) => fetch('/merchantInCountryDisplay/search', { pageNum, pageSize, name, priority, isDisplayed }, 'GET', 'fetch', 0);

/**
已发布商品是否分销
*/
export const  yesOrNoToSalesAndDistribution = ( adminMerchantAgentId, adminMerchantSupplierId, agentGoodsFilingSkuId, distributionType, globalGoodsFilingSkuId ) => fetch('/globalGoodsFilings/distributionOfPublishedProduct', { adminMerchantAgentId, adminMerchantSupplierId, agentGoodsFilingSkuId, distributionType, globalGoodsFilingSkuId }, 'POST', 'fetch', 0);

/**
合作运营商商品是否分销
*/
export const  yesOrNoToPartner = ( adminMerchantAgentId, adminMerchantSupplierId, agentGoodsFilingSkuId, distributionType, globalGoodsFilingSkuId ) => fetch('/globalGoodsFilings/productDistributionOfCooperativeOperator', { adminMerchantAgentId, adminMerchantSupplierId, agentGoodsFilingSkuId, distributionType, globalGoodsFilingSkuId }, 'POST', 'fetch', 0);

/**
修改是否分配库存状态
*/
export const  updateAgentSkuUseStockStatus = (params) => fetch('/agentGoodsSku/sku/updateAgentSkuUseStockStatus', { params }, 'PUT', 'fetch', 0);

/**
合作运营商商品是否包邮
*/
export const  yesOrNoExemptionFromPostage = ( params ) => fetch('/agentGoodsSku/switchFreightAndTax', params, 'POST', 'fetch', 0);

/**
一键包邮包税
*/
export const switchAllFreightAndTax = ( params ) => fetch('/agentGoodsSku/switchAllFreightAndTax', params, 'POST', 'fetch', 0);

/**
 查看合作商户接口 GET
*/
export const viewMerchantList = ( params ) => fetch('/adminContractSigns/allSigned', params, 'GET', 'fetch', 0);

/**
查看合作运营商商品SKU信息接口 GET
*/
export const viewAgentSkus = ( params ) => fetch('/agentGoods/sku/serviceFee/' + params.agentMerchantId, params, 'GET', 'fetch', 0);

/**
编辑合作运营商商品SKU信息接口 PUT
*/
export const updateAgentSkus = ( params ) => fetch('/agentGoods/sku/serviceFee/change/' + params.agentSkuId, { params }, 'PUT', 'fetch', 0);

/**
SKU服务费批量导入接口 POST
*/
export const skuServiceFeeBatchImport = ( params ) => fetch('/serviceImport/serviceCharge', params, 'POST', 'fetch', 8);


/**
 * 一体化
 */
export const ythPreIncreaseFindList = (params) => fetch('/preIncrease/findList', params, 'POST', 'fetch', 11);

export const ythPreIncreaseFindDetail = (params) => fetch('/preIncrease/findDetail', params, 'POST', 'fetch', 11);

export const ythPreIncreaseUpdate = (params) => fetch('/preIncrease/save', params, 'POST', 'fetch', 11);

export const ythPreIncreaseDelete = (params) => fetch('/preIncrease/delete', params, 'POST', 'fetch', 11);

export const ythPortReleaseGetList = (params) => fetch('/portRelease/getList', params, 'POST', 'fetch', 11);

export const ythPortReleaseGetDetail = (params) => fetch('/portRelease/getDetail', params, 'POST', 'fetch', 11);

export const ythPortReleaseSave = (params) => fetch('/portRelease/save', params, 'POST', 'fetch', 11);


/**
 * 账户流水明细
 * @param {商户id} merchantId 
 * @returns 
 */
export const getAccountTurnover = (params) => fetch('/depositlog/list/' + params.merchantId, params, 'GET', 'fetch', 1);

/**
 * 分销商订单明细列表
 * @param {orderAgentId,omsOrderNumber,omsOrderClearanceStatus,omsOrderStatus} params 
 * @returns 
 */
export const getAgentOrderDetail = (params) => fetch('/financeMerchantDetails/selectAgentOrder', params, 'GET', 'fetch', 3);


/**
 * 供货商订单明细列表
 * @param {customsSupplierId,orderNumber,goodsName,goodsSku} params 
 * @returns 
 */
export const getSupplierOrderDetail = (params) => fetch('/financeMerchantDetails/selectSupplierOrder', params, 'GET', 'fetch', 3);

/**
 * 提现明细列表
 * @param {withdrawCustomsId,withdrawId,withdrawStatus} params 
 * @returns 
 */
export const getWithdrawDetail = (params) => fetch('/financeMerchantDetails/selectWithdraw', params, 'GET', 'fetch', 3);

/**
 * 分销商结算明细列表
 * @param {merchantId,subject,orderBeginTime,orderEndTime,deliveryBeginTime,deliveryEndTime,
 * settleBeginTime,settleEndTime,isSettle,orderSettlementNo,orderNumber,pageIndex,pageSize} params  
 * @returns 
 */
export const getDistributorSettlementDetail = (params) => fetch('/account/agent/accountSettleList', params, 'GET', 'fetch', 8);

/**
 * 供货商商结算明细列表
 * @param {merchantId,subject,orderBeginTime,orderEndTime,deliveryBeginTime,deliveryEndTime,
 * settleBeginTime,settleEndTime,isSettle,orderSettlementNo,orderNumber} params 
 * @returns 
 */
export const getSupplierSettlementDetail = (params) => fetch('/account/supply/accountSettleList', params, 'GET', 'fetch', 8);

/**
 * 充值明细列表
 * @param {merchantId,rechargeMethod,approvalStatus,subject,beginTime,endTime,rechargeNumber} params 
 * @returns 
 */
export const getTopupDetail = (params) => fetch('/depositAbroad/list/' + params.merchantId, params, 'GET', 'fetch', 1);

/**
 * 账户明细
 * @param {merchantId} params 
 * @returns 
 */
export const getAccountDetails = (params) => fetch('/fundAccount', params, 'GET', 'fetch', 1);

/**
 * 推送运单号到公服
 * @param {orderNumber} params 
 * @returns 
 */
export const pushLogisticsNum2CommonService = (orderNumber) => fetch('/haiguanxxljob/YtoLogisticsNumber?orderNumber=' + orderNumber, {}, 'POST', 'fetch', 8);


/**
 * 修改备案状态为成功（废弃）
 * @param {orderNumber} params 
 * @returns 
 */
export const updatefilingGoodsSuccess = (globalGoodsFilingId) => fetch('/globalGoodsFilings/submit?globalGoodsFilingId=' + globalGoodsFilingId, {}, 'POST', 'fetch', 0);

/**
 * 修改备案状态失败（废弃）
 * @param {orderNumber} params 
 * @returns 
 */
export const updatefilingGoodsFailure = (globalGoodsFilingId) => fetch('/globalGoodsFilings/updateSubmit?globalGoodsFilingId=' + globalGoodsFilingId, {}, 'POST', 'fetch', 0);

/**
 * 修改备案状态 成功失败都用这个接口（New）
 * @param {orderNumber} params 
 * @returns 
 */
export const updatefilingGoodsAuditStatus = (params) => fetch('/globalGoodsFilings/filingExamine', { params }, 'PUT', 'fetch', 0);


/**
 * 查询备案不成功的列表
 * @param {orderNumber} params 
 * @returns 
 */
 export const queryGlobalGoodsFilingsNoSuccess = (pageNum, pageSize, filingStatus, customsIeportCode, adminMerchantId, beginDate, endDate, globalGoodsSearch) => fetch('/globalGoodsFilings/FilingReview', {
    pageNum,
    pageSize,
    filingStatus,
    customsIeportCode,
    adminMerchantId,
    beginDate,
    endDate,
    globalGoodsSearch
}, 'GET', 'fetch', 0);

/**
 * 查询线下商品备案列表
 * 
 * @param {*} params 
 * @returns 
 */
export const queryGlobalGoodsFilingsOffline = (params) => fetch('/globalGoodsFilings/offline', params, 'GET', 'fetch', 0);

/**
 * 审核线下商品备案
 * 
 * @param {*} params 
 * @returns 
 */
export const auditGlobalGoodsFilingsOffline = (params) => fetch('/globalGoodsFilings/offline/audit', params, 'POST', 'fetch', 0);

/**
 * 查看分销商财务明细汇总接口
 * 
 * @param {*} params 
 * @returns 
 */
export const agentFinanceDetailSummary = (params) => fetch('/deposit/getAccountDetails', params, 'GET', 'fetch', 1);

/**
 * 分销商财务明细列表接口
 * 
 * @param {*} params 
 * @returns 
 */
export const agentFinanceDetailList = (params) => fetch('/deposit/page', params, 'GET', 'fetch', 1);

/**
 * 文件资源分组列表查询
 * @param {*} params 
 * @returns 
 */
export const fileGroupList = (params) => fetch('/resource/group-list', params, 'GET', 'fetch', 5);

/**
 * 文件列表
 * @param {*} params 
 * @returns 
 */
export const fileList = (params) => fetch('/resource/page', params, 'GET', 'fetch', 5);

/**
 * 添加分组
 * @param {*} params 
 * @returns 
 */
export const addFileGroup = (params) => fetch('/resource/add-group', params, 'POST', 'fetch', 5);

/**
 * 溯源商品sku列表
 * @param {*} params 
 * @returns 
 */
export const skuTraceList = (params) => fetch('/presale/selectCommodity', params, 'GET', 'fetch', 0);

/**
 * 商品sku溯源入库
 * @param {*} params 
 * @returns 
 */
export const skuTraceAdd = (params) => fetch('/presale/traceabilityWarehousing', params, 'POST', 'fetch', 0);

/**
 * 商品sku溯源详情查询
 * @param {*} params 
 * @returns 
 */
export const skuTraceDetail = (params) => fetch('/presale/selelctTraceability/' + params.goodsSkuId, {}, 'GET', 'fetch', 0);

/**
 * 单个商品复制
 * @param {*} params 
 * @returns 
 */
export const singalCopyGoods = (params) => fetch('/presale/singleItemCopy?goodsNumber=' + 
params.goodsNumber + '&adminMerchantId=' + params.adminMerchantId, {}, 'POST', 'fetch', 0);

/**
 * 批量个商品复制
 * @param {*} params 
 * @returns 
 */
export const batchCopyGoods = (params) => fetch('/presale/productCopy', params, 'POST', 'fetch', 0);


