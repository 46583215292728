/**
 *  配置编译环境和线上环境之间的切换
 *  baseUrl: 域名地址
 *  routerMode: 路由模式
 *  imgBaseUrl: 图片所在域名地址
 */

let baseUrl = '';
let routerMode = 'hash';
let imgBaseUrl;
let apiUrl = '';
let apiUrl1 = ''; // 宋泽
let apiUrl2 = ''; // 王强强
let apiUrl3 = ''; // 杨明凯
let apiUrls = [];
let fileBaseUrl = '';
let websockUrl = '';
// apiUrl = 'http://120.132.106.53:8080/wmmapi'; 测试环境地址
if (process.env.NODE_ENV == 'development') {
    fileBaseUrl = 'https://wmmapi.cnmcpl.com/wmmapi/uploadAction/upload';
    imgBaseUrl = 'https://test.polo-e.net/mpcc-third-server-provider/upload/upload';
    // apiUrls.push('https://test.polo-e.net/mpcc-goods-api', 
    //             'https://test.polo-e.net/mpcc-deposit-provider', 
    //             'https://test.polo-e.net/dms', 
    //             'https://test.polo-e.net/mpcc-order-provider', 
    //             'https://test.polo-e.net/mpcc-saas-gateway', 
    //             'https://test.polo-e.net/mpcc-third-server-provider', 
    //             'https://www.polo-e.net/oms', 
    //             'https://test.polo-e.net/mpcc-cms-api', 
    //             'https://test.polo-e.net/mpcc-order-h5-provider', 
    //             'https://test.polo-e.net/mpcc-dms-provider', 
    //             'https://test.polo-e.net/mpcc-weimob-api',
    //             'https://test.polo-e.net/custom-server');
    apiUrls.push('https://mpcc.polo-e.net/mpcc-goods-api', 
    'https://mpcc.polo-e.net/mpcc-deposit-provider', 
    'https://mpcc.polo-e.net/dms', 
    'https://mpcc.polo-e.net/mpcc-order-provider', 
    'https://mpcc.polo-e.net/mpcc-saas-gateway', 
    'https://mpcc.polo-e.net/mpcc-third-server-provider', 
    'https://www.polo-e.net/oms', 
    'https://mpcc.polo-e.net/mpcc-cms-api', 
    'https://mpcc.polo-e.net/mpcc-order-h5-provider', 
    'https://mpcc.polo-e.net/mpcc-dms-provider',
    'https://mpcc.polo-e.net/mpcc-weimob-api');
} else if (process.env.NODE_ENV == 'production') {
    // 测试环境
    fileBaseUrl = 'https://www.cnmcpl.com/wmmapi/uploadAction/upload';
    imgBaseUrl = '/mpcc-third-server-provider/upload/upload';
    apiUrl = 'https://wmmapi.cnmcpl.com/wmmapi';
    websockUrl = 'https://wmmapi.cnmcpl.com/wmmapi';
    routerMode = 'history';
    apiUrl2 = '/mpcc-goods-api';
    apiUrls.push('/mpcc-goods-api', 
                '/mpcc-deposit-provider', 
                '/dms', 
                '/mpcc-order-provider', 
                '/mpcc-saas-gateway', 
                '/mpcc-third-server-provider', 
                '/oms', 
                '/mpcc-cms-api',  
                '/mpcc-order-h5-provider', 
                '/mpcc-dms-provider', 
                'mpcc-weimob-api',
                '/custom-server');
    // apiUrls.push('/mpcc-goods-api', '/mpcc-deposit-provider', 'https://www.polo-e.net/dms', '/mpcc-order-provider', '/mpcc-saas-gateway', 'mpcc-third-server-provider', 'https://www.polo-e.net/oms');
} else if (process.env.NODE_ENV == 'surveymeter') {
    // 正式环境
    fileBaseUrl = 'https://www.cnmcpl.com/wmmapi/uploadAction/upload';
    imgBaseUrl = '/mpcc-third-server-provider/upload/upload';
    apiUrl = 'https://wmmapi.cnmcpl.com/wmmapi';
    websockUrl = 'https://wmmapi.cnmcpl.com/wmmapi';
    routerMode = 'history';
    // apiUrls.push('https://wmmapi.cnmcpl.com/wmmapi', 'https://wmmapi.cnmcpl.com/wmmapi', 'https://wmmapi.cnmcpl.com/wmmapi');
    apiUrls.push('/mpcc-goods-api', '/mpcc-deposit-provider', 'https://www.polo-e.net/dms', '/mpcc-order-provider', '/mpcc-saas-gateway', 'mpcc-third-server-provider', 'https://www.polo-e.net/oms', 'mpcc-cms-api', 'mpcc-order-h5-provider', '/mpcc-dms-provider', 'mpcc-weimob-api');
}

export {
    baseUrl,
    routerMode,
    imgBaseUrl,
    apiUrl,
    fileBaseUrl,
    websockUrl,
    apiUrl1,
    apiUrl2,
    apiUrl3,
    apiUrls
}
