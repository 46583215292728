import { apiUrls } from '~/assets/config/env';
import { Local, PromptBox } from '~/assets/js/public';

export default async (url = '', data = {}, type = 'GET', method = 'fetch', apikey = 0) => {
    type = type.toUpperCase();
    url = apiUrls[apikey] + url;
    if (type == 'GET') {
        let dataStr = ''; // 数据拼接字符串
        Object.keys(data).forEach(key => {
            dataStr += key + '=' + data[key] + '&';
        })
        if (dataStr !== '') {
            dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'));
            url = url + '?' + dataStr;
        }
    }

    if (window.fetch && method == 'fetch') {
        let requestConfig = {
            credentials: 'include',
            method: type,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // 'userId': localStorage.getItem('userId'),
                'Authorization': localStorage.getItem('authorization'),
                'userAuthCode': '53D61242A5E245D78D8B8E854EDDDD8B' // 一体化的
            },
            mode: 'cors',
            cache: 'no-cache'
        }

        if (type == 'POST') {
            Object.defineProperty(requestConfig, 'body', {
                value: JSON.stringify(data)
            })
        }
        if (type == 'PUT') {
            Object.defineProperty(requestConfig, 'body', {
                value: JSON.stringify(data.params)
            })
        }
        if (type == 'DELETE') {
            Object.defineProperty(requestConfig, 'body', {
                value: JSON.stringify(data)
            })
        }

        try {
            const response = await fetch(url, requestConfig);
            const responseJson = await response.json();
            responseJson.headers = response.headers;
            console.log('responseJson:', responseJson);
            if (responseJson.code == 401) {
                Local.clear();
                var protocolUrl = window.location.protocol;
                var hostUrl = window.location.host;
                var hrefUrl = window.location.pathname;
                var hashUrl = window.location.hash;
                protocolUrl = protocolUrl.toString();
                hostUrl = hostUrl.toString();
                hrefUrl = hrefUrl.toString();
                hashUrl = hashUrl.toString();
                let jumpUrl = protocolUrl + '//' + hostUrl;
                if(!sessionStorage.getItem('logoOther')) {
                    window.location.href = jumpUrl + '/#/';
                } else {
                    window.location.href = jumpUrl + '/#/?btsc09876';
                }
                return;
            }
            return responseJson
        } catch (error) {
            throw new Error(error)
        }
    } else {
        return new Promise((resolve, reject) => {
            let requestObj;
            if (window.XMLHttpRequest) {
                requestObj = new XMLHttpRequest();
            } else {
                // requestObj = new ActiveXObject;
            }

            let sendData = '';
            if (type == 'POST') {
                sendData = JSON.stringify(data);
            }
            if (type == 'PUT') {
                sendData = JSON.stringify(data);
            }
            if (type == 'DELETE') {
                sendData = JSON.stringify(data);
            }

            if (type == 'PUT') {
                sendData = JSON.stringify(data);
            }
            if (type == 'DELETE') {
                sendData = JSON.stringify(data);
            }

            requestObj.open(type, url, true);
            requestObj.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            requestObj.send(sendData);

            requestObj.onreadystatechange = () => {
                if (requestObj.readyState == 4) {
                    if (requestObj.status == 200) {
                        let obj = requestObj.response
                        if (typeof obj !== 'object') {
                            obj = JSON.parse(obj);
                        }
                        resolve(obj)
                    } else {
                        reject(requestObj)
                    }
                }
            }
        })
    }
}
